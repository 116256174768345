import { Box, Typography } from '@mui/material';
import HkSasFillInTheBlankAnswer from 'pages/results/component/HkSasFillInTheBlank/HkSasFillInTheBlankAnswer';
import { AnswerItemProp, Results } from 'pages/results/models';
import { useTranslation } from 'react-i18next';
import { colors } from 'styles/Colors';

export const SasFillInTheBlanksAnswerItem = (props: AnswerItemProp) => {
    const { t } = useTranslation();
    return props?.answerCardData?.results?.map((item: Results, index: number) => {
        const sasFIBObj = props?.selectedTestroom?.lessonVersion?.lessonQuestions.filter((lessonObj) => lessonObj.id === props?.answerCardData?.questionID);
        if (sasFIBObj === undefined) {
            return <>no question found</>;
        }
        let questionText = '';
        if (sasFIBObj.length > 0) {
            if (sasFIBObj[0].content) {
                const sasContent = sasFIBObj[0].content;
                questionText = sasContent?.question ?? '';
            }
        }
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                }}>
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: '16px',
                        color: colors.yellow900,
                        lineHeight: '150%',
                    }}>
                    {t('answerTry', {
                        num: (index + 1).toString(),
                    })}
                </Typography>
                <Box
                    onClick={() => {
                        // setSelectedAnswerIndex('' + index);
                        // setSelectedAnswer([item]);
                    }}
                    sx={
                        {
                            // ...(selectedAnswerIndex === '' + index && {
                            //   border: '6px solid #29C0E7',
                            // }),
                        }
                    }>
                    <HkSasFillInTheBlankAnswer question={questionText} data={item.data} startTime={item} endTime={item} />
                </Box>
            </Box>
        );
    });
};
