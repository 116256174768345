import { OverallResultByQuestion, Results } from 'models/resultPage/ResultPageModel';
import { useCallback, useEffect, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { ResultsPageContext } from '../models';
import { useTestroomController } from './controller/useTestroomController';
import { useHardQuestionHook } from './useHardQuestionHook';
import { useQuestionTableResult } from './useQuestionTableResult';
import { useQuestionType } from './useQuestionType';
import { useSpecificQuestionStudentResultHook } from './useSpecificQuestionStudentResultHook';
import { useStudentTableHook } from './useStudentTableHook';
import { AnswerCardDataProps } from 'pages/results/component/AnswerCard/AnswerCard';
import { Constants } from 'Constants';
import { GameType } from '../utils';

// export type StudentProfileWithResults = StudentProfile & TestroomResults;

export const useTestroomResult = () => {
    const { selectedTestRoom, selectedStudent } = useOutletContext<ResultsPageContext>();
    const [resultByQuestion, setResultByQuestion] = useState<OverallResultByQuestion[]>([]);

    const [allStudentsAverageAnswerTime, setAllStudentsAverageAnswerTime] = useState(0);
    const {
        prepareStudentTable,
        studentTableData,
        numberOfQuestion,
        numberOfAttempt,
        failedCompeteStudents,
        absentStudents,
        allStudentsAverageScore,
        allStudentsAnswerTime,
        setStudentTableData,
        badPerformanceStudents,
        sortedStudentData,
    } = useStudentTableHook(selectedTestRoom);
    const { getQuestionTable, prepareQuestionTable, questionTableData } = useQuestionTableResult();
    const { prepareStudentQuestionTable, questionTableFromEachStudentData } = useSpecificQuestionStudentResultHook(resultByQuestion);
    const { getHardQuestions, hardQuestions } = useHardQuestionHook();
    const { getQuestionTypeResult, questionTypeResult } = useQuestionType();
    const [loadingOverallResultPage, setLoadingOverallResultPage] = useState(false);
    const { testroomId, classId, schoolId, mode } = useParams();
    const { setSelectedTestRoom } = useOutletContext<ResultsPageContext>();
    const { getTestroom, getStudentTestroomResult } = useTestroomController();

    const prepareStudentTableCoroutine = useCallback(async () => {
        if (!classId || !testroomId || !schoolId) return;
        setLoadingOverallResultPage(true);
        //TODO: error handling (refresh if error)
        const studentDataWithResult = await prepareStudentTable(classId, selectedTestRoom, testroomId, schoolId, mode === 'Homework');
        if (!studentDataWithResult) return;
        const questionResultPerStudents = getQuestionTable(selectedTestRoom, studentDataWithResult);
        setResultByQuestion(questionResultPerStudents);
        prepareQuestionTable(questionResultPerStudents);
        getHardQuestions(numberOfQuestion, questionResultPerStudents, absentStudents.length);
        getQuestionTypeResult(questionResultPerStudents, studentDataWithResult.filter((res) => res?.results?.length === 0).length);
        setLoadingOverallResultPage(false);
    }, [
        absentStudents.length,
        classId,
        getHardQuestions,
        getQuestionTable,
        getQuestionTypeResult,
        mode,
        numberOfQuestion,
        prepareQuestionTable,
        prepareStudentTable,
        schoolId,
        selectedTestRoom,
        testroomId,
    ]);

    const prepareAnswers = useCallback(
        (results: Results[]) => {
            const answers: AnswerCardDataProps[] = selectedTestRoom?.lessonVersion?.lessonQuestions?.map((question, index) => {
                let questionName: string;
                let locale: Constants.Locale;
                if (isNullOrEmpty(question?.content?.phrase)) {
                    questionName =
                        typeof question?.content?.character === 'number'
                            ? question?.content?.characterList[question?.content?.character].character ?? ''
                            : question?.content?.character?.split('/')[1].replace(/[0-9]/g, '') ?? '';
                } else {
                    questionName = question?.content?.phrase ?? '';
                }
                if (isNullOrEmpty(question?.content?.phrase)) {
                    locale = 'zh-HK';
                } else {
                    locale = typeof question?.content?.character === 'number' ? question?.content?.characterList[question?.content?.character].locale : 'zh-CN';
                }
                const filterItem = results.filter((element: any) => {
                    if (element?.componentId === question?.id) {
                        return true;
                    } else {
                        return false;
                    }
                });
                let grading: boolean = false;
                if (filterItem.length > 0) {
                    grading = filterItem?.some((item: any) => item.grading !== 'ungraded');
                }
                const isCorrect = filterItem?.some((item: any) => item.correct) || filterItem?.some((item: any) => item.grading === 'correct');
                let questionType = (question?.content?.gameType as GameType) ?? (question.type as GameType);
                return {
                    index: index,
                    locale: locale,
                    question: questionName,
                    questionID: question.id,
                    questionType,
                    correct: isCorrect,
                    results: filterItem,
                    grading: grading,
                };
            });
            return answers;
        },
        [selectedTestRoom?.lessonVersion?.lessonQuestions],
    );

    //TODO: error handling (refresh if error)
    const prepareStudentTestroomResults = useCallback(
        async (studentId: string) => {
            try {
                if (selectedTestRoom && selectedTestRoom.id && selectedStudent && selectedStudent.id) {
                    // const { data: studentResults } = await getStudentResults();
                    if (testroomId && studentId) {
                        const data = await getStudentTestroomResult(mode === 'Homework', testroomId, studentId);

                        const answers = prepareAnswers(data ?? []);
                        return answers;
                    }
                }
            } catch (error) {
                console.log('prepareStudentResults', error);
            }
        },
        [getStudentTestroomResult, mode, prepareAnswers, selectedStudent, selectedTestRoom, testroomId],
    );
    // First useEffect to fetch testroom data
    useEffect(() => {
        const setTestroom = async () => {
            if (!testroomId || selectedTestRoom?.id === testroomId) return;
            setLoadingOverallResultPage(true);
            //TODO: error handling (refresh if error)
            try {
                const testroomData = await getTestroom(mode === 'Homework' ? true : false, testroomId);

                if (testroomData) {
                    setSelectedTestRoom(testroomData);
                }
                return testroomData;
            } catch (error) {
                console.error('Error fetching testroom:', error);
            }
            setLoadingOverallResultPage(false);
        };

        setTestroom();
    }, [getTestroom, mode, selectedTestRoom?.id, setSelectedTestRoom, testroomId]);

    useEffect(() => {
        if (selectedTestRoom) {
            prepareStudentTableCoroutine();
        }
    }, [prepareStudentTableCoroutine, selectedTestRoom]);

    useEffect(() => {
        if (numberOfAttempt > 0) {
            setAllStudentsAverageAnswerTime(allStudentsAnswerTime / numberOfAttempt);
        }
    }, [allStudentsAnswerTime, numberOfAttempt]);

    return {
        prepareStudentTable,
        studentTableData,
        allStudentsAnswerTime,
        setStudentTableData,
        allStudentsAverageScore,
        allStudentsAverageAnswerTime,
        numberOfQuestion,
        absentStudents,
        badPerformanceStudents,
        failedCompeteStudents: failedCompeteStudents.filter((x) => !absentStudents.includes(x)),
        resultByQuestion,
        hardQuestions,
        questionTableData,
        prepareStudentQuestionTable,
        questionTableFromEachStudentData,
        questionTypeResult,
        loadingOverallResultPage,
        sortedStudentData,
        prepareStudentTestroomResults,
    };
};

function isNullOrEmpty(str: string | undefined): boolean {
    if (str === undefined) {
        return true;
    }
    if (str.trim() === '') {
        return true;
    }
    return false;
}
