import styled from '@emotion/styled';
import { EasyWritingAnswerProps } from 'pages/results/EasyWritingAnswer/EasyWritingAnswer';
import { version } from 'os';
import { Strokes } from 'pages/results/models';
import { useLayoutEffect, useMemo, useRef } from 'react';

const WRITING_CANVAS_VERSION_1 = {
    width: 300,
    height: 150,
};
const WRITING_CANVAS_VERSION_2 = {
    width: 184,
    height: 184,
};
const WRITING_CANVAS_HKCS = {
    width: 72,
    height: 72,
};
const STROKE_WIDTH_VERSION_1 = 3;
const STROKE_WIDTH_VERSION_2 = 3;
const UNITY_WRITING_CANVAS = 400;
const INTERPOLATION_FACTOR = 10;

export const HkcsEasyWritingAnswer = (props: EasyWritingAnswerProps) => {
    const { strokes, writingVersion } = props;
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const intervalRef = useRef<any>(null);
    let t = 1;
    let strokeTrialIdx = 0;
    let animatedCorrectStrokes: {
        x: number;
        y: number;
    }[][] = [];

    const clearAllCanvas = () => {
        if (canvasRef?.current) {
            clearInterval(intervalRef.current);
            const canvas = canvasRef.current;
            const context = canvas?.getContext('2d');
            context?.clearRect(0, 0, canvas?.width, canvas?.height);
        }
    };

    const createOutlinedPoint = (strokes: Strokes[][][]) => {
        // Version 2 scale down by (react canvas side)/(unity canvas side)
        const scaleFactor = WRITING_CANVAS_HKCS.width / UNITY_WRITING_CANVAS;

        return strokes.map((stroke) =>
            stroke.map((item) =>
                item.map((value) => ({
                    x: value.x * scaleFactor,
                    y: value.y * scaleFactor,
                })),
            ),
        );
    };

    const outlinePoint = useMemo(() => createOutlinedPoint(strokes), [strokes]);

    const calcWaypoints = (
        vertices: {
            x: number;
            y: number;
        }[][],
    ) => {
        var waypoints2D = [];
        for (var i = 0; i < vertices.length; i++) {
            var waypoints = [];
            for (var j = 0; j < vertices[i].length; j++) {
                var pt0 = vertices[i][j];
                var pt1 = vertices[i][j + 1];
                if (pt1) {
                    var dx = pt1.x - pt0.x;
                    var dy = pt1.y - pt0.y;
                    for (var k = 0; k < INTERPOLATION_FACTOR; k++) {
                        var x = pt0.x + (dx * k) / INTERPOLATION_FACTOR;
                        var y = pt0.y + (dy * k) / INTERPOLATION_FACTOR;
                        waypoints.push({
                            x: x,
                            y: y,
                        });
                    }
                }
            }
            waypoints2D.push(waypoints);
        }
        return waypoints2D;
    };

    const drawStroke = (context: CanvasRenderingContext2D, points: { x: number; y: number }[][], strokeTrialIdx: number, t: number, color: string) => {
        context.strokeStyle = color;
        context.beginPath();
        context.moveTo(points[strokeTrialIdx][t].x, points[strokeTrialIdx][t].y);
        context.lineTo(points[strokeTrialIdx][t + 1].x, points[strokeTrialIdx][t + 1].y);
        context.stroke();
        context.strokeStyle = 'black';
    };

    const redrawCanvas = (context: CanvasRenderingContext2D, canvas: HTMLCanvasElement) => {
        context.clearRect(0, 0, canvas.width, canvas.height);
        if (animatedCorrectStrokes && animatedCorrectStrokes.length > 0) {
            for (const cpoints of animatedCorrectStrokes) {
                writeStrokes(cpoints, context);
            }
        }
    };

    const animate = (
        points: {
            x: number;
            y: number;
        }[][],
        strokeLength: number,
        index: number,
    ) => {
        if (!canvasRef?.current) return;

        const canvas = canvasRef.current;
        let context = canvas.getContext('2d');
        let strokeArray: {
            x: number;
            y: number;
        }[] = [];
        intervalRef.current = setInterval(() => {
            if (!context || t >= points[strokeTrialIdx].length - 1) {
                clearInterval(intervalRef.current);
                return;
            }

            const color = strokeTrialIdx === points.length - 1 ? 'black' : '#FF0000';
            drawStroke(context, points, strokeTrialIdx, t, color);
            strokeArray.push(points[strokeTrialIdx][t]);
            t++;

            if (t < points[strokeTrialIdx].length - 1) {
                return;
            }
            if (!points) {
                clearInterval(intervalRef.current);
                return;
            }

            if (strokeTrialIdx === points.length - 1) {
                animatedCorrectStrokes.push(strokeArray);
            } else {
                redrawCanvas(context, canvas);
            }

            clearInterval(intervalRef.current);

            if (strokeTrialIdx === points.length - 1) {
                redrawCanvas(context, canvas);
                if (index + 1 < outlinePoint.length) {
                    const calPoints2D = calcWaypoints(outlinePoint[index + 1]);
                    animate(calPoints2D, strokeLength, index + 1);
                } else {
                    clearInterval(intervalRef.current);
                }
                strokeTrialIdx = 0;
            } else {
                strokeTrialIdx += 1;
                animate(points, strokeLength, index);
            }
            t = 1;
        }, 1 / 100);
    };

    const writeStrokes = (
        points: {
            x: number;
            y: number;
        }[],
        context: CanvasRenderingContext2D,
    ) => {
        for (var i = 1; i < points.length; i++) {
            if (points[i + 1] && points[i + 1].y) {
                context.strokeStyle = 'black';
                context?.beginPath();
                context?.moveTo(points[i].x, points[i].y);
                context?.lineTo(points[i + 1].x, points[i + 1].y);
                context?.stroke();
            }
        }
    };

    useLayoutEffect(() => {
        if (canvasRef?.current) {
            const canvas = canvasRef.current;
            const context = canvas?.getContext('2d');
            if (writingVersion) {
                // Version 2 does not require flip Y Axis
                context?.transform(1, 0, 0, 1, 0, 0);
            } else {
                context?.transform(1, 0, 0, -1, 0, canvas.height);
            }
            if (context) {
                context.lineWidth = writingVersion ? STROKE_WIDTH_VERSION_2 : STROKE_WIDTH_VERSION_1;
            }
        }
    }, [canvasRef]);

    useLayoutEffect(() => {
        if (canvasRef?.current) {
            const canvas = canvasRef.current;
            const context = canvas?.getContext('2d');
            if (outlinePoint.length > 0 && context) {
                for (const points of outlinePoint) {
                    const calPoints2D = calcWaypoints(points);
                    writeStrokes(calPoints2D[calPoints2D.length - 1], context);
                }
            }
        }
    }, [outlinePoint, canvasRef]);

    return (
        <Container>
            <Canvas ref={canvasRef} width={WRITING_CANVAS_HKCS.width} height={WRITING_CANVAS_HKCS.height}>
                Canvas
            </Canvas>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 71px;
    height: 71px;
    background: transparent;
    position: relative;
`;

const Canvas = styled.canvas``;
