import { ResultTable, ResultTableProps } from 'pages/results/component/ResultTable/ResultTable';
import { useSelectTestroomPageHook } from 'pages/results/hook/selectTestroomPage/useSelectTestroomPage';
import { ResultsPageContext } from 'pages/results/models';
import { useEffect, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';

export const SelectTestroomPage = () => {
    const { schoolId, classId, mode } = useParams();
    const { userData } = useOutletContext<ResultsPageContext>();
    const [loading, setLoading] = useState(false);
    const [testroomTableData, setTestroomTableData] = useState<ResultTableProps>();
    const { getTestroomData } = useSelectTestroomPageHook();
    useEffect(() => {
        const SetUpSelectTestroomTable = async () => {
            setLoading(true);
            if (mode && schoolId && classId && userData) {
                const data = await getTestroomData(mode, schoolId, classId, userData);
                if (data) {
                    setTestroomTableData(data);
                }
            }
            setLoading(false);
        };
        SetUpSelectTestroomTable();
    }, [classId, getTestroomData, mode, schoolId, userData]);
    return <ResultTable columns={testroomTableData?.columns} data={testroomTableData?.data} isLoading={loading} showSearchTab={true} />;
};
