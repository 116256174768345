import { gql, useMutation } from '@apollo/client';
import { UserAuthContext } from 'contexts/UserAuthContext';
import { ERROR_TYPE, TTError, handleException } from 'models/ErrorHandling';
import { TestroomModel } from 'pages/testroom/models/TestroomModel';
import { useCallback, useContext } from 'react';

const SAVE_GQL = gql`
    mutation save($name: String!, $lessonVersion: ID!, $class: ID!, $passlock: String!, $offlineMode: Boolean!, $writingHints: Boolean!, $freeStyleMode: Boolean!) {
        createTestroom(
            data: {
                testroomName: $name
                lessonVersion: { connect: { id: $lessonVersion } }
                class: { connect: { id: $class } }
                passlock: $passlock
                offlineMode: $offlineMode
                writingHints: $writingHints
                freeStyleMode: $freeStyleMode
            }
        ) {
            id
            testroomName
            otp
            lessonVersion {
                id
            }
            class {
                id
            }
            passlock
            offlineMode
            writingHints
            freeStyleMode
        }
    }
`;

type MutationVariableType = {
    name: string;
    otp: string;
    class: string;
    passlock: string;
    offlineMode: boolean;
    writingHints: boolean;
    freeStyleMode: boolean;
    lessonVersion: string;
};

type MutationType = {
    createTestroom: TestroomModel;
};

export const useCreateTestroomMutation = () => {
    const { accessDenied } = useContext(UserAuthContext);
    const [fetchMutation] = useMutation<MutationType>(SAVE_GQL);

    const runMutation = useCallback(
        async (variables: MutationVariableType) => {
            try {
                const result = await fetchMutation({
                    variables,
                });

                if (!result || !result.data?.createTestroom) {
                    throw new TTError(ERROR_TYPE.UPDATE_FAILED);
                }

                return result.data.createTestroom;
            } catch (err) {
                const errMessage = handleException(err);
                if (errMessage === ERROR_TYPE.KS_ACCESS_DENIED) {
                    accessDenied(errMessage);
                }

                throw new TTError(errMessage);
            }
        },
        [fetchMutation, accessDenied],
    );

    return { runMutation };
};
