import { gql, useLazyQuery } from '@apollo/client';
import { UserAuthContext } from 'contexts/UserAuthContext';
import { ERROR_TYPE, TTError, handleException } from 'models/ErrorHandling';
import { useCallback, useContext } from 'react';
import { ClassForOptionModel } from './ClassModel';

const QUERY_GQL = gql`
    query GetClassesForOption {
        classes(orderBy: { className: asc }) {
            id
            school {
                schoolName
            }
            className
        }
    }
`;

type QueryVariableType = {};

type ModelQueryType = {
    classes: ClassForOptionModel[];
};

export const useClassesForOptionQuery = () => {
    const auth = useContext(UserAuthContext);
    const [fetchQuery, { data, loading }] = useLazyQuery<ModelQueryType>(QUERY_GQL);

    const runQuery = useCallback(
        async (variables: QueryVariableType): Promise<ModelQueryType | null> => {
            try {
                const result = await fetchQuery({
                    variables,
                    fetchPolicy: 'no-cache',
                    notifyOnNetworkStatusChange: true,
                });

                if (!result?.data) {
                    throw new TTError(ERROR_TYPE.NO_DATA);
                }

                return result?.data;
            } catch (err) {
                const errorMsg = handleException(err);
                if (errorMsg === ERROR_TYPE.KS_ACCESS_DENIED) {
                    auth.accessDenied(errorMsg);
                }

                throw new TTError(errorMsg);
            }
        },
        [auth, fetchQuery],
    );

    return { runQuery, data, loading };
};
