import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import useTimeSlotMutation from 'pages/calendar/hooks/useTimeSlotMutation';
import { CalendarData, TimeSlotDialogState } from 'pages/calendar/types/timeSlotTypes';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import usePublishLessonNewHook from '../hooks/usePublishLessonNewHook';
import { PublishModal } from './PublishModal';

export const PublishButton = ({ lessonID }: { lessonID: string }) => {
    const { t } = useTranslation();
    const { refetchTestroom } = useTimeSlotMutation(() => {});

    const { enqueueSnackbar } = useSnackbar();

    const navigate = useNavigate();

    const [currentCalendarState, setCurrentCalendarState] = useState<TimeSlotDialogState>({
        isDialogOpen: false,
        calendarData: {} as CalendarData,
    });

    const { formik, classesData } = usePublishLessonNewHook(lessonID, async (data) => {
        if (!data.id || data.id === '') {
            enqueueSnackbar(t('createFailed'), {
                variant: 'error',
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
            });
            return;
        }

        let result = await refetchTestroom({
            id: data.id,
        });

        const otp = result?.data?.testroom?.otp;

        if (!otp || otp === '') {
            enqueueSnackbar(t('createFailed'), {
                variant: 'error',
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
            });
            return;
        }

        enqueueSnackbar(t('publishedMessage'), {
            variant: 'success',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        handleClose();
        navigate(`/testroom/${otp}`);
    });

    const handleClose = () => {
        setCurrentCalendarState({
            isDialogOpen: false,
            calendarData: {} as CalendarData,
        });
        resetForm();
    };

    const handleOpen = () => {
        setCurrentCalendarState({
            isDialogOpen: true,
            calendarData: {} as CalendarData,
        });
    };

    const resetForm = async () => {
        if (currentCalendarState.isDialogOpen) {
            formik.resetForm();

            formik.setFieldValue('offlineMode', false);
            formik.setFieldValue('writingHints', false);
            formik.setFieldValue('freeStyleMode', false);
        }
    };

    return (
        <>
            <Button
                onClick={handleOpen}
                variant='contained'
                color='primary'
                sx={{
                    textTransform: 'none',
                    borderRadius: '50px',
                    mr: 1,
                    color: 'white',
                }}
                startIcon={<PublishedWithChangesIcon />}>
                {t('new.publishLesson.publish')}
            </Button>

            <PublishModal currentCalendarState={currentCalendarState} formik={formik} classesData={classesData} handleClose={handleClose} />
        </>
    );
};
