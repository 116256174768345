import { ResultTableProps } from 'pages/results/component/ResultTable/ResultTable';
import { UserData } from 'contexts/UserAuthContext';
import moment from 'moment';
import { getDateSortDirection, getStringSortDirection } from 'pages/results/utils';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { OverallResultPath, SelectClassPath, SelectTestroomPath } from 'routes/utils';
import { useTestroomController } from '../controller/useTestroomController';
export const useSelectTestroomPageHook = () => {
    const [testroomTableData, setTestroomTableData] = useState<ResultTableProps>();
    const { getTestrooms } = useTestroomController();
    const { t } = useTranslation();
    const navigator = useNavigate();

    const columns = useMemo(
        () => [
            {
                name: 'testName',
                nameStr: t('testName'),
                sortCallback: () => {
                    setTestroomTableData((prevState) => {
                        if (!prevState?.data) return prevState;
                        const cloneState = [...prevState.data];
                        const sortDirection = getStringSortDirection(cloneState, 'testName');
                        return {
                            ...prevState,
                            data: cloneState.sort((a, b) => (sortDirection === 'ascending' ? b?.testName?.localeCompare(a?.testName) : a?.testName?.localeCompare(b?.testName))),
                        };
                    });
                },
            },
            {
                name: 'testDate',
                nameStr: t('testDate'),
                sortCallback: () => {
                    setTestroomTableData((prevState) => {
                        if (!prevState?.data) return prevState;
                        const cloneState = [...prevState.data];
                        const sortDirection = getDateSortDirection(cloneState, 'testDate');
                        return {
                            ...prevState,
                            data: cloneState.sort((a, b) =>
                                sortDirection === 'ascending' ? new Date(b?.testDate).valueOf() - new Date(a?.testDate).valueOf() : new Date(a?.testDate).valueOf() - new Date(b?.testDate).valueOf(),
                            ),
                        };
                    });
                },
            },
        ],
        [t],
    );
    //TODO: error handling (refresh if error)
    const getTestroomData = useCallback(
        async (mode: string, schoolId: string, classId: string, userData: UserData) => {
            const testrooms = await getTestrooms(mode === 'Homework' ? true : false, classId ?? '', userData?.teacherProfileId ?? '');
            // Remove the function wrapper and directly transform the data
            const transformedData =
                testrooms?.map((item) => ({
                    testName: item?.testName,
                    testDate: moment(item.testDate).format('L'),
                    id: item.id,
                    onClick: async () => {
                        navigator(`../${SelectClassPath}/${schoolId}/${SelectTestroomPath}/${classId}/${mode === 'Homework' ? 'Homework' : 'Classroom'}/${OverallResultPath}/${item.id}`);
                    },
                })) ?? [];
            if (transformedData) {
                setTestroomTableData({
                    data: transformedData,
                    columns,
                });
                return {
                    data: transformedData,
                    columns,
                };
            }
        },
        [getTestrooms, columns, navigator],
    );
    return { getTestroomData };
};
