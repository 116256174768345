import { UserAuthContext } from 'contexts/UserAuthContext';
import { useAuthChecking } from 'hooks/useAuthChecking';
import { LessonModel, LessonVersionModel } from 'models/lesson/LessonModel';
import { useLessonsQuery } from 'models/lesson/useLessonsQuery';
import { enqueueSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useMyLessonHook = () => {
    const { userData } = useContext(UserAuthContext);

    const { getMyLessons, data, loading } = useLessonsQuery();

    const [lessonData, SetLessonData] = useState<LessonModel[]>([]);

    const { t } = useTranslation();

    useAuthChecking(data);

    useEffect(() => {
        refresh();
    }, []);

    useEffect(() => {
        if (data?.lessons) {
            for (let lessonItem of data.lessons) {
                if (lessonItem.latestLessonDraft) {
                    lessonItem.latestLessonDraft.slides = lessonItem.latestLessonDraft?.slides.map((slideItem) => {
                        if (typeof slideItem.data === 'string') {
                            return {
                                ...slideItem,
                                data: JSON.parse(slideItem.data),
                            };
                        } else {
                            return { ...slideItem };
                        }
                    });
                } else {
                    lessonItem.latestLessonDraft = {} as LessonVersionModel;
                }
            }
            SetLessonData(data.lessons);
        }
    }, [data]);

    const refresh = async () => {
        try {
            await getMyLessons({ teacherID: userData?.id ?? '' });
        } catch (err: any) {
            enqueueSnackbar(t(`error.${err.message}`), {
                variant: 'error',
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            });
        }
    };

    return { loading, lessonData };
};
