import { gql, useLazyQuery } from '@apollo/client';
import { UserAuthContext } from 'contexts/UserAuthContext';
import { ERROR_TYPE, TTError, handleException } from 'models/ErrorHandling';
import { useCallback, useContext } from 'react';
import { enqueueSnackbar } from 'notistack';
import { Results } from '../ResultPageModel';

export const GET_RESULTS_BY_TESTROOM = gql`
    query GetResultsByTestroom($testroomId: ID!) {
        results(where: { testroom: { id: { equals: $testroomId } } }) {
            id
            message
            data
            student {
                id
                givenName
                familyName
            }
            startTime
            endTime
            componentId
            correct
            grading
            gameType
        }
    }
`;

export type ModelQueryType = {
    results?: Results[];
};

type QueryVariableType = {
    testroomId: string;
};

export const useClassroomResultQuery = () => {
    const auth = useContext(UserAuthContext);

    const [getClassroomResultQuery, { data, loading: resultsLoading }] = useLazyQuery(GET_RESULTS_BY_TESTROOM);

    const getClassroomsResultData = useCallback(
        async (variables: QueryVariableType): Promise<ModelQueryType | null> => {
            try {
                const result = await getClassroomResultQuery({
                    variables,
                    fetchPolicy: 'no-cache',
                    notifyOnNetworkStatusChange: true,
                });

                if (!result?.data) {
                    throw new TTError(ERROR_TYPE.NO_DATA);
                }
                return result?.data;
            } catch (err) {
                const errorMsg = handleException(err);
                if (errorMsg === ERROR_TYPE.KS_ACCESS_DENIED) {
                    auth.accessDenied(errorMsg);
                }
                throw new TTError(errorMsg);
            }
        },
        [getClassroomResultQuery, auth],
    ); // Added dependencies for useCallback

    return { getClassroomsResultData, data, resultsLoading };
};
