import { Box, Card, CardContent, List, ListItem, Typography } from '@mui/material';
import { ResultTableProps } from 'pages/results/component/ResultTable/ResultTable';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { useClassOverallProp } from 'pages/results/hook/useClassOverallCard';
import { useTranslation } from 'react-i18next';

type classOverallProp = {
    studentData?: ResultTableProps;
    averageTime?: number;
    numberOfQuestion: number;
};
const ClassOverallCard = (props: classOverallProp) => {
    const { t } = useTranslation();
    const { numberOfQuestion, averageTime, studentNumber } = useClassOverallProp(props);
    return (
        <Card
            sx={{
                display: 'flex',
                alignItems: 'flex-start',
                flexDirection: 'column',
                flex: '1 0 0',
                alignSelf: 'stretch',
                padding: '16px',
                width: '100%',
            }}>
            <CardContent
                sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                    width: '100%',
                    height: '100%',
                    gap: '16px',
                }}>
                <List
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        alignSelf: 'stretch',
                        flexDirection: 'column',
                        padding: '8px ',
                    }}>
                    <ListItem
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'row',
                        }}>
                        <Box sx={{ flex: 0.5 }}>
                            <svg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'>
                                <path
                                    d='M12 12.5C14.21 12.5 16 10.71 16 8.5C16 6.29 14.21 4.5 12 4.5C9.79 4.5 8 6.29 8 8.5C8 10.71 9.79 12.5 12 12.5ZM12 14.5C9.33 14.5 4 15.84 4 18.5V20.5H20V18.5C20 15.84 14.67 14.5 12 14.5Z'
                                    fill='#F05023'
                                />
                            </svg>
                        </Box>
                        <Typography sx={{ flex: 6 }} align='left'>
                            {t('resultPage.student-number')}
                        </Typography>
                        <Typography sx={{ flex: 1 }} align='right'>
                            {studentNumber}
                        </Typography>
                    </ListItem>
                    <ListItem
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'row',
                        }}>
                        <Box sx={{ flex: 0.5 }}>
                            <svg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'>
                                <path
                                    d='M11.07 13.35C11.84 11.96 13.32 11.14 14.18 9.91C15.09 8.62 14.58 6.21 12 6.21C10.31 6.21 9.48004 7.49 9.13004 8.55L6.54004 7.46C7.25004 5.33 9.18004 3.5 11.99 3.5C14.34 3.5 15.95 4.57 16.77 5.91C17.47 7.06 17.88 9.21 16.8 10.81C15.6 12.58 14.45 13.12 13.83 14.26C13.58 14.72 13.48 15.02 13.48 16.5H10.59C10.58 15.72 10.46 14.45 11.07 13.35ZM14 20.5C14 21.6 13.1 22.5 12 22.5C10.9 22.5 10 21.6 10 20.5C10 19.4 10.9 18.5 12 18.5C13.1 18.5 14 19.4 14 20.5Z'
                                    fill='#FFAB00'
                                />
                            </svg>
                        </Box>
                        <Typography sx={{ flex: 6 }} align='left'>
                            {t('resultPage.question')}
                        </Typography>
                        <Typography sx={{ flex: 1 }} align='right'>
                            {numberOfQuestion}
                        </Typography>
                    </ListItem>
                    <ListItem
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'row',
                        }}>
                        <Box sx={{ flex: 0.5 }}>
                            <svg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'>
                                <path d='M12.5 7.5H11V13.5L16.25 16.65L17 15.42L12.5 12.75V7.5Z' fill='#AA00FF' />
                                <path
                                    d='M11.99 2.5C6.47 2.5 2 6.98 2 12.5C2 18.02 6.47 22.5 11.99 22.5C17.52 22.5 22 18.02 22 12.5C22 6.98 17.52 2.5 11.99 2.5ZM12 20.5C7.58 20.5 4 16.92 4 12.5C4 8.08 7.58 4.5 12 4.5C16.42 4.5 20 8.08 20 12.5C20 16.92 16.42 20.5 12 20.5Z'
                                    fill='#AA00FF'
                                />
                            </svg>
                        </Box>
                        <Typography sx={{ flex: 6 }} align='left'>
                            {t('resultPage.average-answer-time')}
                        </Typography>
                        <Typography sx={{ flex: 1 }} align='right'>
                            {averageTime}
                        </Typography>
                    </ListItem>
                </List>
            </CardContent>
        </Card>
    );
};

export default ClassOverallCard;
