import { CheckCircle, CheckRounded, ClearRounded } from '@mui/icons-material';
import { Box, Typography, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { colors } from 'styles/Colors';
import { boolean } from 'yup';
import FeedbackIcon from '../CorrectTopRightIcon/FeedbackIcon';
import { MultipleChoiceResult } from 'pages/results/models';

type MCAnswerProp = {
    mcQuestions: MCQuestionProp[];
    data: string;
};
type MCAnswerInfo = {
    isCorrect: boolean;
    question: string;
    choices: string[];
    answeIdx: number;
    chosenIdx: number;
};

export type MCQuestionProp = {
    id: string;
    answerIndex: number;
    choices: string[];
    questionText: string;
};
const MultipleChoiceAnswer = (prop: MCAnswerProp) => {
    const [resultArr, setResultArr] = useState<MCAnswerInfo[]>([]);
    useEffect(() => {
        if (prop) {
            const mcResult: MultipleChoiceResult = JSON.parse(prop.data) as MultipleChoiceResult;
            const answers: number[] = mcResult?.multipleChoice?.answers;
            let resArr: MCAnswerInfo[] = [];
            prop.mcQuestions.forEach((mc, index) => {
                let res: MCAnswerInfo = {
                    isCorrect: false,
                    question: '',
                    choices: [],
                    answeIdx: -1,
                    chosenIdx: -1,
                };
                res.isCorrect = mc.answerIndex === answers[index];
                res.question = mc.questionText;
                res.choices = mc.choices;
                res.answeIdx = mc.answerIndex;
                res.chosenIdx = answers[index];
                resArr.push(res);
            });
            setResultArr(resArr);
        }
    }, [prop]);
    return (
        <Box
            sx={{
                padding: 0,
                display: 'flex',
                flexDirection: 'column',
                gap: '58px',
            }}>
            {resultArr.length > 0 &&
                resultArr.map((item: MCAnswerInfo, index: number) => {
                    return (
                        <Box
                            sx={{
                                padding: 0,
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '58px',
                            }}>
                            <Typography
                                sx={{
                                    fontWeight: 400,
                                    fontSize: '16px',
                                    color: colors.yellow900,
                                    lineHeight: '150%',
                                }}>
                                {(index + 1).toString() + '. ' + item.question}
                            </Typography>
                            <Box
                                sx={{
                                    // padding: '8px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '16px',
                                    position: 'relative', // Add this

                                    // borderRadius: '4px',
                                    // backgroundColor: '#FAFAFA',
                                }}>
                                <FeedbackIcon isCorrect={item.isCorrect} top={-15} right={-45} />
                                {item.choices.map((choice: string, index: number) => (
                                    <Box
                                        sx={{
                                            paddingTop: '8px',
                                            paddingBottom: '8px',
                                            paddingLeft: '22px',
                                            paddingRight: '22px',
                                            border: 1,
                                            borderRadius: '4px',
                                            backgroundColor: !item.isCorrect && item.answeIdx === index ? '#4CAF50' : item.chosenIdx === index ? '#FEE458' : '#FFFFFF',
                                            marginLeft: '16px',
                                        }}>
                                        <Typography
                                            sx={{
                                                fontWeight: 400,
                                                fontSize: '16px',
                                                color: !item.isCorrect && item.answeIdx === index ? '#FFFFFF' : colors.yellow900,
                                                lineHeight: '150%',
                                                padding: 0,
                                            }}>
                                            {choice}
                                        </Typography>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    );
                })}
        </Box>
    );
};

export default MultipleChoiceAnswer;
