import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Box, Button, Typography, makeStyles, Dialog } from '@mui/material';
import { Check, CheckRounded, Clear, ClearRounded, QuestionMark } from '@mui/icons-material';

import { useState, useEffect } from 'react';
import moment from 'moment';
import { GameType, gameTypeStr, GradingType } from 'pages/results/utils';
import { EasyWritingAnswer } from 'pages/results/EasyWritingAnswer/EasyWritingAnswer';
import { AudioAnswer } from 'pages/results/component/AudioAnswer/AudioAnswer';
import { useAudioResultAnswer } from 'pages/results/component/AnswerCard/hook/useAudioResultAnswer';
import { Results, ResultsPageContext } from 'pages/results/models';
import { useNavigate, useOutletContext } from 'react-router-dom';
import MultipleChoiceAnswer, { MCQuestionProp } from 'pages/results/component/MultipleChoice/MultipleChoiceAnswer';
import HKcsQ2Textbubbles from 'pages/results/component/HkcsQ2/HKcsQ2Textbubbles';
import FeedbackIcon from 'pages/results/component/CorrectTopRightIcon/FeedbackIcon';
import { HkcsQ1QuestionImages, useGetHkcsImagesHook } from 'pages/results/hook/useGetHkcsImagesHook';
import HkcsQ3InGameLayout, { HkcsQuestionImages } from 'pages/results/component/HkcsQ3/HkcsQ3InGameLayout';
import { SelectClassPath, SelectTestroomPath, ClassOverallResultPath } from 'routes/utils';
import { colors } from 'styles/Colors';
import { CardAnswer } from 'pages/results/component/CardAnswer/CardAnswer';
import { WritingAnswer } from 'pages/results/component/WritingAnswer/WritingAnswer';
import { Constants } from 'Constants';

export interface AnswerCardDataProps {
    index: number;
    question: string | undefined;
    questionID: string;
    questionType: GameType;
    correct: boolean;
    grading: boolean;
    results: Results[];
    locale?: Constants.Locale;
}
interface TopRightIconProps {
    isCorrect: boolean;
}
export interface AnswerCardProps {
    data: AnswerCardDataProps;
    onGrading(items: any[], grade: GradingType): void;
}

export const PrintResultAnswerCard = (props: AnswerCardProps) => {
    const { data, onGrading } = props;
    const navigator = useNavigate();

    const { t } = useTranslation();
    const { selectedTestRoom } = useOutletContext<ResultsPageContext>();
    const [selectedAnswer, setSelectedAnswer] = useState<any[]>([]);
    const [grade, setGrade] = useState('');
    const [mcQuestios, setMcQuestions] = useState<MCQuestionProp[]>([]);
    const { setHkcsImage, hkcsImage } = useGetHkcsImagesHook();

    const [isAutoCorrect, SetisAutoCorrect] = useState(true);
    const { playingIdx, isPlaying, playSound } = useAudioResultAnswer({
        isPlaying: false,
        audio: null,
        playingIdx: -1,
    });
    useEffect(() => {
        if (data.questionType === 'Writing') {
            const item: any = Array.isArray(data.results) ? (data.results.length > 0 ? data.results[0] : {}) : {};
            if (Object.keys(item).length !== 0) {
                setSelectedAnswer([item]);
            }
            const obj = JSON.parse(item.data);
            const writing: any = Object.values(obj)[0];
            if (!writing.normalMode) {
                SetisAutoCorrect(false);
            }
        }
        if (data.questionType === 'Audio') {
            const item: any = Array.isArray(data.results) ? (data.results.length > 0 ? data.results[0] : {}) : {};
            if (Object.keys(item).length !== 0) {
                setSelectedAnswer([item]);
            }
            SetisAutoCorrect(false);
        }
        if (data.questionType === 'hkcsQ3') {
            const selectedcs3 = selectedTestRoom.lessonVersion.lessonQuestions.filter((lessonObj) => lessonObj.id === data.questionID);
            console.log(selectedcs3);
            console.log(data);
            const hkcsQ3Obj = JSON.parse(data.results[0].data);
            console.log(hkcsQ3Obj?.hkcsQ3.answer);
        }
        if (data.questionType === 'hkcsQ2') {
            console.log(data);
        }
        if (data.questionType === 'multipleChoice') {
            const selectedMc = selectedTestRoom.lessonVersion.lessonQuestions.filter((lessonObj) => lessonObj.id === data.questionID);
            // console.log(selectedMc[0].content);
            const mCQuestionsObj: MCQuestionProp[] = (selectedMc[0].content?.questions as MCQuestionProp[]) ?? [];
            setMcQuestions(mCQuestionsObj);
        }
        if (data.questionType === 'hkcsQ1') {
            const selectedcs1 = selectedTestRoom.lessonVersion.lessonQuestions.filter((lessonObj) => lessonObj.id === data.questionID);
            const imagesArray: HkcsQ1QuestionImages[] = (selectedcs1[0].content?.questions as HkcsQ1QuestionImages[]) ?? [];
            setHkcsImage(imagesArray ?? []);

            // console.log(selectedMc[0].content);
            // const mCQuestionsObj: MCQuestionProp[] =
            //   selectedMc[0].content?.questions ?? [];
            // setMcQuestions(mCQuestionsObj);
        }
    }, [data]);
    return (
        <Box>
            {data.questionType === 'hkcsQ3' && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '72px',
                    }}>
                    {data?.results?.map((item: any, index: number) => {
                        let wordElement = '';
                        let vocab = '';
                        let imgInfo: HkcsQuestionImages = {
                            id: '',
                            image: '',
                            imageURL: '',
                            flip: -1,
                        };
                        if (data.questionType === 'hkcsQ3') {
                            const selectedcs3 = selectedTestRoom.lessonVersion.lessonQuestions.filter((lessonObj) => lessonObj.id === data.questionID);
                            let index: number = Number(selectedcs3[0].content?.character) ?? 0;
                            wordElement = selectedcs3[0].content?.phrase[index] ?? '';
                            vocab = selectedcs3[0].content?.phrase ?? '';
                            imgInfo = {
                                id: '',
                                image: selectedcs3[0].content?.image ?? '',
                                imageURL: selectedcs3[0].content?.imageURL ?? '',
                                flip: -1,
                            };
                            console.log(selectedcs3);
                            console.log(data);
                        }
                        return (
                            <Box
                                sx={{
                                    padding: 0,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '16px',
                                }}>
                                <HkcsQ3InGameLayout wordElement={wordElement} vocab={vocab} item={item} image={imgInfo} />
                            </Box>
                        );
                    })}
                </Box>
            )}
        </Box>
    );
};

const SoundBoxContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 184px;
    height: 184px;
    border-radius: 8px;
    background: #ffffff;
    position: relative;
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    item-align: center;
    gap: 16px;
`;

const AnswersContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 24px;
    flex-wrap: wrap;
`;

const Cards = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const CardAnswerGroupContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
    background: #f8f0e0;
    border-radius: 12px;
`;

const CardAnswerContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 16px 24px;
    background: ${colors.yellow050};
    border-radius: 12px;
`;

const TopRightIconContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

const TopRightIcon = styled.div<TopRightIconProps>`
    display: flex;
    position: absolute;
    width: 32px;
    height: 32px;
    right: -15px;
    top: -15px;
    border-radius: 50%;
    background: ${(props) => (props.isCorrect ? colors.success400 : colors.error500)};
`;
