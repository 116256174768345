import { gql, useLazyQuery } from '@apollo/client';
import { UserAuthContext } from 'contexts/UserAuthContext';
import { ERROR_TYPE, TTError, handleException } from 'models/ErrorHandling';
import { useCallback, useContext } from 'react';
import { Results } from '../ResultPageModel';

const GET_RESULTS_BY_HOMEWORK = gql`
    query GetResultsByTestroom($homeworkId: ID!) {
        homeworkResults(where: { homework: { id: { equals: $homeworkId } } }) {
            id
            message
            data
            student {
                id
                givenName
                familyName
            }
            startTime
            endTime
            componentId
            correct
            grading
            gameType
        }
    }
`;

export type ModelQueryType = {
    homeworkResults?: Results[];
};

type QueryVariableType = {
    homeworkId: string;
};

export const useHomeworkResultQuery = () => {
    const auth = useContext(UserAuthContext);

    const [getHomeworkResultQuery, { data, loading: resultsLoading }] = useLazyQuery(GET_RESULTS_BY_HOMEWORK);

    const getHomeworkResultData = useCallback(
        async (variables: QueryVariableType): Promise<ModelQueryType | null> => {
            try {
                const result = await getHomeworkResultQuery({
                    variables,
                    fetchPolicy: 'no-cache',
                    notifyOnNetworkStatusChange: true,
                });

                if (!result?.data) {
                    throw new TTError(ERROR_TYPE.NO_DATA);
                }
                return result?.data;
            } catch (err) {
                const errorMsg = handleException(err);
                if (errorMsg === ERROR_TYPE.KS_ACCESS_DENIED) {
                    auth.accessDenied(errorMsg);
                }
                throw new TTError(errorMsg);
            }
        },
        [getHomeworkResultQuery, auth],
    ); // Added dependencies for useCallback

    return { getHomeworkResultData, data, resultsLoading };
};
