import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { LocalizationProvider, MobileDatePicker } from '@mui/lab';
import AdapterMoment from '@mui/lab/AdapterMoment';
import { Autocomplete, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, TextField, TextFieldProps, Typography } from '@mui/material';
import { CancelButton } from 'components/General/Buttons/CancelButton';
import { SubmitButton } from 'components/General/Buttons/SubmitButton';
import { PopupCircularProgress } from 'components/General/Popups/PopupCircularProgress';
import { PopupTitle } from 'components/General/Popups/PopupTitle';
import { FormikProps } from 'formik';
import { ClassForOptionModel } from 'models/class/ClassModel';
import { Moment } from 'moment';
import { TimeSlotDialogState } from 'pages/calendar/types/timeSlotTypes';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
    currentCalendarState: TimeSlotDialogState;
    formik: FormikProps<{
        name: string;
        lessonId: string;
        lessonName: string;
        class: ClassForOptionModel[];
        writingHints: boolean;
        freeStyleMode: boolean;
        startTime: Date | null;
        endTime: Date | null;
    }>;
    handleDelete?: (id: string) => Promise<void>;
    classesData?: ClassForOptionModel[];
    handleClose: () => void;
    handleLessonPickerOpen: () => void;
};

export const PublishHomeworkModal = (props: Props) => {
    const { t } = useTranslation();
    const [deleteOpen, setDeleteOpen] = useState<boolean>(false);

    return (
        <>
            <Dialog open={props.currentCalendarState.isDialogOpen && !props.formik.isSubmitting}>
                <DialogTitle>
                    <PopupTitle>{t('homework.publish.title')}</PopupTitle>
                </DialogTitle>
                <form onSubmit={props.formik.handleSubmit}>
                    <DialogContent>
                        <Typography>{t('homework.publish.description')}</Typography>
                        <Typography>{t('homework.publish.description2')}</Typography>

                        <Stack spacing={2} minWidth={480} sx={{ mt: 3 }}>
                            <TextField
                                id='outlined-basic'
                                label={t('homework.publish.name') + t('homework.publish.optional')}
                                variant='outlined'
                                value={props.formik.values.name}
                                onChange={(e) => {
                                    props.formik.setFieldValue('name', e.target.value);
                                }}
                                error={props.formik.touched.name && !!props.formik.errors.name}
                                helperText={props.formik.touched.name ? props.formik.errors.name : ''}
                            />

                            <TextField
                                id='outlined-basic'
                                label={t('homework.publish.selectLesson')}
                                variant='outlined'
                                value={props.formik.values.lessonName}
                                onClick={() => {
                                    props.handleLessonPickerOpen();
                                }}
                                error={props.formik.touched.lessonId && !!props.formik.errors.lessonId}
                                helperText={props.formik.touched.lessonId ? props.formik.errors.lessonId : ''}
                            />

                            <Autocomplete
                                multiple
                                id='fixed-tags-demo'
                                value={props.formik.values.class}
                                onChange={(event, newValue) => {
                                    props.formik.setFieldValue('class', [...newValue]);
                                }}
                                options={props.classesData || []}
                                getOptionLabel={(option) => `${option.school.schoolName} ${option.className}`}
                                renderOption={(props, option, { selected }) => {
                                    return (
                                        <li {...props}>
                                            <Checkbox
                                                icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                                                checkedIcon={<CheckBoxIcon fontSize='small' />}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.school.schoolName} {option.className}
                                        </li>
                                    );
                                }}
                                style={{ width: 500 }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t('class')}
                                        placeholder={t('class')}
                                        error={props.formik.touched.class && !!props.formik.errors.class}
                                        helperText={props.formik.touched.class ? props.formik.errors.class : ''}
                                    />
                                )}
                            />

                            <Stack direction={'row'} spacing={2}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <MobileDatePicker
                                        onChange={(date: Moment | null, keyboardInputValue?: string) => {}}
                                        value={props.formik.values.startTime}
                                        renderInput={(textProps: TextFieldProps) => (
                                            <TextField
                                                {...textProps}
                                                label={t('startTime')}
                                                fullWidth
                                                error={props.formik.touched.startTime && !!props.formik.errors.startTime}
                                                helperText={props.formik.touched.startTime ? props.formik.errors.startTime : ''}
                                            />
                                        )}
                                        onAccept={(date: Moment | null) => {
                                            if (date) {
                                                props.formik.setFieldValue('startTime', date);
                                            }
                                        }}
                                        cancelText={''}
                                    />
                                </LocalizationProvider>

                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <MobileDatePicker
                                        onChange={(date: Moment | null, keyboardInputValue?: string) => {}}
                                        value={props.formik.values.endTime}
                                        renderInput={(textProps: TextFieldProps) => (
                                            <TextField
                                                {...textProps}
                                                label={t('endTime')}
                                                fullWidth
                                                error={props.formik.touched.endTime && !!props.formik.errors.endTime}
                                                helperText={props.formik.touched.endTime ? props.formik.errors.endTime : ''}
                                            />
                                        )}
                                        onAccept={(date: Moment | null) => {
                                            if (date) {
                                                props.formik.setFieldValue('endTime', date);
                                            }
                                        }}
                                        cancelText={''}
                                    />
                                </LocalizationProvider>
                            </Stack>
                        </Stack>
                    </DialogContent>
                    <DialogActions sx={{ display: 'flex' }}>
                        {props.currentCalendarState.calendarData?.data?.id && (
                            <CancelButton onClick={() => setDeleteOpen(true)} disabled={props.formik.isSubmitting} color='error' variant='outlined' sx={{ mr: 'auto' }}>
                                {t('delete')}
                            </CancelButton>
                        )}

                        <CancelButton onClick={props.handleClose} disabled={props.formik.isSubmitting}>
                            {t('cancel')}
                        </CancelButton>

                        <SubmitButton type='submit' loading={props.formik.isSubmitting}>
                            {t('homework.publish.submit')}
                        </SubmitButton>
                    </DialogActions>
                </form>
            </Dialog>

            <Dialog open={deleteOpen} onClose={() => setDeleteOpen(false)} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
                <DialogTitle id='alert-dialog-title'>{t('confirm-delete')}</DialogTitle>
                <DialogContent sx={{ width: 400 }}>
                    <DialogContentText id='alert-dialog-description'>{t('confirm-delete-description')}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteOpen(false)}>{t('cancel')}</Button>
                    {props.handleDelete && (
                        <Button
                            onClick={() => {
                                setDeleteOpen(false);
                                props.handleDelete?.(props.currentCalendarState.calendarData?.data?.id);
                            }}
                            autoFocus>
                            {t('delete')}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
            <PopupCircularProgress isOpened={props.formik.isSubmitting} />
        </>
    );
};
