import styled from '@emotion/styled';
import { CheckRounded, ClearRounded } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { EasyWritingAnswer } from 'pages/results/EasyWritingAnswer/EasyWritingAnswer';
import { WritingAnswer } from 'pages/results/component/WritingAnswer/WritingAnswer';
import moment from 'moment';
import { AnswerItemProp, Results, SasFillInTheBlanksWritingResult, Strokes, WritingAnswer as WritingAnswerModel } from 'pages/results/models';
import { useTranslation } from 'react-i18next';
import { colors } from 'styles/Colors';
interface TopRightIconProps {
    isCorrect: boolean;
}

export const SasFillInTheBlanksWritingAnswerItem = (props: AnswerItemProp) => {
    const { t } = useTranslation();

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '72px',
            }}>
            {props.answerCardData?.results?.map((item: Results, index: number) => {
                let questionText = '';
                const sasReultObj: SasFillInTheBlanksWritingResult = JSON.parse(item.data);
                const selectedcs3 = props?.selectedTestroom?.lessonVersion?.lessonQuestions.filter((lessonObj) => lessonObj.id === props.answerCardData.questionID);
                if (selectedcs3 === undefined) {
                    return <>no question found</>;
                }
                let questionObj: any = selectedcs3[0].content;
                questionText = questionObj.question;

                return (
                    <Box
                        sx={{
                            padding: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '16px',
                        }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '16px',
                                paddingLeft: '32px',
                            }}>
                            <Typography
                                sx={{
                                    fontWeight: 400,
                                    fontSize: '16px',
                                    color: colors.yellow900,
                                    lineHeight: '150%',
                                }}>
                                {t('answerTry', {
                                    num: (index + 1).toString(),
                                })}
                            </Typography>

                            <Typography
                                sx={{
                                    fontWeight: 400,
                                    fontSize: '16px',
                                    color: colors.yellow900,
                                    lineHeight: '150%',
                                }}>
                                {questionText ?? ''}
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '16px',
                                }}>
                                {sasReultObj.sasFillInTheBlanksWriting.answers.map((item: any, index: number) => {
                                    const writing: WritingAnswerModel = item;
                                    return (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: '16px',
                                            }}>
                                            <Box
                                                sx={{
                                                    border: '1px solid #856666',
                                                    borderRadius: '8px',
                                                    // ...(selectedAnswerIndex === '' + index && {
                                                    //   border: '6px solid #29C0E7',
                                                    // }),
                                                }}>
                                                {writing.normalMode ? (
                                                    <TopRightIconContainer>
                                                        <EasyWritingAnswer strokes={writing.strokes as Strokes[][][]} writingVersion={writing.version} />
                                                        <TopRightIcon isCorrect={props.answerCardData.correct}>
                                                            {props.answerCardData.correct ? (
                                                                <CheckRounded
                                                                    sx={{
                                                                        color: colors.reallyWhite,
                                                                        display: 'block',
                                                                        margin: 'auto',
                                                                    }}
                                                                />
                                                            ) : (
                                                                <ClearRounded
                                                                    sx={{
                                                                        color: colors.reallyWhite,
                                                                        display: 'block',
                                                                        margin: 'auto',
                                                                    }}
                                                                />
                                                            )}
                                                        </TopRightIcon>
                                                    </TopRightIconContainer>
                                                ) : (
                                                    <WritingAnswer strokes={writing.strokes as Strokes[][]} writingVersion={writing.version} />
                                                )}
                                            </Box>
                                        </Box>
                                    );
                                })}
                            </Box>

                            {/* <Dialog fullScreen open={true}>
          <Box
            sx={{
              '@media print': {
                width: '210mm',
                height: '297mm',
              },
              border: 1,
              width: '100%', // Add this
              height: '100%', // Add this
            }}></Box>
          <Button
            sx={{ position: 'absolute', top: 0 }}
            variant='contained'
            onClick={handlePrint}
            className='no-print'>
            Print
          </Button>
        </Dialog> */}
                            <Typography
                                sx={{
                                    fontWeight: 400,
                                    fontSize: '16px',
                                    color: colors.yellow900,
                                }}>
                                {t('answerTime')}: {moment.utc(moment(item?.endTime).diff(moment(item?.startTime))).format('mm:ss')}
                            </Typography>
                        </Box>
                    </Box>
                );
            })}
        </Box>
    );
};

const TopRightIconContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

const TopRightIcon = styled.div<TopRightIconProps>`
    display: flex;
    position: absolute;
    width: 32px;
    height: 32px;
    right: -15px;
    top: -15px;
    border-radius: 50%;
    background: ${(props) => (props.isCorrect ? colors.success400 : colors.error500)};
`;
