import { gql, useLazyQuery } from '@apollo/client';
import { UserAuthContext } from 'contexts/UserAuthContext';
import { ERROR_TYPE, TTError, handleException } from 'models/ErrorHandling';
import { useCallback, useContext } from 'react';
import { enqueueSnackbar } from 'notistack';
import { TestroomWithQuestions } from 'pages/testroom/models/TestroomModel';

export const GET_HOEMWORK = gql`
    query Homework($where: HomeworkWhereUniqueInput!) {
        homework(where: $where) {
            id
            homeworkName
            startTime
            lessonVersion {
                id
                lessonQuestions
                slides {
                    id
                    questionCount
                }
            }
        }
    }
`;

export type ModelQueryType = {
    homework: TestroomWithQuestions;
};
type IDFIlter = {
    id: string;
};
type QueryVariableType = {
    where: IDFIlter;
};

export const useHomeworkQuery = () => {
    const auth = useContext(UserAuthContext);
    const [getHomeworkQuery, { data, loading: resultsLoading }] = useLazyQuery(GET_HOEMWORK);

    const getHomework = useCallback(
        async (variables: QueryVariableType): Promise<ModelQueryType | null> => {
            try {
                console.log('start');
                const result = await getHomeworkQuery({
                    variables,
                    fetchPolicy: 'no-cache',
                    notifyOnNetworkStatusChange: true,
                });
                console.log('receive');

                if (!result?.data) {
                    throw new TTError(ERROR_TYPE.NO_DATA);
                }

                return result?.data;
            } catch (err) {
                const errorMsg = handleException(err);
                if (errorMsg === ERROR_TYPE.KS_ACCESS_DENIED) {
                    auth.accessDenied(errorMsg);
                }
                throw new TTError(errorMsg);
            }
        },
        [getHomeworkQuery, auth],
    ); // Added dependencies for useCallback

    return { getHomework, resultsLoading };
};
