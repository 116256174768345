import { Box, Stack, Typography } from '@mui/material';
import { AnswerCard, AnswerCardDataProps } from 'pages/results/component/AnswerCard/AnswerCard';
import { PopupCircularProgress } from 'components/General/Popups/PopupCircularProgress';
import { Results } from 'models/resultPage/ResultPageModel';
import { AnswerAccordion } from 'pages/results/component/AnswerAccordion';
import OverallStudentGradingView from 'pages/results/component/GradingPage/OverallStudentGradingView';
import StudentHeader from 'pages/results/component/GradingPage/StudentHeader';
import { GradingStatus } from 'pages/results/component/GradingStatus';
import { useUpdateResultHook } from 'pages/results/hook/updateResult/useUpdateResultHook';
import { useTestroomResult } from 'pages/results/hook/useTestroomResult';
import { ResultsPageContext } from 'pages/results/models';
import { gradeVariants, GradingType, gradingTypeStr } from 'pages/results/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext, useParams } from 'react-router-dom';
export const questionCharacter = (resultData: any) => {
    const question: any = Object.values(resultData)[0];
    return /[0-9]/.test(question.character) ? question.character.replace(/[0-9]/g, '') : question.character;
};

export const GradingPage = () => {
    const { selectedTestRoom, selectedStudent, selectedQuestion, gradedItems, setGradedItems } = useOutletContext<ResultsPageContext>();
    const { sortedStudentData, prepareStudentTestroomResults } = useTestroomResult();
    const [loadGradingPage, setLoadGradingPage] = useState(false);
    const { testroomId, studentId, questionIndex, mode } = useParams();

    const [updateResultLoading, setUpdateResultLoading] = useState(false);
    const [answersData, setAnswersData] = useState<AnswerCardDataProps[]>([]);
    const [expandedControl, setExpandedControl] = useState<{ accordionId: string; isExpanded: boolean }[]>([]);
    const [score, setScore] = useState('');
    const [rank, setRank] = useState('');
    const [time, setTime] = useState('');
    const [unattempted, setUnattempted] = useState('');
    const { onSaveGrading } = useUpdateResultHook();
    const { t } = useTranslation();

    const onGrading = (results: Results[], grade: GradingType) => {
        setGradedItems((prevState: any[]) => [{ componentID: results[0].componentId, items: results, grade: grade }]);
        onSaveGrading([{ results: results, grade: grade }]);
        setExpandedControl((prevState) => {
            let newArr = [...prevState];
            const newState = newArr.map((controlObj) => {
                if (controlObj.accordionId === results[0].componentId) {
                    return {
                        accordionId: results[0].componentId,
                        isExpanded: false,
                    };
                } else {
                    return controlObj;
                }
            });
            return newState;
        });
    };

    useEffect(() => {
        if (answersData.length > 0) {
            setExpandedControl(
                answersData.map((item) => {
                    return { accordionId: item.questionID, isExpanded: true };
                }),
            );
            if (selectedQuestion || questionIndex) {
                const accordion = document?.getElementById(`answerAccordion-${selectedQuestion ?? questionIndex}`);
                accordion?.scrollIntoView();
            }
        }
    }, [answersData, questionIndex, selectedQuestion]);

    useEffect(() => {
        if (sortedStudentData && selectedStudent) {
            const student = sortedStudentData.find((stud) => stud.id === selectedStudent.id);
            const studentIdx = sortedStudentData.findIndex((stud) => stud.id === selectedStudent.id);
            setScore(student?.totalScore ? student?.totalScore.toString() : '0');
            setTime(student?.totalTestDuration ? student?.totalTestDuration.toString() : '00:00');
            setRank(studentIdx !== -1 ? (studentIdx + 1).toString() + '/' + sortedStudentData.length.toString() : '0');
        }
    }, [selectedStudent, sortedStudentData]);

    useEffect(() => {
        const setAnswerCard = async () => {
            setLoadGradingPage(true);
            if (studentId) {
                const answers = await prepareStudentTestroomResults(studentId);
                if (answers) {
                    setAnswersData(answers);
                }
            }
            setLoadGradingPage(false);
        };
        setAnswerCard();
    }, [prepareStudentTestroomResults, studentId]);

    return (
        <Stack direction='column' spacing={1} className='pageCustom'>
            <Box sx={{ backgroundColor: 'white' }}>
                <StudentHeader studentName={(selectedStudent?.familyName ?? '') + (selectedStudent?.givenName ?? '')} />
                {/* <Button
          onClick={() => {
            navigator(
              `../${SelectClassPath}/${schoolId}/${SelectTestroomPath}/${classId}/${SelectStudentPath}/${testroomId}/${SelectQuestionPath}/${studentId}/${GradingPath}/PrintResult`,
            );
          }}>
          test
        </Button> */}
                <PopupCircularProgress isOpened={updateResultLoading || loadGradingPage} />
                <OverallStudentGradingView notAttempted={unattempted} rank={rank} totalScore={score} totalTime={time} />
                {answersData.map((answer, index) => {
                    const gradedItem = gradedItems.find((item: any) => item?.componentID === answer.questionID);
                    let gradeType: GradingType = 'n/a';
                    if (Array.isArray(answer.results)) {
                        if (answer.results.length > 0) {
                            gradeType = 'ungraded';
                            if (answer.questionType === 'Writing') {
                                if (JSON.parse(answer.results[0].data).writing?.normalMode) {
                                    gradeType = answer.correct ? 'correct' : 'incorrect';
                                } else {
                                    if (answer.grading) {
                                        gradeType = answer.correct ? 'correct' : 'incorrect';
                                    }
                                }
                            } else if (answer.questionType === 'Audio') {
                                if (answer.grading) {
                                    gradeType = answer.correct ? 'correct' : 'incorrect';
                                }
                            } else if (answer.questionType === 'multipleChoice') {
                                if (answer.grading) {
                                    gradeType = answer.correct ? 'correct' : 'incorrect';
                                }
                            } else {
                                gradeType = answer.correct ? 'correct' : 'incorrect';
                            }
                        }
                    }
                    if (gradedItem) {
                        gradeType = gradedItem?.grade;
                        answer.grading = gradedItem?.grade;
                        answer.correct = gradedItem?.grade === 'correct' ? true : false;
                    }
                    let questionName = '';
                    if (answer.questionType === 'multipleChoice') {
                        questionName = t('hksas.mctitle');
                    } else if (answer.questionType === 'sasAudio') {
                        const sasObj = selectedTestRoom.lessonVersion.lessonQuestions.filter((lessonObj) => lessonObj.id === answer.questionID);
                        if (sasObj.length > 0) {
                            if (sasObj[0].content) {
                                const sasContent: any = sasObj[0].content;
                                questionName = sasContent?.questionText.toString();
                            }
                        }
                    } else if (answer.questionType === 'sasFillInTheBlanks') {
                        questionName = t('hksas.fillInTheBlanks.title');
                    } else if (answer.questionType === 'sasFillInTheBlanksWriting') {
                        questionName = t('hksas.fillInTheBlanksWriting.title');
                    } else {
                        questionName = answer.question ?? '';
                    }
                    return (
                        <AnswerAccordion
                            id={`answerAccordion-${index}`}
                            index={index}
                            summaryChildren={
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: '90%',
                                    }}>
                                    <Typography
                                        variant='h6'
                                        sx={{
                                            display: 'flex',
                                            marginRight: '44px',
                                            gap: '12px',
                                        }}>
                                        {index + 1}.{/* <HighlightedQuestionName> */}
                                        {questionName}
                                        {/* </HighlightedQuestionName> */}
                                    </Typography>
                                    <GradingStatus variants={gradeVariants(gradeType)}>{gradingTypeStr(gradeType)}</GradingStatus>
                                </Box>
                            }
                            isExpand={expandedControl[index]?.isExpanded}>
                            {answer.results.length > 0 ? <AnswerCard data={answer} onGrading={onGrading} /> : <span>No Result</span>}
                        </AnswerAccordion>
                    );
                })}
            </Box>
        </Stack>
    );
};
