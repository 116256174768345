import { Box, CardActionArea, CardContent, CardMedia, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { PopupTitle } from 'components/General/Popups/PopupTitle';
import { LessonModel } from 'models/lesson/LessonModel';
import { SlidePreviewContextProvider } from 'pages/lessons/contexts/SlidePreviewContext';
import { ratio } from 'pages/lessons/hooks/useLessonSlide';
import { SlidePreview } from 'pages/testroom/views/SlidePreview';
import { useTranslation } from 'react-i18next';

type Props = {
    isOpen: boolean;
    onCloseHandler: () => void;
    onClickHandler: (lesson: LessonModel) => void;
    lessonData: LessonModel[];
};
export const LessonPicker = (props: Props) => {
    const { t } = useTranslation();

    return (
        <Dialog open={props.isOpen} onClose={props.onCloseHandler} maxWidth='md' fullWidth PaperProps={{ style: { height: '70%' } }}>
            <DialogTitle>
                <PopupTitle sx={{ fontSize: 30, ml: 2 }}>{t('homework.publish.selectLesson')}</PopupTitle>
            </DialogTitle>

            <DialogContent>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    {props.lessonData?.map((lesson, index) => (
                        <CardActionArea
                            sx={{ flexBasis: 1 }}
                            key={index}
                            onClick={() => {
                                props.onClickHandler(lesson);
                                props.onCloseHandler();
                            }}>
                            <CardMedia component='div'>
                                <Box
                                    sx={{
                                        width: 200,
                                        aspectRatio: `${ratio}`,
                                        overflow: 'auto',
                                        overflowX: 'hidden',
                                        overflowY: 'hidden',
                                    }}>
                                    {lesson.latestLessonDraft?.slides && lesson.latestLessonDraft?.slides.length > 0 && (
                                        <SlidePreviewContextProvider>
                                            <SlidePreview data={lesson.latestLessonDraft?.slides[0].data} hasBorderColor={false} borderRadius='0px' />
                                        </SlidePreviewContextProvider>
                                    )}
                                </Box>
                            </CardMedia>
                            <CardContent
                                sx={{
                                    position: 'relative',
                                }}>
                                <Typography
                                    gutterBottom
                                    component='div'
                                    sx={{
                                        fontSize: '1rem',
                                        color: '#5A4522',
                                        fontWeight: 'medium',
                                        lineHeight: '21px',
                                    }}>
                                    {lesson.latestLessonDraft?.lessonName}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    ))}
                </Box>
            </DialogContent>
        </Dialog>
    );
};
