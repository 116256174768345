import { gql, useMutation } from '@apollo/client';
import { UserAuthContext } from 'contexts/UserAuthContext';
import { ERROR_TYPE, TTError, handleException } from 'models/ErrorHandling';
import { useCallback, useContext } from 'react';

const SAVE_GQL = gql`
    mutation DeleteHomeworks($where: [HomeworkWhereUniqueInput!]!) {
        deleteHomeworks(where: $where) {
            id
        }
    }
`;

type MutationVariableType = {
    where: {
        id: string;
    }[];
};

type MutationType = {
    deleteHomeworks: { id: string }[];
};

export const useDeleteHomeworksMutation = () => {
    const { accessDenied } = useContext(UserAuthContext);
    const [fetchMutation] = useMutation<MutationType>(SAVE_GQL);

    const runMutation = useCallback(
        async (variables: MutationVariableType) => {
            try {
                const result = await fetchMutation({
                    variables,
                });

                if (!result || !result.data?.deleteHomeworks) {
                    throw new TTError(ERROR_TYPE.UPDATE_FAILED);
                }

                return result.data.deleteHomeworks;
            } catch (err) {
                console.log(err);

                const errMessage = handleException(err);
                if (errMessage === ERROR_TYPE.KS_ACCESS_DENIED) {
                    accessDenied(errMessage);
                }

                throw new TTError(errMessage);
            }
        },
        [fetchMutation, accessDenied],
    );

    return { runMutation };
};
