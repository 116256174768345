import React from 'react';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import { useTestroomResult } from '../hook/useTestroomResult';
import moment from 'moment';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

const CsvGenerator = () => {
    const { resultByQuestion } = useTestroomResult();
    const { t } = useTranslation();

    const createCsv = () => {
        let studentNameHeaders: string[] = [];
        if (resultByQuestion.length > 0) {
            studentNameHeaders = resultByQuestion[0].studentPerformances.map((s) => {
                return s.studentName;
            });
        }
        let questionHeaders: string[] = [];
        if (resultByQuestion.length > 0) {
            questionHeaders = resultByQuestion.map((s) => {
                return `${s.question}_${s.questionType}`;
            });
        }
        const resRows = [];

        if (resultByQuestion.length > 0) {
            const studentNumber = resultByQuestion[0].studentPerformances.length;
            for (let i = 0; i < studentNumber; i++) {
                let resRow: string[] = [];

                resultByQuestion.forEach((res) => {
                    const studentPerformance = res.studentPerformances[i];
                    const answerTime = moment.utc(studentPerformance.averageTime).isValid() ? moment.utc(studentPerformance.averageTime).format('mm:ss') : '00:00';
                    const questionResultData = `${studentPerformance.studentScore},${studentPerformance.questionAttempt},${answerTime}`;

                    resRow.push(questionResultData);
                });
                resRows.push(resRow);
            }
            studentNameHeaders = resultByQuestion[0].studentPerformances.map((s) => {
                return s.studentName;
            });
        }

        const newCsvData: string[][] = [];

        // Add headers for the CSV
        const headers: string[] = ['名字'];
        for (let j = 0; j < questionHeaders.length; j++) {
            headers.push('', `${questionHeaders[j]}`, '');
        }
        newCsvData.push(headers);

        // Add sub-headers for the CSV
        const subHeaders: string[] = [''];
        for (let j = 0; j < questionHeaders.length; j++) {
            subHeaders.push(`分數`, `次數`, `時間`);
        }
        newCsvData.push(subHeaders);

        // Process each row to split cells into sub-columns and add ID
        resRows.forEach((row, index) => {
            const newRow: string[] = [studentNameHeaders[index]]; // Add ID column
            row.forEach((cell) => {
                const splitCells = cell.split(',');
                newRow.push(...splitCells);
            });
            newCsvData.push(newRow);
        });

        const csvContent = Papa.unparse(newCsvData);
        const blob = new Blob(['\uFEFF' + csvContent], {
            type: 'text/csv;charset=utf-8;',
        });
        saveAs(blob, 'result.csv');
    };

    return (
        <Box>
            <Button variant='contained' onClick={createCsv}>
                {t('resultPage.generate-csv')}
            </Button>
        </Box>
    );
};

export default CsvGenerator;
