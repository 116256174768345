import { gql, useMutation } from '@apollo/client';
import { UserAuthContext } from 'contexts/UserAuthContext';
import { ERROR_TYPE, TTError, handleException } from 'models/ErrorHandling';
import { useCallback, useContext } from 'react';
import { HomeworkModel } from './HomeworkModel';

const SAVE_GQL = gql`
    mutation CreateHomework(
        $name: String!
        $lessonVersion: ID!
        $class: ClassRelateToManyForCreateInput!
        $writingHints: Boolean!
        $freeStyleMode: Boolean!
        $startTime: DateTime!
        $endTime: DateTime!
    ) {
        createHomework(
            data: {
                homeworkName: $name
                lessonVersion: { connect: { id: $lessonVersion } }
                class: $class
                writingHints: $writingHints
                freeStyleMode: $freeStyleMode
                startTime: $startTime
                endTime: $endTime
            }
        ) {
            id
            homeworkName
            lessonVersion {
                id
            }
            class {
                id
            }
            writingHints
            freeStyleMode
        }
    }
`;

type MutationVariableType = {
    name: string;
    class: {
        connect: {
            id: string;
        }[];
    };
    writingHints: boolean;
    freeStyleMode: boolean;
    lessonVersion: string;
    startTime: Date;
    endTime: Date;
};

type MutationType = {
    createHomework: HomeworkModel;
};

export const useCreateHomeworkMutation = () => {
    const { accessDenied } = useContext(UserAuthContext);
    const [fetchMutation] = useMutation<MutationType>(SAVE_GQL);

    const runMutation = useCallback(
        async (variables: MutationVariableType) => {
            try {
                const result = await fetchMutation({
                    variables,
                });

                if (!result || !result.data?.createHomework) {
                    throw new TTError(ERROR_TYPE.UPDATE_FAILED);
                }

                return result.data.createHomework;
            } catch (err) {
                console.log(err);

                const errMessage = handleException(err);
                if (errMessage === ERROR_TYPE.KS_ACCESS_DENIED) {
                    accessDenied(errMessage);
                }

                throw new TTError(errMessage);
            }
        },
        [fetchMutation, accessDenied],
    );

    return { runMutation };
};
