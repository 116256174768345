import { UserAuthContext } from 'contexts/UserAuthContext';
import { useDeleteHomeworksMutation } from 'models/homework/useDeleteHomeworksMutation';
import { useHomeworkModelQuery } from 'models/homework/useHomeworkModelQuery';
import { MutationVariableType, useUpdateHomeworksMutation } from 'models/homework/useUpdateHomeworksMutation';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Pagination = {
    pageSize: number;
    page: number;
};

export enum HomeworkStatus {
    all = 'all',
    started = 'started',
    ended = 'ended',
    archived = 'archived',
}

export const useHomeworksHook = () => {
    const { t } = useTranslation();
    const { getHomeworks, data, loading } = useHomeworkModelQuery();
    const { runMutation: runMutationDeleteHomework } = useDeleteHomeworksMutation();
    const { runMutation: runMutationUpdateHomeworks } = useUpdateHomeworksMutation();

    const { userData } = useContext(UserAuthContext);

    const [pagination, setPagination] = useState<Pagination>({
        pageSize: 10,
        page: 0,
    });

    const [homeworkFilter, setHomeworkFilter] = useState<HomeworkStatus>(HomeworkStatus.all);

    const refresh = useCallback(async () => {
        if (pagination) {
            try {
                const response = await fetch(`${process.env.REACT_APP_MAIN_SERVICE_URL}/server`);
                const serverTime = (await response.json()).time;

                await getHomeworks({
                    skip: pagination.page * pagination.pageSize,
                    take: pagination.pageSize,
                    where: {
                        teacher: {
                            id: {
                                equals: userData?.teacherProfileId ?? '',
                            },
                        },

                        isArchived: {
                            equals: homeworkFilter === HomeworkStatus.archived,
                        },

                        ...(homeworkFilter === HomeworkStatus.started && {
                            endTime: {
                                gt: serverTime,
                            },
                        }),

                        ...(homeworkFilter === HomeworkStatus.ended && {
                            endTime: {
                                lte: serverTime,
                            },
                        }),
                    },
                });
            } catch (err: any) {
                enqueueSnackbar(t(`error.${err.message}`), {
                    variant: 'error',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                });
            }
        }
    }, [getHomeworks, pagination, t, userData?.teacherProfileId, homeworkFilter]);

    useEffect(() => {
        refresh();
    }, [pagination, refresh]);

    const handleDelete = useCallback(
        async (ids: { id: string }[]) => {
            try {
                await runMutationDeleteHomework({ where: ids });
                refresh();
            } catch (err: any) {
                enqueueSnackbar(t(`error.${err.message}`), {
                    variant: 'error',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                });
            }
        },
        [runMutationDeleteHomework, refresh, t],
    );

    const handleArchive = useCallback(
        async (data: MutationVariableType['data']) => {
            try {
                await runMutationUpdateHomeworks({ data });
                refresh();
            } catch (err: any) {
                enqueueSnackbar(t(`error.${err.message}`), {
                    variant: 'error',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                });
            }
        },
        [refresh, runMutationUpdateHomeworks, t],
    );

    return {
        data,
        loading,
        refresh,
        pagination,
        setPagination,
        homeworkFilter,
        setHomeworkFilter,
        handleDelete,
        handleArchive,
    };
};
