import { StudentId, useGetClassStudentIds } from 'models/resultPage/getClassStudentIds';
import { ClassQuestionCountModel, useGetTestroomQuestionCount } from 'models/resultPage/useGetTestroomQuestionCount';
import { useTestroomAnswerStatus } from 'models/resultPage/useTestroomAnswerStatus';
import { useParams } from 'react-router-dom';
import { useGetStudentWorkingTime } from './useGetStudentWorkingTime';
// export type StudentProfileWithResults = StudentProfile & TestroomResults;
interface StudentResult {
    correctResult: number;
    studentId: string;
    totalQuestion: number;
    totalTime: number;
    studentName: string;
}
export interface TestroomIdResult {
    correctResult: number;
    incorrectResult: number;
    testroomId: string;
    notAttemptedQuestion: number;
}
type studentResultPerTestroom = {
    testroomId: string;
    studentName: string;
    correctResult: number;
    incorrectResult: number;
    studentId: string;
    notAttemptedQuestion: number;
    totalQuestion: number;
    totalTime: number;
};

function calculateAvarageScores(data: StudentResult[]) {
    // Create an empty object to store the results
    let results: {
        [key: string]: {
            totalCorrect: number;
            count: number;
            averageCorrect?: number;
            totalQuestion: number;
            studentId: string;
            studentName: string;
            totalTime: number;
        };
    } = {};

    // Iterate over the data array
    data.forEach((item) => {
        // If the student ID is not in the results object, add it
        if (!results[item.studentId]) {
            results[item.studentId] = {
                totalCorrect: 0,
                count: 0,
                totalQuestion: 0,
                studentId: item.studentId,
                studentName: item.studentName,
                totalTime: item.totalTime,
            };
        }

        // Add the correctResult to the totalCorrect for this student ID
        results[item.studentId].totalCorrect += item.correctResult;
        results[item.studentId].totalQuestion += item.totalQuestion;

        // Increment the count for this student ID
        results[item.studentId].count++;
    });

    // Calculate the average for each student ID
    for (let studentId in results) {
        results[studentId].averageCorrect = results[studentId].totalCorrect / results[studentId].count;
    }
    console.log(results);
    return Object.values(results);

    // return results;
}
function calculateTestroomScores(data: TestroomIdResult[]) {
    // Create an empty object to store the results
    let results: {
        [key: string]: {
            totalCorrect: number;
            totalIncorrectResult: number;
            count: number;
            notAttemptedQuestion: number;
        };
    } = {};

    // Iterate over the data array
    data.forEach((item) => {
        // If the student ID is not in the results object, add it
        if (!results[item.testroomId]) {
            results[item.testroomId] = {
                totalCorrect: 0,
                count: 0,
                totalIncorrectResult: 0,
                notAttemptedQuestion: item.notAttemptedQuestion,
            };
        }

        // Add the correctResult to the totalCorrect for this student ID
        results[item.testroomId].totalCorrect += item.correctResult;
        results[item.testroomId].totalIncorrectResult += item.incorrectResult;
        results[item.testroomId].notAttemptedQuestion += item.notAttemptedQuestion;

        // Increment the count for this student ID
        results[item.testroomId].count++;
    });

    // Calculate the average for each student ID

    // console.log(results);
    return results;
}

export const useOverallTestroom = () => {
    const { getStudentIds } = useGetClassStudentIds();
    const { getQuestionCountByClass } = useGetTestroomQuestionCount();
    const { getCorrectAttempts } = useTestroomAnswerStatus();
    const { getTotalTime } = useGetStudentWorkingTime();
    const { classId } = useParams();
    const getTestroomIds = async () => {
        try {
            const questionCountModel = await getQuestionCountByClass({
                classId: classId ?? '',
            });
            const studentData = await getStudentIds({
                classId: classId ?? '',
            });
            const studentIds = studentData?.class?.students ?? [];
            if (questionCountModel?.class !== undefined && studentIds !== undefined) {
                const result = getOverallAnswerStatus(questionCountModel?.class, studentIds);
                return result;
            }
        } catch (error: any) {
            console.log(error);
            console.log(error?.message);
        }
    };
    const getOverallAnswerStatus = async (classQuestionCountModel: ClassQuestionCountModel, studentIds: StudentId[]) => {
        try {
            const results: studentResultPerTestroom[] = [];
            for (const studentData of studentIds) {
                const totalTime = await getTotalTime(studentData.id);

                for (const testroomIdFromQuery of classQuestionCountModel.testrooms) {
                    const correctAttempts = await getCorrectAttempts({
                        testroomId: testroomIdFromQuery.id,
                        studentId: studentData.id,
                    });
                    const totalQuestion = testroomIdFromQuery.lessonVersion.slides.reduce((accumulator, currentValue) => accumulator + currentValue.questionCount, 0);
                    // const questionCount =
                    // const questionTypeTotalCount = await getQuestionTypeCount();

                    results.push({
                        testroomId: testroomIdFromQuery.id,
                        correctResult: correctAttempts?.getGroupedQuestionId?.correctResult ?? 0,
                        incorrectResult: correctAttempts?.getGroupedQuestionId?.incorrectResult ?? 0,
                        studentId: studentData.id,
                        totalTime: totalTime ?? 0,
                        studentName: studentData.familyName + studentData.givenName,
                        notAttemptedQuestion: totalQuestion - (correctAttempts?.getGroupedQuestionId?.correctResult ?? 0) - (correctAttempts?.getGroupedQuestionId?.incorrectResult ?? 0),
                        totalQuestion,
                    });
                }
            }
            const avgScorePerStudent = calculateAvarageScores(results);
            const testroomScore = calculateTestroomScores(results);
            console.log(avgScorePerStudent);

            const totalAvgScore = avgScorePerStudent.reduce((accumulator, currentValue) => accumulator + (currentValue?.averageCorrect ?? 0), 0);
            const avgScoreAllStudent = totalAvgScore / (studentIds.length ?? 1);
            return {
                avgScorePerStudent,
                testroomScore,
                avgScoreAllStudent,
                studentCount: studentIds.length,
            };
        } catch (error: any) {
            console.log(error);
            console.log(error?.message);
        }
    };
    return { getTestroomIds };
};
