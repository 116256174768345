import { gql } from '@apollo/client';

export type CreateSessionData = {
    authenticateWithClassroom:
        | { item: { id: string; __typename: 'StudentProfile' }; sessionToken: string; __typename: 'AuthenticateWithClassroomSuccess' }
        | { message: string; __typename: 'AuthenticateWithClassroomFailure' };
};

export type CreateSessionVariables = {
    studentProfileId: string;
    ttl?: number;
};

export const CREATE_SESSION = gql`
    mutation AuthenticateWithClassroom($studentProfileId: ID!, $ttl: Int) {
        authenticateWithClassroom(studentProfileId: $studentProfileId, ttl: $ttl) {
            ... on AuthenticateWithClassroomSuccess {
                sessionToken
                item {
                    id
                }
            }
            ... on AuthenticateWithClassroomFailure {
                message
            }
        }
    }
`;
