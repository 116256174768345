import { gql, useLazyQuery } from '@apollo/client';
import { UserAuthContext } from 'contexts/UserAuthContext';
import { ERROR_TYPE, TTError, handleException } from 'models/ErrorHandling';
import { useCallback, useContext } from 'react';
import { StudentProfile } from './ResultPageModel';

export const GET_STUDENTS = gql`
    query GetStudents($classId: ID!) {
        studentProfiles(where: { AND: [{ class: { id: { equals: $classId } } }, { NOT: { user: null } }] }) {
            id
            studentNumber
            givenName
            familyName
        }
    }
`;
type QueryVariableType = {
    classId: string;
};

type ModelQueryType = {
    studentProfiles: StudentProfile[];
};

export const useStudentInfoQuery = () => {
    const { accessDenied } = useContext(UserAuthContext);

    const [getStudents, { data: studentData, loading: studentLoading }] = useLazyQuery(GET_STUDENTS);

    const getStudentData = useCallback(
        async (variables: QueryVariableType): Promise<ModelQueryType | null> => {
            try {
                const result = await getStudents({
                    variables,
                    fetchPolicy: 'no-cache',
                    notifyOnNetworkStatusChange: true,
                });

                if (!result?.data) {
                    throw new TTError(ERROR_TYPE.NO_DATA);
                }

                return result?.data;
            } catch (err) {
                const errorMsg = handleException(err);
                if (errorMsg === ERROR_TYPE.KS_ACCESS_DENIED) {
                    accessDenied(errorMsg);
                }
                throw new TTError();
            }
        },
        [accessDenied, getStudents],
    ); // Add dependencies here
    return { getStudentData, studentData, studentLoading };
};
