import styled from '@emotion/styled';
import { Button, Typography } from '@mui/material';
import { Constants } from 'Constants';
import { Results, ResultsPageContext } from 'pages/results/models';
import { GameType, gameTypeStr, GradingType } from 'pages/results/utils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { colors } from '../../../../styles/Colors';
import { AudioAnswerItem } from './ResultComponents/AudioResultComponent';
import { CardAnswersItem } from './ResultComponents/CardResultComponent';
import { HkcsEnQ1AnswerItem } from './ResultComponents/HkcsEnQ1ResultComponent';
import { HkcsEnQ2AnswerItem } from './ResultComponents/HkcsEnQ2ResultComponent';
import { HkcsQ1AnswerItem } from './ResultComponents/HkcsQ1ResultComponent';
import { HkcsQ2AnswerItem } from './ResultComponents/HkcsQ2ResultComponent';
import { HkcsQ3AnswerItem } from './ResultComponents/HkcsQ3ResultComponent';
import { MultipleChoiceAnswerItem } from './ResultComponents/MultipleChoiceResultComponent';
import { SasAudioResultAnswerItem } from './ResultComponents/SasAudioResultComponent';
import { SasFillInTheBlanksAnswerItem } from './ResultComponents/SasFillInTheBlanksComponent';
import { SasFillInTheBlanksWritingAnswerItem } from './ResultComponents/SasFillInTheBlanksWritingComponent';
import { SasRadicalMatchingAnswerItem } from './ResultComponents/SasRadicalMatchingComponent';
import { useAnswerCardHook } from './hook/UseAnswerCardHook';
import { WritingAnswerItem } from './ResultComponents/WritingResultsComponent';
export interface AnswerCardDataProps {
    index: number;
    question: string | undefined;
    questionID: string;
    questionType: GameType;
    correct: boolean;
    grading: boolean;
    results: Results[];
    locale?: Constants.Locale;
}

export interface AnswerCardProps {
    data: AnswerCardDataProps;
    onGrading(items: Results[], grade: GradingType): void;
}

export const AnswerCard = (props: AnswerCardProps) => {
    const { data: answerCardData, onGrading } = props;
    const locale = props.data.locale ?? 'zh-HK';
    const { t } = useTranslation();
    const { selectedTestRoom } = useOutletContext<ResultsPageContext>();
    const [grade, setGrade] = useState('');

    const { selectedAnswers, isAutoCorrect } = useAnswerCardHook(answerCardData);

    return (
        <Container>
            <Typography
                sx={{
                    fontWeight: 400,
                    fontSize: '16px',
                    color: colors.yellow900,
                    lineHeight: '150%',
                }}>
                {t('type')}: {gameTypeStr(answerCardData.questionType)}
            </Typography>
            {(answerCardData.questionType === 'Writing' || answerCardData.questionType === 'hkcsQ3' || answerCardData.questionType === 'hkcsEnQ2') && (
                <Typography
                    sx={{
                        fontWeight: 700,
                        fontSize: '14px',
                        color: '#0380F4',
                    }}>
                    {t('pleaseHoverToPlay')}
                </Typography>
            )}
            <AnswersContainer>
                {answerCardData.questionType === 'Writing' && WritingAnswerItem({ selectedTestroom: selectedTestRoom, answerCardData: answerCardData })}
                {answerCardData.questionType === 'hkcsQ3' && HkcsQ3AnswerItem({ selectedTestroom: selectedTestRoom, answerCardData: answerCardData })}
                {answerCardData.questionType === 'hkcsQ2' && HkcsQ2AnswerItem({ selectedTestroom: selectedTestRoom, answerCardData: answerCardData })}
                {answerCardData.questionType === 'hkcsQ1' && HkcsQ1AnswerItem({ selectedTestroom: selectedTestRoom, answerCardData: answerCardData })}
                {answerCardData.questionType === 'hkcsEnQ2' && HkcsEnQ2AnswerItem({ selectedTestroom: selectedTestRoom, answerCardData: answerCardData })}
                {answerCardData.questionType === 'hkcsEnQ1' && HkcsEnQ1AnswerItem({ selectedTestroom: selectedTestRoom, answerCardData: answerCardData })}
                {answerCardData.questionType === 'multipleChoice' && MultipleChoiceAnswerItem({ selectedTestroom: selectedTestRoom, answerCardData: answerCardData })}
                {answerCardData.questionType === 'Audio' && AudioAnswerItem({ selectedTestroom: selectedTestRoom, answerCardData: answerCardData })}
                {answerCardData.questionType === 'sasAudio' && SasAudioResultAnswerItem({ selectedTestroom: selectedTestRoom, answerCardData: answerCardData })}
                {answerCardData.questionType === 'sasFillInTheBlanks' && SasFillInTheBlanksAnswerItem({ selectedTestroom: selectedTestRoom, answerCardData: answerCardData })}
                {answerCardData.questionType === 'sasFillInTheBlanksWriting' && SasFillInTheBlanksWritingAnswerItem({ selectedTestroom: selectedTestRoom, answerCardData: answerCardData })}
                {answerCardData.questionType === 'sasRadicalMatching' && SasRadicalMatchingAnswerItem({ selectedTestroom: selectedTestRoom, answerCardData: answerCardData })}
                {(answerCardData.questionType === 'OneCard' || answerCardData.questionType === 'TwoCards') && CardAnswersItem(answerCardData, locale)}
            </AnswersContainer>
            {!isAutoCorrect && (
                <ButtonsContainer>
                    <Button
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            padding: '8px 16px',
                            background: '#2E7D32',
                            borderRadius: '4px',
                            color: '#FFFFFF',
                            width: '132px',
                            fontWeight: '700',
                            fontSize: '15px',
                            lineHeight: '150%',
                            '&:hover': {
                                backgroundColor: '#00953A',
                            },
                            ...(grade === 'correct' && {
                                filter: 'drop-shadow(0px 0px 8px #00953A)',
                            }),
                        }}
                        disabled={selectedAnswers?.length === 0}
                        // startIcon={<Check />}
                        onClick={() => {
                            onGrading(selectedAnswers, 'correct');
                            setGrade('correct');
                        }}>
                        {t('correct')}
                    </Button>

                    <Button
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            padding: '8px 16px',
                            background: '#D32F2F',
                            borderRadius: '4px',
                            color: '#FFFFFF',
                            width: '132px',
                            fontWeight: '700',
                            fontSize: '15px',
                            lineHeight: '150%',
                            '&:hover': {
                                backgroundColor: '#F04438',
                            },
                            ...(grade === 'incorrect' && {
                                filter: 'drop-shadow(0px 0px 8px #F04438)',
                            }),
                        }}
                        disabled={selectedAnswers?.length === 0}
                        // startIcon={<Clear />}
                        onClick={() => {
                            onGrading(selectedAnswers, 'incorrect');
                            setGrade('incorrect');
                        }}>
                        {t('incorrect')}
                    </Button>
                </ButtonsContainer>
            )}
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    item-align: center;
    gap: 16px;
`;

const AnswersContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 24px;
    flex-wrap: wrap;
`;
