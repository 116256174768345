import { useState } from 'react';

import { useParams } from 'react-router-dom';

import { SlideComponent } from 'models/lesson/LessonModel';
import { Testroom, useGetQuestionTypeData } from 'models/resultPage/useGetQuestionTypeData';

// export type StudentProfileWithResults = StudentProfile & TestroomResults;

export const useGetQuestionTypeCountHook = () => {
    const { classId } = useParams();
    const [questionTypeCounts, setQuestionTypeCounts] = useState<any[]>();
    const { getQuestionTypeCountByTestroomId } = useGetQuestionTypeData();
    const getQuestionTypeCount = async () => {
        try {
            if (classId) {
                const testroomQuestionData = await getQuestionTypeCountByTestroomId({
                    classId: classId,
                });
                if (testroomQuestionData) {
                    let testrooms: Testroom[] = testroomQuestionData?.testrooms ?? []; // replace with the fetched data
                    let questionTypeCount: any[] = [];
                    testrooms.map((testroom) => {
                        const id = testroom.id;
                        let counts: { [key: string]: number } = {};
                        let hasQuestion: boolean = true;
                        testroom.lessonVersion.slides.map((slide) => {
                            const data: any = slide.data;
                            let obj: SlideComponent[] = JSON.parse(data);
                            if (obj) {
                                for (let element of obj) {
                                    // Get the gameType of the element
                                    if ('gameType' in element.content) {
                                        if (element.content.gameType) {
                                            let gameType = element.content.gameType;

                                            if (gameType in counts) {
                                                counts[gameType]++;
                                            } else {
                                                counts[gameType] = 1;
                                            }
                                        } else {
                                            hasQuestion = false;
                                        }
                                    }
                                }
                            }
                        });
                        if (hasQuestion) {
                            const testRoomCounterObj: any = {
                                testroomId: id,
                                ...counts,
                            };
                            questionTypeCount.push(testRoomCounterObj);
                        }
                    });
                    setQuestionTypeCounts(questionTypeCount);
                    return questionTypeCount;
                }
            }
        } catch (error: any) {
            console.log(error?.message);
        }
    };
    return { getQuestionTypeCount };
};
