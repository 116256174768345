import { gql, useLazyQuery } from '@apollo/client';
import { UserAuthContext } from 'contexts/UserAuthContext';
import { ERROR_TYPE, handleException, TTError } from 'models/ErrorHandling';
import { useCallback, useContext } from 'react';
import { ClassesQueryModel, ClassModel } from '../ResultPageModel';

export const GET_CLASSES = gql`
    query GetClasses($school: IDFilter) {
        classes(where: { AND: { school: { id: $school } } }, orderBy: [{ className: asc }]) {
            id
            name
            className
        }
    }
`;

export type ModelQueryType = {
    classes: ClassModel[];
};

type QueryVariableType = {
    school: { equals: string };
};

export const useClassesQuery = () => {
    const auth = useContext(UserAuthContext);

    const [getClassesQuery, { data, loading }] = useLazyQuery<ClassesQueryModel>(GET_CLASSES, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
    });
    const getClasses = useCallback(
        async (variables: QueryVariableType): Promise<ModelQueryType | null> => {
            try {
                const result = await getClassesQuery({
                    variables,
                    fetchPolicy: 'no-cache',
                    notifyOnNetworkStatusChange: true,
                });

                if (!result?.data) {
                    throw new TTError(ERROR_TYPE.NO_DATA);
                }

                return result?.data;
            } catch (err) {
                const errorMsg = handleException(err);
                if (errorMsg === ERROR_TYPE.KS_ACCESS_DENIED) {
                    auth.accessDenied(errorMsg);
                }
                throw new TTError(errorMsg);
            }
        },
        [getClassesQuery, auth],
    ); // Added dependencies for useCallback
    return { getClasses };
};
