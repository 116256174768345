import React, { useRef } from 'react';
import { Typography, TextField, Stack, Avatar, Grid } from '@mui/material';
import { GoogleSigninButton } from 'components/GoogleSigninButton';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ResultType } from '../../onBoarding/subPage/SignIn';
import { UserData } from 'contexts/UserAuthContext';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { PasswordChangeForm } from '../components/PasswordChangeForm';

interface UserInfoDisplayProps {
    userData: any; // Define a more specific type based on your user data structure
    resetEmailExists: () => void;
    t: (key: string) => string; // Translation function
    clientId: string;
    // formik: FormikProps<FormValues>;
    setNormalMessage: (message: string) => void;
    setErrorMessage: (message: string) => void;
    setUserData: (userData: UserData) => void;
    onSubmit: (values: any, createAnother: boolean, createAnotherCb: () => void) => void;
}

export const UserInfoDisplay: React.FC<UserInfoDisplayProps> = ({
    userData,
    resetEmailExists,
    t,
    clientId,
    // formik,
    setNormalMessage,
    setErrorMessage,
    setUserData,
    onSubmit,
}) => {
    const isEmailExists = true;
    const createAnother = useRef<boolean>(false);

    const handleSubmitGoogle = async (result: ResultType | undefined) => {
        // console.log('handleSubmitGoogle result: ', result);
        // setUserData(result.data.authenticateUserWithGoogle.item);

        if (result?.data?.authenticateWithGoogle?.__typename === 'AuthenticateWithGoogleFailure') {
            setTimeout(() => {
                setErrorMessage(result?.data?.authenticateWithGoogle?.message?.toString() || '');
            }, 10000);
        }

        // console.log('result: ', result);

        if (result) {
            setUserData({
                ...userData,
                ...result.data.authenticateWithGoogle.item,
            });
        }
    };
    let isUpdate: boolean = false;
    const validationSchema: any = Yup.object().shape({
        isUpdate: Yup.boolean(),
        email: Yup.string().email(t('invalid-email')).required(t('required')),
        password: isUpdate ? Yup.string().nullable().min(8, t('password-requirement')) : Yup.string().min(8, t('password-requirement')).required(t('required')),
        passwordConfirmation: isUpdate
            ? Yup.string()
                  .nullable()
                  .oneOf([Yup.ref('password'), null], t('password-match'))
                  .when('password', (password, schema) => {
                      return !password || (password && password.length <= 0) ? schema : schema.required(t('required'));
                  })
            : Yup.string()
                  .oneOf([Yup.ref('password'), null], t('password-match'))
                  .required(t('required')),
        class: Yup.string().required(t('required')),
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            currentPassword: '',
            password: '',
            passwordConfirmation: '',
        },
        validationSchema,
        onSubmit: async (values) => {
            onSubmit(values, createAnother.current, () => {
                formik.resetForm();
            });
        },
    });

    const validationSchemaPassword: any = Yup.object().shape({
        isUpdate: Yup.boolean(),
        currentPassword: Yup.string().min(8, t('password-requirement')).required(t('required')),
        password: isUpdate ? Yup.string().nullable().min(8, t('password-requirement')) : Yup.string().min(8, t('password-requirement')).required(t('required')),
        passwordConfirmation: isUpdate
            ? Yup.string()
                  .nullable()
                  .oneOf([Yup.ref('password'), null], t('password-match'))
                  .when('password', (password, schema) => {
                      return !password || (password && password.length <= 0) ? schema : schema.required(t('required'));
                  })
            : Yup.string()
                  .oneOf([Yup.ref('password'), null], t('password-match'))
                  .required(t('required')),
    });
    const formikPassword = useFormik({
        initialValues: {
            currentPassword: '',
            password: '',
            passwordConfirmation: '',
        },
        validationSchema: validationSchemaPassword,
        onSubmit: async (values) => {
            onSubmit(values, createAnother.current, () => {
                formik.resetForm();
            });
        },
    });

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <Stack spacing={2}>
                    <Typography variant='h5'>{t('profile.personalInfo')}</Typography>
                    <TextField
                        id='userName'
                        name='userName'
                        disabled={true}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        // value={formik.values.name}
                        value={userData?.name}
                        label={t('profile.userName')}
                        fullWidth
                        variant='outlined'
                        error={formik.touched.name && !!formik.errors.name}
                        helperText={formik.touched.name && formik.errors.name}
                    />

                    <TextField
                        id='email'
                        name='email'
                        disabled={true}
                        autoComplete='email'
                        onChange={(e) => {
                            resetEmailExists();
                            formik.handleChange(e);
                        }}
                        onBlur={formik.handleBlur}
                        // value={formik.values.email}
                        value={userData?.email}
                        label={t('profile.email')}
                        fullWidth
                        variant='outlined'
                        error={formik.touched.email && (!!formik.errors.email || isEmailExists)}
                        helperText={formik.touched.email && (formik.errors.email ? formik.errors.email : isEmailExists ? t('email-exist') : '')}
                    />
                    <TextField
                        id='role'
                        name='role'
                        disabled={true}
                        autoComplete='new-password'
                        onChange={(e) => {
                            resetEmailExists();
                            formik.handleChange(e);
                        }}
                        onBlur={formik.handleBlur}
                        // value={formik.values.email}
                        value={userData?.role == 'teacher' ? t('profile.' + userData.role) : userData!.role.charAt(0).toUpperCase() + userData!.role.slice(1)}
                        label={t('profile.role')}
                        fullWidth
                        variant='outlined'
                        error={formik.touched.email && (!!formik.errors.email || isEmailExists)}
                        helperText={formik.touched.email && (formik.errors.email ? formik.errors.email : isEmailExists ? t('email-exist') : '')}
                    />
                    <TextField
                        id='googleEmail'
                        name='googleEmail'
                        disabled={true}
                        autoComplete='email'
                        onChange={(e) => {
                            resetEmailExists();
                            formik.handleChange(e);
                        }}
                        onBlur={formik.handleBlur}
                        // value={formik.values.email}
                        value={userData?.googleEmail == undefined || userData?.googleEmail.length == 0 ? t('profile.notLinkedWithGoogle') : userData?.googleEmail}
                        label={t('profile.googleEmail')}
                        fullWidth
                        variant='outlined'
                        error={formik.touched.email && (!!formik.errors.email || isEmailExists)}
                        helperText={formik.touched.email && (formik.errors.email ? formik.errors.email : isEmailExists ? t('email-exist') : '')}
                    />
                </Stack>
            </Grid>
            <Grid item xs={12} md={6} container direction='column' alignItems='center' spacing={2}>
                <Avatar src={userData?.googleProfilePicture} sx={{ width: 100, height: 100, marginBottom: '30px' }} />
                <PasswordChangeForm t={t} formikPassword={formikPassword} setNormalMessage={setNormalMessage} setErrorMessage={setErrorMessage} userData={userData}></PasswordChangeForm>
                {userData?.googleEmail == undefined || userData?.googleEmail == null || userData?.googleEmail.length == 0 ? (
                    <GoogleOAuthProvider clientId={clientId}>
                        <GoogleSigninButton buttonTitle='Link with Google' onSuccess={handleSubmitGoogle} />
                    </GoogleOAuthProvider>
                ) : null}
            </Grid>
        </Grid>
    );
};
