import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useEffect, useState } from 'react';
export type QuestionOverallTabType = {
    data: any;
};

function secondsToTime(seconds: number) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.round(seconds % 60);
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
}
function timeToSeconds(time: string) {
    if (!/^([01]\d|2[0-3]):([0-5]\d)$/.test(time)) {
        return 0;
    }
    const [minutes, seconds] = time.split(':').map(Number);
    return minutes * 60 + seconds;
}
const OverallQuestionResultDetailTab = (props: QuestionOverallTabType) => {
    const [overallStat, setOverallStat] = useState<any>({
        averageScore: 0,
        averageAttempt: 0,
        averageTime: '00:00',
        notAttempted: 0,
    });
    const { t } = useTranslation();

    useEffect(() => {
        if (props?.data?.data) {
            if (Array.isArray(props?.data?.data)) {
                const studentSpecificQuestionResults = props?.data?.data;
                let questionStat = {
                    averageScore: 0,
                    averageAttempt: 0,
                    averageTime: '00:00',
                    notAttempted: 0,
                };
                let avgTime: number = 0;
                studentSpecificQuestionResults.forEach((element: any) => {
                    if (element?.score) {
                        questionStat.averageScore = element?.score === 1 ? questionStat.averageScore + 1 : questionStat.averageScore;
                    }
                    if (element?.attempt === 0) {
                        questionStat.notAttempted += 1;
                    } else {
                        questionStat.averageAttempt += element?.attempt;
                    }

                    if (element?.answerTime) {
                        avgTime += timeToSeconds(element?.answerTime.toString()) * element?.attempt;
                    }
                });
                questionStat.averageScore = Math.round(questionStat.averageScore / (props?.data?.data.length - questionStat.notAttempted));
                avgTime = avgTime / (props?.data?.data.length - questionStat.notAttempted);
                questionStat.averageAttempt = Math.round(questionStat.averageAttempt / (props?.data?.data.length - questionStat.notAttempted));
                questionStat.averageTime = secondsToTime(avgTime);
                console.log(questionStat);
                setOverallStat(questionStat);
            }
        }
    }, [props]);
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 8,
                alignSelf: 'stretch',
                justifyContent: 'space-evenly',
                backgroundColor: 'white',
            }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '8px 16px',
                    alignSelf: 'stretch',
                }}>
                <Box minWidth='56px'>
                    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                        <path
                            d='M22.909 11.9999C22.909 18.0248 18.0248 22.909 11.9999 22.909C5.97499 22.909 1.09082 18.0248 1.09082 11.9999C1.09082 5.97499 5.97499 1.09082 11.9999 1.09082C18.0248 1.09082 22.909 5.97499 22.909 11.9999Z'
                            fill='#EF5350'
                        />
                        <circle cx='11.9999' cy='11.9999' r='10.9091' fill='#4CAF50' />
                    </svg>
                </Box>
                <Typography fontSize={16}>{t('resultPage.average-score') + '：' + overallStat.averageScore}</Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '8px 16px',
                    alignSelf: 'stretch',
                }}>
                <Box minWidth='56px'>
                    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                        <path
                            d='M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z'
                            fill='#AA00FF'
                        />
                        <path d='M12.5 7H11V13L16.25 16.15L17 14.92L12.5 12.25V7Z' fill='#AA00FF' />
                    </svg>
                </Box>
                <Typography fontSize={16}>{t('resultPage.average-answer-time') + '：' + overallStat.averageTime}</Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '8px 16px',
                    alignSelf: 'stretch',
                }}>
                <Box minWidth='56px'>
                    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                        <path
                            d='M18.41 5.79988L17.2 4.58988C16.42 3.80988 15.15 3.80988 14.37 4.58988L3 15.9599V19.9999H7.04L18.41 8.62988C19.2 7.84988 19.2 6.57988 18.41 5.79988ZM6.21 17.9999H5V16.7899L13.66 8.12988L14.87 9.33988L6.21 17.9999ZM11 19.9999L15 15.9999H21V19.9999H11Z'
                            fill='#533D18'
                            fill-opacity='0.56'
                        />
                    </svg>
                </Box>
                <Typography fontSize={16}>{t('resultPage.average-answer-attempt') + '：' + overallStat.averageAttempt}</Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '8px 16px',
                    alignSelf: 'stretch',
                }}>
                <Box minWidth='56px'>
                    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                        <path d='M1 21H23L12 2L1 21ZM13 18H11V16H13V18ZM13 14H11V10H13V14Z' fill='#FFCA28' />
                    </svg>
                </Box>
                <Typography fontSize={16}>{t('resultPage.not-attempted') + '：' + overallStat.notAttempted}</Typography>
            </Box>
        </Box>
    );
};

export default OverallQuestionResultDetailTab;
