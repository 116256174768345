import { gql, useMutation } from '@apollo/client';
import { UserAuthContext } from 'contexts/UserAuthContext';
import { ERROR_TYPE, TTError, handleException } from 'models/ErrorHandling';
import { useCallback, useContext } from 'react';

const SAVE_GQL = gql`
    mutation UpdateHomeworks($data: [HomeworkUpdateArgs!]!) {
        updateHomeworks(data: $data) {
            id
        }
    }
`;

export type MutationVariableType = {
    data: {
        data: {
            isArchived?: boolean;
        };
        where: {
            id: string;
        };
    }[];
};

type MutationType = {
    updateHomeworks: { id: string }[];
};

export const useUpdateHomeworksMutation = () => {
    const { accessDenied } = useContext(UserAuthContext);
    const [fetchMutation] = useMutation<MutationType>(SAVE_GQL);

    const runMutation = useCallback(
        async (variables: MutationVariableType) => {
            try {
                const result = await fetchMutation({
                    variables,
                });

                if (!result || !result.data?.updateHomeworks) {
                    throw new TTError(ERROR_TYPE.UPDATE_FAILED);
                }

                return result.data.updateHomeworks;
            } catch (err) {
                console.log(err);

                const errMessage = handleException(err);
                if (errMessage === ERROR_TYPE.KS_ACCESS_DENIED) {
                    accessDenied(errMessage);
                }

                throw new TTError(errMessage);
            }
        },
        [fetchMutation, accessDenied],
    );

    return { runMutation };
};
