import { Stack } from '@mui/material';
import { ResultTable } from 'pages/results/component/ResultTable/ResultTable';
import { useTestroomResult } from 'pages/results/hook/useTestroomResult';

export const SelectStudentPage = () => {
    const { studentTableData } = useTestroomResult();

    return (
        <Stack direction='column' spacing={6} className='pageCustom'>
            {/* <ScoreCard
        score={
          studentData?.studentProfiles
            ? '' +
              (
                allStudentsAverageScore / studentData?.studentProfiles?.length
              )?.toFixed(2)
            : ''
        }
        time={
          studentData?.studentProfiles
            ? moment
                .utc(
                  (allStudentsAnswerTime /
                    studentData?.studentProfiles?.length) *
                    1000,
                )
                .format('mm:ss')
            : ''
        }
      /> */}
            <ResultTable columns={studentTableData?.columns} data={studentTableData?.data} isLoading={!studentTableData} showSearchTab={true} />
        </Stack>
    );
};
