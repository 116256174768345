import { createContext, useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import {
    RECEIVE_BROADCAST_ASSIGN_END,
    RECEIVE_BROADCAST_ASSIGN_START,
    RECEIVE_BROADCAST_LABEL,
    RECEIVE_BROADCAST_RECEIVE_ROOM_SETTINGS,
    RECEIVE_BROADCAST_STUDENT_CLIENT_IDX,
    RECEIVE_BROADCAST_USER_DISCONNECTED,
} from '../constants/commands';
import { requestClientList } from '../hooks/classroomHooks/requestClientList';
import { requestJoinRoom } from '../hooks/classroomHooks/requestJoinRoom';
import { requestRoomState } from '../hooks/classroomHooks/requestRoomState';
import { requestUpdateRoomSettings, RoomSettings } from '../hooks/classroomHooks/requestUpdateRoomSettings';
import { TT_EVENT_ASSIGN, TT_EVENT_ASSIGN_END, TT_EVENT_ASSIGN_START, TT_EVENT_RECEIVE_CLIENT_LIST } from '../hooks/customEvent';
import { TestroomModel } from '../models/TestroomModel';
import { useEndLessonMutation } from 'models/lesson/useEndLessonMutation';

// new classroom flow start
export type TurtleTeachRole = 'teacher' | 'student' | null;

export type ClientLabel = {
    isGuest: boolean;
    studentProfileId: string;
    studentName: string;
    studentNumber: string;
    profilePicURL: string;
    clientNumber: number;
    role: TurtleTeachRole;
    socketId: string;
    deviceLabel: string;
};

export type AssigningDevices = {
    [socketId: string]: boolean;
};

export const ClassroomContext = createContext<ClassroomContextType>({} as ClassroomContextType);

type ClassroomContextType = {
    socket: Socket | null;
    testroomData: TestroomModel | undefined;
    studentClientList: ClientLabel[];
    page: number;
    screenSharing: boolean;
    writingHints: boolean;
    freeStyleMode: boolean;
    screenSync: boolean;
    handleEndLesson: (testroomId: string) => void;
};

type Props = {
    testroomData: TestroomModel | undefined;
    children: JSX.Element | JSX.Element[];
};

export const ClassroomContextProvider = (props: Props) => {
    const [socket, setSocket] = useState<Socket | null>(null);
    const [studentClientList, setStudentClientList] = useState<ClientLabel[]>([]);
    const { endLesson } = useEndLessonMutation();

    const [page, setPage] = useState(0);
    const [screenSharing, setScreenSharing] = useState(false);
    const [writingHints, setWritingHints] = useState(false);
    const [freeStyleMode, setFreeStyleMode] = useState(false);
    const [screenSync, setScreenSync] = useState(false);

    useEffect(() => {
        const handleUpdateClientListEvent = (e: any) => {
            if (!e || !e.detail) {
                return;
            }

            setStudentClientList(e.detail);
        };

        window.addEventListener(TT_EVENT_RECEIVE_CLIENT_LIST, handleUpdateClientListEvent);
        return () => {
            window.removeEventListener(TT_EVENT_RECEIVE_CLIENT_LIST, handleUpdateClientListEvent);
        };
    }, []);

    // new classroom flow start
    useEffect(() => {
        if (!props.testroomData?.id || props.testroomData?.id === '') {
            return;
        }

        if (props.testroomData?.id && props.testroomData?.id !== '') {
            if (process.env.REACT_APP_CLASSROOM_FLOW_ENABLED !== 'true') {
                return;
            }

            try {
                const socket = io(process.env.REACT_APP_CLASSROOM_SERVICE_URL ?? '', {
                    path: process.env.REACT_APP_CLASSROOM_SERVICE_PATH,
                    transports: ['websocket'],
                    reconnection: true,
                    reconnectionAttempts: Infinity,
                    reconnectionDelay: 500,
                });

                setSocket(socket);

                socket.on('connect', () => {
                    console.log('classroom: connected');
                    requestJoinRoom(socket, props.testroomData?.id ?? '');
                });

                socket.on(RECEIVE_BROADCAST_STUDENT_CLIENT_IDX, (data: ClientLabel) => {
                    // setSocketIds((prevIds) => [...prevIds, data]);
                    if (data.socketId === socket.id) {
                        requestRoomState(socket);

                        // update room setting for the first time, if not updated
                        requestUpdateRoomSettings(
                            socket,
                            props.testroomData?.id ?? '',
                            {
                                page: 0,
                                screenSharing: props.testroomData?.screenSharing ?? false,
                                writingHints: props.testroomData?.writingHints ?? true,
                                freeStyleMode: props.testroomData?.freeStyleMode ?? false,
                                screenSync: true, //default tru
                            },
                            false,
                            (response: RoomSettings) => {
                                setPage(response.page);
                                setScreenSharing(response.screenSharing);
                                setWritingHints(response.writingHints);
                                setFreeStyleMode(response.freeStyleMode);
                                setScreenSync(response.screenSync);
                            },
                        );
                    }
                    console.log('classroom:', RECEIVE_BROADCAST_STUDENT_CLIENT_IDX, data);
                    requestClientList(socket);
                });

                socket.on(RECEIVE_BROADCAST_RECEIVE_ROOM_SETTINGS, (data: RoomSettings) => {
                    console.log('classroom:', RECEIVE_BROADCAST_RECEIVE_ROOM_SETTINGS, data);

                    if (data) {
                        setPage(data.page);
                        setScreenSharing(data.screenSharing);
                        setWritingHints(data.writingHints);
                        setFreeStyleMode(data.freeStyleMode);
                        setScreenSync(data.screenSync);
                    }
                });

                socket.on(RECEIVE_BROADCAST_ASSIGN_START, (data) => {
                    console.log('classroom:', RECEIVE_BROADCAST_ASSIGN_START, data);

                    const eventMessage = new CustomEvent(TT_EVENT_ASSIGN_START, {
                        detail: data,
                    });
                    window.dispatchEvent(eventMessage);
                });

                socket.on(RECEIVE_BROADCAST_LABEL, (data) => {
                    console.log('classroom:', RECEIVE_BROADCAST_LABEL, data);

                    setStudentClientList((prev) => {
                        return prev.map((client) => {
                            if (client.socketId === data.socketId) {
                                return {
                                    ...client,
                                    ...data,
                                };
                            }
                            return client;
                        });
                    });

                    const eventMessage = new CustomEvent(TT_EVENT_ASSIGN, {
                        detail: data,
                    });
                    window.dispatchEvent(eventMessage);
                });

                socket.on(RECEIVE_BROADCAST_ASSIGN_END, (data) => {
                    console.log('classroom:', RECEIVE_BROADCAST_ASSIGN_END, data);

                    const eventMessage = new CustomEvent(TT_EVENT_ASSIGN_END, {
                        detail: data,
                    });
                    window.dispatchEvent(eventMessage);
                });

                socket.on(RECEIVE_BROADCAST_USER_DISCONNECTED, (data) => {
                    console.log('classroom:', RECEIVE_BROADCAST_USER_DISCONNECTED, data);
                    requestClientList(socket);
                });

                socket.on('disconnect', (reason) => {
                    console.log('classroom: disconnected', reason);
                    if (reason === 'io server disconnect') {
                        // the disconnection was initiated by the server, you need to reconnect manually
                        socket.connect();
                    }
                });
            } catch (err) {
                console.log('error', err);
            }
        }
    }, [props.testroomData?.id]);
    // new classroom flow end

    const handleEndLesson = async (testroomId: string) => {
        try {
            await endLesson({ where: { id: testroomId } });
        } catch (err) {
            // todo: handle error
        }
    };

    return (
        <ClassroomContext.Provider value={{ socket, testroomData: props.testroomData, studentClientList, page, screenSharing, writingHints, freeStyleMode, screenSync, handleEndLesson }}>
            {props.children}
        </ClassroomContext.Provider>
    );
};
