import { OverallResultByQuestion } from 'models/resultPage/ResultPageModel';
import { useCallback, useState } from 'react';

// export type StudentProfileWithResults = StudentProfile & TestroomResults;

export const useHardQuestionHook = () => {
    const [hardQuestions, setHardQuestions] = useState<OverallResultByQuestion[]>([]);
    const getHardQuestions = useCallback((numberOfQuestion: number, questionResultPerStudents: OverallResultByQuestion[], absentStudents: number) => {
        if (numberOfQuestion > 0 && questionResultPerStudents) {
            setHardQuestions(questionResultPerStudents.filter((question) => question.correct / (question.studentPerformances.length - absentStudents) < 0.3));
        }
    }, []);

    return {
        getHardQuestions,
        hardQuestions,
    };
};
