import { Circle } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { ResultTable, ResultTableProps } from 'pages/results/component/ResultTable/ResultTable';
import { StudentOverallResult, StudentProfileWithResults } from 'models/resultPage/ResultPageModel';
import { AvgScorePerStudent } from 'pages/results/hook/useOverallTestroomResult';
import { DotContainer } from 'pages/results/subPage/QuestionResultPage/QuestionResultPage';
import { GameType, gameTypeStr, getStringDotSplitSortDirection, getStringSortDirection, grade, gradeVariants, gradingTypeStr } from 'pages/results/utils';
import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
type StudentDataType = {
    studentData: AvgScorePerStudent[];
};
const StudentResultTable = (props: StudentDataType) => {
    const { t } = useTranslation();
    useEffect(() => {
        if (props) {
            prepareQuestionTable(props.studentData);
        }
    }, [props]);
    const [studentOverallResultTable, setQuestionTableData] = useState<ResultTableProps>();
    const prepareQuestionTable = (studentResult: AvgScorePerStudent[]) => {
        setIsLoading(false);
        setQuestionTableData({
            data: studentResult?.map((studentResult: AvgScorePerStudent, index) => {
                if (studentResult) {
                    return {
                        student: studentResult.studentName,
                        correctPercentage: Math.round((studentResult.totalCorrect / studentResult.totalQuestion) * 100).toString() + '%',
                        averageScore: Math.round(studentResult.averageCorrect ?? 0),
                    };
                } else {
                    return {
                        student: '',
                        correctPercentage: '0%',
                        averageScore: '0',
                    };
                }
            }),
            columns: [
                {
                    name: 'student',
                    nameStr: t('menu-students'),
                    sortCallback: () => {
                        setQuestionTableData((prevState) => {
                            if (prevState?.data) {
                                const cloneState = [...prevState?.data];
                                const sortDirection = getStringDotSplitSortDirection(cloneState, 'student');
                                if (sortDirection === 'unsorted') {
                                    console.error('Error: Invalid question format');
                                    return prevState;
                                }
                                const sortFunction = (a: any, b: any) => {
                                    const regex = /^(\d+)\./;
                                    const matchA = a.question.match(regex);
                                    const matchB = b.question.match(regex);
                                    if (!matchA || !matchB) {
                                        console.error('Error: Invalid question format');
                                        return 0;
                                    }
                                    const A = Number(matchA[1]);
                                    const B = Number(matchB[1]);
                                    if (isNaN(A) || isNaN(B)) {
                                        console.error('Error: Invalid number format');
                                        return 0;
                                    }
                                    return sortDirection === 'ascending' ? B - A : A - B;
                                };
                                cloneState.sort(sortFunction);
                                return {
                                    ...prevState,
                                    data: cloneState,
                                };
                            } else {
                                return prevState;
                            }
                        });
                    },
                },
                {
                    name: 'correctPercentage',
                    nameStr: '答題正確率',
                    showPercentage: true,
                    sortCallback: () => {
                        setQuestionTableData((prevState) => {
                            if (prevState?.data) {
                                const cloneState = [...prevState?.data];
                                const sortDirection = getStringSortDirection(cloneState, 'correctPercentage');
                                if (sortDirection === 'ascending') {
                                    cloneState.sort((a, b) => b?.questionType?.localeCompare(a?.questionType));
                                } else {
                                    cloneState.sort((a, b) => a?.questionType?.localeCompare(b?.questionType));
                                }
                                return {
                                    ...prevState,
                                    data: cloneState,
                                };
                            } else {
                                return prevState;
                            }
                        });
                    },
                },
                {
                    name: 'averageScore',
                    nameStr: '平均分',
                    sortCallback: () => {
                        setQuestionTableData((prevState) => {
                            if (prevState?.data) {
                                const cloneState = [...prevState?.data];
                                const sortDirection = getStringSortDirection(cloneState, 'averageScore');
                                console.log(cloneState);

                                if (sortDirection === 'ascending') {
                                    cloneState.sort((a, b) => b?.questionType?.localeCompare(a?.questionType));
                                } else {
                                    cloneState.sort((a, b) => a?.questionType?.localeCompare(b?.questionType));
                                }
                                return {
                                    ...prevState,
                                    data: cloneState,
                                };
                            } else {
                                return prevState;
                            }
                        });
                    },
                },
            ],
        });
    };
    const [isLoading, setIsLoading] = useState(true);
    return (
        <Box sx={{ width: '100%' }}>
            <ResultTable columns={studentOverallResultTable?.columns} data={studentOverallResultTable?.data} isLoading={isLoading} />
        </Box>
    );
};

export default StudentResultTable;
