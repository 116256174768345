import { UserData } from 'contexts/UserAuthContext';
import { SchoolModel } from 'models/school/SchoolModel';
import { StudentProfileModel } from 'models/student/StudentModel';
import { type } from 'os';
import { GameType } from 'pages/results/utils';
import { TestroomWithQuestions } from 'pages/testroom/models/TestroomModel';
export type Grading = 'ungraded' | 'correct' | 'incorrect' | 'notGiven';
export enum ResultIndicatorEnum {
    correct,
    wrong,
    notGiven,
    pending,
    notAtttempted,
}

export type Results = {
    id: string;
    message: string;
    data: string;
    student: {
        id: string;
        givenName: string;
        familyName: string;
    };
    files: AudioFile[];
    startTime: string;
    endTime: string;
    componentId: string;
    gameType: string;
    correct: boolean | null;
    grading: Grading;
};

export type AudioFile = {
    file: {
        url: string;
    };
};

export type TestroomResults = {
    results?: Results[];
};

export type StudentProfile = Pick<StudentProfileModel, 'id' | 'studentNumber' | 'familyName' | 'givenName'>;

export type StudentOverallResult = {
    student: string;
    correctPercentage: string;
    averageScore: string;
};
export type StudentProfileWithResults = StudentProfile & TestroomResults;
export type OverallResultByQuestion = {
    index: number;
    question: string;
    questionID: string;
    questionType: GameType;
    correct: number;
    studentPerformances: StudentPerformance[];
    averageTime: number;
};
export type StudentPerformance = {
    grading: boolean;
    questionAttempt: number;
    studentName: string;
    studentScore: number;
    averageTime: number;
};
export type StudentTableData = {
    student: string;
    correctCount: string;
    totalTestDuration: string;
    totalScore: string | number;
    id: string;
};
export type QuestionTableProp = {
    questionName: string;
    questionType: GameType;
    studentAverageCorrectPercentage: number;
};
export type StudentQueryModel = {
    studentProfile: StudentProfile;
};

export type ClassStudentQueryModel = {
    studentProfiles: StudentProfile[];
};

export type ClassQueryModel = {
    class: ClassModel;
};

export type ClassesQueryModel = {
    classes: ClassModel[];
};

export type ClassModel = {
    id: string;
    name: string;
    className: string;
    students: StudentProfile[];
};
export type SchoolNameModel = {
    id: string;
    name: string;
};

export type TestroomQueryModel = {
    testroom: TestroomWithQuestions;
};
