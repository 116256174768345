import { gql, useMutation } from '@apollo/client';
import { ERROR_TYPE, TTError } from 'models/ErrorHandling';

const SAVE_GQL = gql`
    mutation save($name: String!, $email: String!, $password: String!, $studentNumber: String!, $familyName: String!, $givenName: String!, $dob: DateTime!, $classId: ID!) {
        createUser(
            data: {
                name: $name
                role: "student"
                email: $email
                password: $password
                studentProfile: { create: { studentNumber: $studentNumber, familyName: $familyName, givenName: $givenName, dob: $dob, class: { connect: { id: $classId } } } }
            }
        ) {
            id
        }
    }
`;

type StudentModelMutationVariableType = {
    name: string;
    email: string;
    password: string;
    studentNumber: string;
    familyName: string;
    givenName: string;
    dob: string;
    classId: string;
};

type StudentModelMutationType = {
    createUser: StudentModelMutationVariableType;
};

export const useStudentModelCreateMutation = () => {
    const [saveForm] = useMutation<StudentModelMutationType>(SAVE_GQL);

    const create = async (variables: StudentModelMutationVariableType) => {
        const result = await saveForm({
            variables,
        });

        if (!result || !result.data?.createUser) {
            throw new TTError(ERROR_TYPE.UPDATE_FAILED);
        }
    };

    return { create };
};
