import { useMutation } from '@apollo/client';
import { Dialog, DialogContent, CircularProgress, Alert } from '@mui/material';
import { CREATE_SESSION, CreateSessionData, CreateSessionVariables } from 'client/gql';
import { Constants } from 'Constants';
import { useEffect } from 'react';
import { QRCode } from 'react-qrcode-logo';

const CODE_SIZE = 400;
const CODE_QUITE_ZONE = 10;

const ErrorAlert = (props: { message: string }) => {
    return <Alert severity='error'>{props.message}</Alert>;
};

export const BadgeModal = (props: { open: boolean; onClose: () => void; profileId: string; caption?: string }) => {
    const [createSession, { data, loading, error }] = useMutation<CreateSessionData, CreateSessionVariables>(CREATE_SESSION);

    useEffect(() => {
        if (props.profileId) {
            createSession({
                variables: {
                    studentProfileId: props.profileId,
                    ttl: Constants.User.StudentSessionTTL,
                },
            });
        }
    }, [createSession, props.profileId]);

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogContent>
                <div style={{ height: CODE_SIZE + CODE_QUITE_ZONE * 2, width: CODE_SIZE + CODE_QUITE_ZONE * 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {loading ? (
                        <CircularProgress />
                    ) : error ? (
                        <ErrorAlert message={error.message} />
                    ) : data?.authenticateWithClassroom.__typename === 'AuthenticateWithClassroomFailure' ? (
                        <ErrorAlert message={data.authenticateWithClassroom.message} />
                    ) : (
                        <QRCode ecLevel='M' size={CODE_SIZE} quietZone={CODE_QUITE_ZONE} value={data?.authenticateWithClassroom.sessionToken} />
                    )}
                </div>
                {props.caption && <div style={{ marginTop: 8, textAlign: 'center', fontWeight: 'bold', fontSize: '24px' }}>{props.caption}</div>}
            </DialogContent>
        </Dialog>
    );
};

export default BadgeModal;
