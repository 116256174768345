import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { SasFillInTheBlanksResult } from 'pages/results/models';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from 'styles/Colors';
import FeedbackIcon from '../CorrectTopRightIcon/FeedbackIcon';

type FillInTheBlankAnswerProp = {
    question: string;
    data: string;
    startTime: any;
    endTime: any;
};

type FillInTheBlankAnswerComponentProp = {
    text: string;
    isCorrect: boolean;
};
const HkSasFillInTheBlankAnswer = (prop: FillInTheBlankAnswerProp) => {
    const { t } = useTranslation();

    const [resultArr, setResultArr] = useState<FillInTheBlankAnswerComponentProp[]>([]);
    useEffect(() => {
        if (prop) {
            const sasFillInTheBlanksResult: SasFillInTheBlanksResult = JSON.parse(prop.data);
            const answers = sasFillInTheBlanksResult.sasFillInTheBlanks.answers;
            setResultArr(answers);
        }
    }, [prop]);
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '32px',
            }}>
            <Typography
                sx={{
                    fontWeight: 400,
                    fontSize: '16px',
                    color: colors.yellow900,
                    lineHeight: '150%',
                }}>
                {prop.question}
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '16px',
                    padding: '24px 32px 24px 32px',
                }}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        gap: '8px',
                        position: 'relative', // Add this

                        padding: '0px',
                    }}>
                    <FeedbackIcon isCorrect={resultArr.filter((answer) => answer.isCorrect === false).length === 0} top={-15} right={-45} />

                    {resultArr.length > 0 &&
                        resultArr.map((item: FillInTheBlankAnswerComponentProp, index: number) => {
                            return (
                                <Box
                                    sx={{
                                        paddingTop: '8px',
                                        paddingBottom: '8px',
                                        paddingLeft: '22px',
                                        paddingRight: '22px',
                                        border: 1,
                                        borderRadius: '4px',
                                        backgroundColor: item.isCorrect ? '#4CAF50' : '#EF5350',
                                    }}>
                                    <Typography
                                        align='center'
                                        sx={{
                                            fontWeight: 400,
                                            fontSize: '16px',
                                            color: '#FFFFFF',
                                            lineHeight: '150%',
                                        }}>
                                        {item.text}
                                    </Typography>
                                </Box>
                            );
                        })}
                </Box>
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: '16px',
                        color: colors.yellow900,
                    }}>
                    {t('answerTime')}: {moment.utc(moment(prop?.endTime).diff(moment(prop?.startTime))).format('mm:ss')}
                </Typography>
            </Box>
        </Box>
    );
};

export default HkSasFillInTheBlankAnswer;
