import { useMemo } from 'react';

export const useAnswerGroups = (results: any[]) => {
    return useMemo(() => {
        let groups: any[] = [];

        // TTEACH-150
        // sorted by endTime
        const sortedCardData = results.sort((a: any, b: any) => {
            return new Date(a.endTime).getTime() - new Date(b.endTime).getTime();
        });

        for (const card of sortedCardData) {
            if (groups.length > 0) {
                let hasCreatedGroup = false;
                for (const answer of groups) {
                    if (answer.some((item: any) => item.startTime === card.startTime)) {
                        answer.push(card);
                        hasCreatedGroup = true;
                        break;
                    }
                }
                if (!hasCreatedGroup) {
                    groups.push([card]);
                }
            } else {
                groups.push([card]);
            }
        }

        return groups;
    }, [results]);
};
