import { ResultTableProps } from 'pages/results/component/ResultTable/ResultTable';
import { StudentProfileWithResults, StudentTableData } from 'models/resultPage/ResultPageModel';
import { useStudentInfoQuery } from 'models/resultPage/useStudentResult';
import moment from 'moment';
import { TestroomWithQuestions } from 'pages/testroom/models/TestroomModel';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { SelectClassPath, SelectQuestionPath, SelectStudentPath, SelectTestroomPath } from 'routes/utils';
import { ResultsPageContext } from '../models';
import { getStringSortDirection } from '../utils';
import { useTestroomController } from './controller/useTestroomController';
import { enqueueSnackbar } from 'notistack';

// export type StudentProfileWithResults = StudentProfile & TestroomResults;

export const useStudentTableHook = (selectedTestRoom: TestroomWithQuestions) => {
    const navigator = useNavigate();
    const { setSelectedStudent, setStudentResults, setStudentGrading } = useOutletContext<ResultsPageContext>();
    // const { schoolId, classId, testroomId } = useParams();
    const { getStudentData } = useStudentInfoQuery();
    const [studentTableData, setStudentTableData] = useState<ResultTableProps>();
    const [studentDataWithResult, setStudentDataWithResult] = useState<StudentProfileWithResults[]>([]);
    const [numberOfQuestion, setNumberOfQuestion] = useState(0);
    const [numberOfAttempt, setNumberOfAttempt] = useState(0);
    const [failedCompeteStudents, setFailedCompeteStudents] = useState<string[]>([]);
    const [absentStudents, setAbsentStudent] = useState<string[]>([]);
    const [allStudentsAverageScore, setAllStudentsScore] = useState(0);
    const [allStudentsAnswerTime, setAllStudentsAnswerTime] = useState(0);
    const [badPerformanceStudents, setBadPerformanceStudent] = useState<string[]>([]);
    const [sortedStudentData, setSortedStudentData] = useState<StudentTableData[]>([]);
    const { getTestroomResult } = useTestroomController();

    const { t } = useTranslation();
    useEffect(() => {
        const SetInfoResultData = () => {
            if (studentTableData?.data && numberOfQuestion > 0) {
                const absentStudent = studentTableData?.data.filter((s) => s?.correctCount === 'NA').map((obj) => obj.student);
                const totalCorrectNumber = studentTableData?.data.reduce((totalCorrect, student) => totalCorrect + (student?.correctCount ?? 0), 0);
                const totalTestDuration = studentTableData?.data.reduce((totalTestDuration, student) => totalTestDuration + (student?.finishTestTime ?? 0), 0);
                const failedCompeteStudent = studentTableData?.data.filter((s) => s?.correctCount !== 'NA' && !s.compete).map((obj) => obj.student);
                const badPerformanceStudent = studentTableData?.data.filter((s) => s?.correctCount !== 'NA' && s?.correctCount / numberOfQuestion < 0.3).map((obj) => obj.student);
                setFailedCompeteStudents(failedCompeteStudent);
                setAllStudentsScore(totalCorrectNumber);
                setAllStudentsAnswerTime(totalTestDuration);
                setAbsentStudent(absentStudent);
                setBadPerformanceStudent(badPerformanceStudent);
            }
        };
        if (studentTableData?.data) {
            SetInfoResultData();
            let sortedPerformance: StudentTableData[] = studentTableData?.data.slice();

            sortedPerformance.sort((a, b) => {
                const aScore = typeof a.totalScore !== 'number' ? -Infinity : a.totalScore;
                const bScore = typeof b.totalScore !== 'number' ? -Infinity : b.totalScore;

                // If scores are equal, sort by totalDuration
                if (aScore === bScore) {
                    const aDuration = typeof a.totalTestDuration === 'string' ? a.totalTestDuration.split(':') : [Infinity];
                    const bDuration = typeof b.totalTestDuration === 'string' ? b.totalTestDuration.split(':') : [Infinity];

                    // Check if totalDuration can be split by ":"
                    if (aDuration.length !== 2 || bDuration.length !== 2) {
                        return aDuration.length === 2 ? -1 : 1;
                    }

                    // Convert durations to seconds for comparison
                    const aSeconds = Number(aDuration[0]) * 60 + Number(aDuration[1]);
                    const bSeconds = Number(bDuration[0]) * 60 + Number(bDuration[1]);

                    return aSeconds - bSeconds;
                }

                // Sort by totalScore
                return bScore - aScore;
            });

            // Step 2: Sort subarrays by totalDuration where totalScore is the same
            setSortedStudentData(sortedPerformance);
        }
    }, [studentTableData, numberOfQuestion]);
    const studentResultsTableRow = useCallback(
        (student: StudentProfileWithResults, index: number, questionCount: number, classId: string, schoolId: string, testroomId: string, mode: string) => {
            if (student?.results && student?.results?.length > 0) {
                let correctCount = 0;
                let checkedResults: string[] = [];
                const answersTimeSec = student?.results.reduce((prevValue, answer) => prevValue + moment(answer?.endTime).diff(moment(answer?.startTime)), 0);
                const componentIdArray: string[] = [];
                let questionAttempt = 0;

                for (const result of student?.results) {
                    if (
                        !checkedResults.includes(result?.componentId) &&
                        (result?.grading === 'correct' || (result?.correct && result?.grading !== 'incorrect')) &&
                        (result.gameType === 'TwoCards' ||
                            result.gameType === 'OneCard' ||
                            result.gameType === 'Writing' ||
                            result.gameType === 'Audio' ||
                            result.gameType === 'MultipleChoice' ||
                            result.gameType === 'HkcsEnQ1' ||
                            result.gameType === 'HkcsEnQ2' ||
                            result.gameType === 'HkcsQ3' ||
                            result.gameType === 'HkcsQ2' ||
                            result.gameType === 'HkcsQ1' ||
                            result.gameType === 'SasAudio' ||
                            result.gameType === 'SasFillInTheBlanks' ||
                            result.gameType === 'SasRadicalMatching' ||
                            result.gameType === 'SasFillInTheBlanksWriting')
                    ) {
                        correctCount += 1;
                        checkedResults.push(result?.componentId);
                    }
                    if (!componentIdArray.includes(result.componentId)) {
                        componentIdArray.push(result.componentId);
                        questionAttempt += 1;
                    }
                }
                setNumberOfQuestion(questionCount);

                return {
                    student: `${student?.studentNumber ? student?.studentNumber : index}. ${student?.familyName}${student?.givenName}`,
                    correctCount: `${correctCount} / ${questionCount}`,
                    //TODO handle HH:mm:ss
                    totalTestDuration: moment.utc(answersTimeSec).format('mm:ss'),
                    finishTestTime: answersTimeSec,
                    totalScore: correctCount,
                    compete: questionAttempt === questionCount,
                    id: student.id,
                    onClick: async () => {
                        setSelectedStudent(student);
                        setStudentResults(student?.results ? student?.results : []);
                        setStudentGrading(student?.results?.some((result) => result.grading === 'ungraded') ? t('ungraded') : '' + correctCount);
                        navigator(
                            `../${SelectClassPath}/${schoolId}/${SelectTestroomPath}/${classId}/${
                                mode === 'Homework' ? 'Homework' : 'Classroom'
                            }/${SelectStudentPath}/${testroomId}/${SelectQuestionPath}/${student.id}`,
                        );
                    },
                };
            } else {
                return {
                    student: `${student?.studentNumber ? student?.studentNumber : index}. ${student?.familyName}${student?.givenName}`,
                    correctCount: 'NA',
                    totalTestDuration: 'NA',
                    finishTestTime: 0,
                    totalScore: student?.results?.some((result) => result.grading === 'ungraded') ? t('ungraded') : 'NA',
                    id: student.id,
                    compete: false,
                };
            }
        },
        [navigator, setSelectedStudent, setStudentGrading, setStudentResults, t],
    );
    const prepareStudentTable = useCallback(
        async (classId: string, selectedTestRoom: TestroomWithQuestions | undefined, testroomId: string, schoolId: string, isHomeworkMode: boolean) => {
            try {
                if (!selectedTestRoom) {
                    return;
                }
                const studentData = await getStudentData({
                    classId: classId ? classId : '',
                });
                const resultData = await getTestroomResult(isHomeworkMode, testroomId ? testroomId : '');
                setNumberOfAttempt(resultData?.results ? resultData?.results?.length : 1);

                const pairedStudentResults: StudentProfileWithResults[] | undefined = studentData?.studentProfiles?.map((student) => {
                    return {
                        ...student,
                        results: resultData?.results?.filter((result) => result.student.id === student.id),
                    };
                });
                const questionCount = selectedTestRoom?.lessonVersion?.slides?.reduce((prevValue, slide) => prevValue + slide.questionCount, 0);
                if (pairedStudentResults) {
                    setStudentDataWithResult(pairedStudentResults);
                    for (const student of pairedStudentResults) {
                        if (student.results) {
                            const componentIdArray: string[] = [];

                            for (const result of student.results) {
                                if (!componentIdArray.includes(result.componentId)) {
                                    componentIdArray.push(result.componentId);
                                }
                            }
                        }
                    }
                }
                if (pairedStudentResults) {
                    pairedStudentResults.sort((a, b) => (b.results ? b.results.length : 0) - (a.results ? a.results.length : 0));
                }
                const tableDataFromQuery = {
                    data: pairedStudentResults?.map((student: StudentProfileWithResults, index) => {
                        return studentResultsTableRow(student, index, questionCount, classId, schoolId, testroomId, isHomeworkMode ? 'Homework' : 'Classroom');
                    }),
                    columns: [
                        {
                            name: 'student',
                            nameStr: t('menu-students'),
                            sortCallback: () => {
                                setStudentTableData((prevState) => {
                                    if (prevState?.data) {
                                        const cloneState = [...prevState?.data];
                                        const sortDirection = getStringSortDirection(cloneState, 'student');
                                        if (sortDirection === 'ascending') {
                                            cloneState.sort((a, b) => b?.student?.localeCompare(a?.student));
                                        } else {
                                            cloneState.sort((a, b) => a?.student?.localeCompare(b?.student));
                                        }
                                        return {
                                            ...prevState,
                                            data: cloneState,
                                        };
                                    } else {
                                        return prevState;
                                    }
                                });
                            },
                        },
                        {
                            name: 'correctCount',
                            nameStr: t('correctCount'),
                            sortCallback: () => {
                                setStudentTableData((prevState) => {
                                    if (prevState?.data) {
                                        const cloneState = [...prevState?.data];
                                        const sortDirection = getStringSortDirection(cloneState, 'correctCount');
                                        if (sortDirection === 'ascending') {
                                            cloneState.sort((a, b) => b?.correctCount?.localeCompare(a?.correctCount));
                                        } else {
                                            cloneState.sort((a, b) => a?.correctCount?.localeCompare(b?.correctCount));
                                        }
                                        return {
                                            ...prevState,
                                            data: cloneState,
                                        };
                                    } else {
                                        return prevState;
                                    }
                                });
                            },
                        },
                        {
                            name: 'totalTestDuration',
                            nameStr: t('totalTestDuration'),
                            sortCallback: () => {
                                setStudentTableData((prevState) => {
                                    if (prevState?.data) {
                                        const cloneState = [...prevState?.data];
                                        const sortDirection = getStringSortDirection(cloneState, 'totalTestDuration');
                                        if (sortDirection === 'ascending') {
                                            cloneState.sort((a, b) => b?.totalTestDuration?.localeCompare(a?.totalTestDuration));
                                        } else {
                                            cloneState.sort((a, b) => a?.totalTestDuration?.localeCompare(b?.totalTestDuration));
                                        }
                                        return {
                                            ...prevState,
                                            data: cloneState,
                                        };
                                    } else {
                                        return prevState;
                                    }
                                });
                            },
                        },
                        {
                            name: 'totalScore',
                            nameStr: t('totalScore'),
                            sortCallback: () => {
                                setStudentTableData((prevState) => {
                                    if (prevState?.data) {
                                        const cloneState = [...prevState?.data];
                                        const sortDirection = getStringSortDirection(cloneState, 'totalScore');
                                        if (sortDirection === 'ascending') {
                                            cloneState.sort((a, b) => b?.totalScore?.localeCompare(a?.totalScore));
                                        } else {
                                            cloneState.sort((a, b) => a?.totalScore?.localeCompare(b?.totalScore));
                                        }
                                        return {
                                            ...prevState,
                                            data: cloneState,
                                        };
                                    } else {
                                        return prevState;
                                    }
                                });
                            },
                        },
                    ],
                };
                setStudentTableData(tableDataFromQuery);
                return pairedStudentResults;
            } catch (err: any) {
                enqueueSnackbar(t(`error.${err.message}`), {
                    variant: 'error',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                });
            }
        },
        [getTestroomResult, getStudentData, studentResultsTableRow, t],
    );

    return {
        prepareStudentTable,
        studentTableData,
        numberOfQuestion,
        numberOfAttempt,
        failedCompeteStudents,
        absentStudents,
        allStudentsAverageScore,
        allStudentsAnswerTime,
        setStudentTableData,
        studentDataWithResult,
        badPerformanceStudents,
        sortedStudentData,
    };
};
