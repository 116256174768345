import styled from '@emotion/styled';
import { CheckRounded, ClearRounded } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { EasyWritingAnswer } from 'pages/results/EasyWritingAnswer/EasyWritingAnswer';
import { WritingAnswer } from 'pages/results/component/WritingAnswer/WritingAnswer';
import moment from 'moment';
import { AnswerItemProp, Results, Strokes, WritingAnswer as WritingAnswerModel, WritingResult } from 'pages/results/models';
import { useTranslation } from 'react-i18next';
import { colors } from 'styles/Colors';
interface TopRightIconProps {
    isCorrect: boolean;
}

export const WritingAnswerItem = (props: AnswerItemProp) => {
    const { t } = useTranslation();

    return props.answerCardData?.results?.map((item: Results, index: number) => {
        const obj: WritingResult = JSON.parse(item.data);
        const writing: WritingAnswerModel = obj.writing;
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                }}>
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: '16px',
                        color: colors.yellow900,
                        lineHeight: '150%',
                    }}>
                    {t('answerTry', {
                        num: (index + 1).toString(),
                    })}
                </Typography>
                <Box
                    onClick={() => {
                        // setSelectedAnswerIndex('' + index);
                        // setSelectedAnswer([item]);
                    }}
                    sx={{
                        border: '1px solid #856666',
                        borderRadius: '8px',
                        // ...(selectedAnswerIndex === '' + index && {
                        //   border: '6px solid #29C0E7',
                        // }),
                    }}>
                    {writing.normalMode ? (
                        <TopRightIconContainer>
                            <EasyWritingAnswer strokes={writing.strokes as Strokes[][][]} writingVersion={writing.version} />
                            <TopRightIcon isCorrect={props.answerCardData.correct}>
                                {props.answerCardData.correct ? (
                                    <CheckRounded
                                        sx={{
                                            color: colors.reallyWhite,
                                            display: 'block',
                                            margin: 'auto',
                                        }}
                                    />
                                ) : (
                                    <ClearRounded
                                        sx={{
                                            color: colors.reallyWhite,
                                            display: 'block',
                                            margin: 'auto',
                                        }}
                                    />
                                )}
                            </TopRightIcon>
                        </TopRightIconContainer>
                    ) : (
                        <WritingAnswer strokes={writing.strokes as Strokes[][]} writingVersion={writing.version} />
                    )}
                </Box>
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: '16px',
                        color: colors.yellow900,
                    }}>
                    {t('answerTime')}: {moment.utc(moment(item?.endTime).diff(moment(item?.startTime))).format('mm:ss')}
                </Typography>
            </Box>
        );
    });
};

const TopRightIconContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

const TopRightIcon = styled.div<TopRightIconProps>`
    display: flex;
    position: absolute;
    width: 32px;
    height: 32px;
    right: -15px;
    top: -15px;
    border-radius: 50%;
    background: ${(props) => (props.isCorrect ? colors.success400 : colors.error500)};
`;
