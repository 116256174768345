import { gql, useLazyQuery } from '@apollo/client';
import { UserAuthContext } from 'contexts/UserAuthContext';
import { ERROR_TYPE, handleException, TTError } from 'models/ErrorHandling';
import { TestroomWithQuestions } from 'pages/testroom/models/TestroomModel';
import { useCallback, useContext } from 'react';
const GET_TESTROOMS_NAME_AND_DATE = gql`
    query GetTestrooms($teacher: IDFilter, $class: IDFilter) {
        testrooms(where: { teacher: { id: $teacher }, class: { id: $class } }, orderBy: [{ startTime: desc }]) {
            id
            testroomName
            startTime
        }
    }
`;

export const get_classroom_variable = (classId: string, teacherProfileId: string) => {
    return {
        where: {
            teacher: { equals: teacherProfileId },
            class: { equals: classId },
        },
    };
};

export type ModelQueryType = {
    testrooms: TestroomWithQuestions[];
};

type QueryVariableType = {
    teacher: { equals: string };
    class: { equals: string };
};

export const useClassroomsQuery = () => {
    const auth = useContext(UserAuthContext);
    const [queryData, { data, loading }] = useLazyQuery<ModelQueryType>(GET_TESTROOMS_NAME_AND_DATE);

    const getClassrooms = useCallback(
        async (variables: QueryVariableType): Promise<ModelQueryType | null> => {
            try {
                const result = await queryData({
                    variables,
                    fetchPolicy: 'no-cache',
                    notifyOnNetworkStatusChange: true,
                });
                if (!result?.data) {
                    throw new TTError(ERROR_TYPE.NO_DATA);
                }
                return result?.data;
            } catch (err) {
                const errorMsg = handleException(err);
                if (errorMsg === ERROR_TYPE.KS_ACCESS_DENIED) {
                    auth.accessDenied(errorMsg);
                }

                throw new TTError(errorMsg);
            }
        },
        [auth, queryData],
    );

    return { getClassrooms, data, loading };
};
