import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import { AudioAnswer } from 'pages/results/component/AudioAnswer/AudioAnswer';
import { useAudioResultAnswer } from 'pages/results/component/AnswerCard/hook/useAudioResultAnswer';
import moment from 'moment';
import { AnswerItemProp, Results } from 'pages/results/models';
import { useTranslation } from 'react-i18next';
import { colors } from 'styles/Colors';
import { AnswerCardDataProps } from '../AnswerCard';

export interface HkcsQ2AnswerItemProp {
    index: number;
    results: Results;
    answerCardData: AnswerCardDataProps;
}
export const SasAudioResultAnswerItem = (props: AnswerItemProp) => {
    const { t } = useTranslation();
    const { playingIdx, isPlaying, playSound } = useAudioResultAnswer({
        isPlaying: false,
        audio: null,
        playingIdx: -1,
    });
    return props?.answerCardData?.results?.map((item: any, index: number) => {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                }}>
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: '16px',
                        color: colors.yellow900,
                        lineHeight: '150%',
                    }}>
                    {t('answerTry', {
                        num: (index + 1).toString(),
                    })}
                </Typography>
                <Box
                    onClick={() => {
                        // setSelectedAnswerIndex('' + index);
                        // setSelectedAnswer([item]);
                    }}
                    sx={{
                        border: '1px solid #856666',
                        borderRadius: '8px',
                        // ...(selectedAnswerIndex === '' + index && {
                        //   border: '6px solid #29C0E7',
                        // }),
                    }}>
                    <SoundBoxContainer>
                        <AudioAnswer index={index} audioUrl={item.files[0].file.url} isPlaying={index === playingIdx && isPlaying} playSoundOnClick={playSound} />
                    </SoundBoxContainer>
                </Box>
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: '16px',
                        color: colors.yellow900,
                    }}>
                    {t('answerTime')}: {moment.utc(moment(item?.endTime).diff(moment(item?.startTime))).format('mm:ss')}
                </Typography>
            </Box>
        );
    });
};

const SoundBoxContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 184px;
    height: 184px;
    border-radius: 8px;
    background: #ffffff;
    position: relative;
`;
