import { useClassroomsQuery } from 'models/resultPage/classroom/useClassroomsQuery';
import { useClassroomResultQuery } from 'models/resultPage/classroom/useTestRoomResultQuery';
import { useHomeworkQuery } from 'models/resultPage/homework/useHomeworkQuery';
import { useHomeworkResultQuery } from 'models/resultPage/homework/useHomeworkResultQuery';
import { useClassroomQuery } from 'models/resultPage/classroom/useClassroomQuery';
import { useCallback } from 'react';
import { HomeworkModelModel, Questions, TestroomWithQuestions } from 'pages/testroom/models/TestroomModel';
import { Results } from 'models/resultPage/ResultPageModel';
import { useClassroomStudentResultQuery } from 'models/resultPage/classroom/useClassroomStudentResultQuery';
import { useHomeworkStudentResultQuery } from 'models/resultPage/homework/useHomeworkStudentResultQuery'; // Ensure this path is correct or create the module if it doesn't exist
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useHomeworksQuery } from 'models/resultPage/homework/useHomeworksQuery';
function isHomeworkModel(item: TestroomWithQuestions): item is HomeworkModelModel & Questions {
    return 'homeworkName' in item;
}
export const useTestroomController = () => {
    const { t } = useTranslation();
    const { getHomeworkResultData } = useHomeworkResultQuery();
    const { getClassroomsResultData } = useClassroomResultQuery();
    const { getClassrooms } = useClassroomsQuery();
    const { getHomeworks } = useHomeworksQuery();
    const { getClassroom } = useClassroomQuery();
    const { getHomework } = useHomeworkQuery();
    const { getStudentClassroomResult } = useClassroomStudentResultQuery();
    const { getHomeworkStudentResultQuery } = useHomeworkStudentResultQuery();
    const getTestroomResult = useCallback(
        async (isHomeworkMode: boolean, testroomId: string) => {
            if (isHomeworkMode) {
                try {
                    const data = await getHomeworkResultData({ homeworkId: testroomId });
                    const resultData = { results: data?.homeworkResults ?? [] };
                    return resultData;
                } catch (err: any) {
                    enqueueSnackbar(t(`error.${err.message}`), {
                        variant: 'error',
                        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                    });
                    return { results: [] };
                }
            } else {
                try {
                    const data = await getClassroomsResultData({ testroomId: testroomId });
                    return data;
                } catch (err: any) {
                    enqueueSnackbar(t(`error.${err.message}`), {
                        variant: 'error',
                        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                    });
                    return { results: [] };
                }
            }
        },
        [getHomeworkResultData, t, getClassroomsResultData],
    );

    const getTestrooms = useCallback(
        async (isHomeworkMode: boolean, classId: string, teacherProfileId: string): Promise<{ id: string; testName: string; testDate: Date }[]> => {
            if (isHomeworkMode) {
                try {
                    const data = await getHomeworks({
                        where: {
                            class: {
                                some: {
                                    id: {
                                        equals: classId,
                                    },
                                },
                            },
                        },
                    });
                    if (data) {
                        // data.homeworks;
                        const testrooms = data?.homeworks.map((homework) => {
                            return {
                                id: homework.id,
                                testName: 'homeworkName' in homework ? homework.homeworkName : '',
                                testDate: homework.startTime,
                            };
                        });
                        return testrooms;
                    }
                } catch (err: any) {
                    enqueueSnackbar(t(`error.${err.message}`), {
                        variant: 'error',
                        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                    });
                    return [];
                }
            } else {
                try {
                    const data = await getClassrooms({
                        teacher: { equals: teacherProfileId },
                        class: { equals: classId },
                    });

                    if (data) {
                        const testrooms = data?.testrooms.map((testroom) => {
                            return {
                                id: testroom.id,
                                testName: 'testroomName' in testroom ? testroom.testroomName : '',
                                testDate: testroom.startTime,
                            };
                        });
                        return testrooms;
                    }
                } catch (err: any) {
                    enqueueSnackbar(t(`error.${err.message}`), {
                        variant: 'error',
                        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                    });
                    return [];
                }
            }
            return [];
        },
        [getClassrooms, getHomeworks, t],
    );

    const getTestroom = useCallback(
        async (isHomeworkMode: boolean, testroomId: string): Promise<TestroomWithQuestions | undefined> => {
            try {
                if (isHomeworkMode) {
                    try {
                        let data = await getHomework({
                            where: {
                                id: testroomId,
                            },
                        });
                        if (data?.homework && isHomeworkModel(data.homework)) {
                            const homeworkData = {
                                ...data.homework,
                                testroomName: data.homework.homeworkName,
                            };
                            return homeworkData;
                        }
                    } catch (err: any) {
                        enqueueSnackbar(t(`error.${err.message}`), {
                            variant: 'error',
                            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                        });
                    }
                } else {
                    try {
                        const data = await getClassroom({ classroomId: testroomId });
                        return data?.testroom;
                    } catch (err: any) {
                        enqueueSnackbar(t(`error.${err.message}`), {
                            variant: 'error',
                            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                        });
                    }
                }
            } catch (error) {
                console.error('Error in getTestroom:', error);
                throw error;
            }
        },
        [getClassroom, getHomework, t],
    );

    const getStudentTestroomResult = useCallback(
        async (isHomeworkMode: boolean, testroomId: string, studentId: string): Promise<Results[] | undefined> => {
            if (isHomeworkMode) {
                try {
                    let data = await getHomeworkStudentResultQuery({
                        homeworkId: { equals: testroomId },
                        studentId: { equals: studentId },
                    });
                    return data?.homeworkResults;
                } catch (err: any) {
                    enqueueSnackbar(t(`error.${err.message}`), {
                        variant: 'error',
                        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                    });
                    return undefined;
                }
            } else {
                try {
                    const data = await getStudentClassroomResult({
                        testroomId: { equals: testroomId },
                        studentId: { equals: studentId },
                    });
                    return data?.results;
                } catch (err: any) {
                    enqueueSnackbar(t(`error.${err.message}`), {
                        variant: 'error',
                        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                    });
                    return undefined;
                }
            }
        },
        [getHomeworkStudentResultQuery, getStudentClassroomResult, t],
    );
    return { getTestroom, getTestrooms, getTestroomResult, getStudentTestroomResult };
};
