import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { UserAuthContext } from 'contexts/UserAuthContext';

import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { HomeworkStatus } from '../hooks/useHomeworksHook';

type FilterHomeworkProps = {
    onChange: (value: HomeworkStatus) => void;
};

export const FilterHomeworkSelect = (props: FilterHomeworkProps) => {
    const { t } = useTranslation();

    const [value, setValue] = useState<HomeworkStatus>(HomeworkStatus.all);

    const onChange = (event: SelectChangeEvent) => {
        setValue(event.target.value as HomeworkStatus);
        props.onChange(event.target.value as HomeworkStatus);
    };

    return (
        <>
            <FormControl sx={{ minWidth: 196, height: 42, mr: 1.5, backgroundColor: 'white' }}>
                <InputLabel id='homework-show'>{t('homework.show')}</InputLabel>
                <Select labelId='homework-show' id='homework-show' name='homework-show' value={value} label={t('class-name')} onChange={onChange} style={{ height: 42 }}>
                    <MenuItem value={HomeworkStatus.all}>{t('homework.status-all')}</MenuItem>
                    <MenuItem value={HomeworkStatus.started}>{t('homework.status-started')}</MenuItem>
                    <MenuItem value={HomeworkStatus.ended}>{t('homework.status-ended')}</MenuItem>
                    <MenuItem value={HomeworkStatus.archived}>{t('homework.status-archived')}</MenuItem>
                </Select>
            </FormControl>
        </>
    );
};
