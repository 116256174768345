import { gql, useLazyQuery } from '@apollo/client';
import { UserAuthContext, UserData } from 'contexts/UserAuthContext';
import { ERROR_TYPE, handleException, TTError } from 'models/ErrorHandling';
import { HomeworkModel } from 'models/homework/HomeworkModel';
import { SlideComponent } from 'models/lesson/LessonModel';
import { AuthenticatedItemModel } from 'pages/authentication/models/AuthenticatedItemModel';
import { useCallback, useContext } from 'react';

export const GET_HOMEWORK_TESTROOMS_NAME_AND_DATE = gql`
    query GetHomework($where: HomeworkWhereInput!) {
        authenticatedItem {
            ... on User {
                id
                name
                email
                role
                studentProfile {
                    id
                    user {
                        id
                    }
                    studentNumber
                    familyName
                    givenName
                    dob
                }
            }
        }
        homeworks(orderBy: { startTime: asc }, where: $where) {
            id
            name
            homeworkName
            startTime
            endTime
            serverTime
        }
    }
`;

export type ModelQueryType = {
    authenticatedItem: AuthenticatedItemModel;
    homeworks: HomeworkModel[];
};

export type Slide = {
    data: SlideComponent;
};
export type LessonVersion = {
    slides: Slide[];
};

export type Testroom = {
    id: string;
    lessonVersion: LessonVersion;
};

type QueryVariableType = {
    where: ClassFilter;
};

type ClassFilter = {
    class?: {
        some: {
            id: {
                equals: string | null;
            };
        };
    };
};

export const useHomeworksQuery = () => {
    const auth = useContext(UserAuthContext);
    const [queryData, { data, loading }] = useLazyQuery<ModelQueryType>(GET_HOMEWORK_TESTROOMS_NAME_AND_DATE);

    const getHomeworks = useCallback(
        async (variables: QueryVariableType): Promise<ModelQueryType | null> => {
            try {
                const result = await queryData({
                    variables,
                    fetchPolicy: 'no-cache',
                    notifyOnNetworkStatusChange: true,
                });

                if (!result?.data) {
                    throw new TTError(ERROR_TYPE.NO_DATA);
                }

                return result?.data;
            } catch (err) {
                const errorMsg = handleException(err);
                if (errorMsg === ERROR_TYPE.KS_ACCESS_DENIED) {
                    auth.accessDenied(errorMsg);
                }

                throw new TTError(errorMsg);
            }
        },
        [auth, queryData],
    );

    return { getHomeworks, data, loading };
};
