import { Box, Tab, Tabs } from '@mui/material';
import noResultImage from 'assets/images/no-result.png';
import { ResultTable, ResultTableProps } from 'pages/results/component/ResultTable/ResultTable';
import { useClassesQuery } from 'models/resultPage/classes/useClassesQuery';
import { ResultsPageContext } from 'pages/results/models';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { SelectClassPath, SelectTestroomPath } from 'routes/utils';

export const SelectClassPage = () => {
    const navigator = useNavigate();
    const { schoolId } = useParams();
    const { userData, setSelectedClass } = useOutletContext<ResultsPageContext>();
    const { t } = useTranslation();
    const { getClasses } = useClassesQuery();
    const [loading, setLoading] = useState(false);
    // Initialize mode from URL or default to 'Classroom'
    const [isHomeworkMode, setIsHomeworkMode] = useState<string>(() => {
        const params = new URLSearchParams(window.location.search);
        return params.get('mode') || 'Classroom';
    });

    const [classesTableData, setClassesTableData] = useState<ResultTableProps>();

    const handlePickTestroomModeChange = (event: React.SyntheticEvent, testroomMode: string) => {
        // Update URL with mode
        const newUrl = `${window.location.pathname}?mode=${testroomMode}`;
        window.history.pushState({ mode: testroomMode }, '', newUrl);
        setIsHomeworkMode(testroomMode);
    };

    // Handle browser back/forward navigation
    useEffect(() => {
        const handlePopState = (event: PopStateEvent) => {
            const params = new URLSearchParams(window.location.search);
            const mode = params.get('mode') || 'Classroom';
            setIsHomeworkMode(mode);
        };

        window.addEventListener('popstate', handlePopState);
        return () => window.removeEventListener('popstate', handlePopState);
    }, []);

    useEffect(() => {
        if (userData && schoolId && userData?.teacherProfileId) {
            const fetchClasses = async () => {
                setLoading(true);
                if (userData && schoolId && userData?.teacherProfileId) {
                    const classesData = await getClasses({ school: { equals: schoolId } });
                    const classesRow = classesData?.classes.map((item) => ({
                        className: item.className,
                        id: item.id,
                        onClick: async () => {
                            setSelectedClass(item);
                            navigator(`../${SelectClassPath}/${schoolId}/${SelectTestroomPath}/${item.id}/${isHomeworkMode === 'Homework' ? 'Homework' : 'Classwork'}`);
                        },
                    }));
                    setClassesTableData({
                        data: classesRow,
                        columns: [
                            {
                                name: 'className',
                                nameStr: t('class'),
                                sortCallback: () => {
                                    setClassesTableData((prevState) => {
                                        if (prevState?.data) {
                                            const cloneState = [...prevState.data];
                                            return {
                                                ...prevState,
                                                data: cloneState.reverse(),
                                            };
                                        }
                                        return prevState;
                                    });
                                },
                            },
                        ],
                    });
                }
                setLoading(false);
            };

            fetchClasses();
        }
    }, [getClasses, isHomeworkMode, navigator, schoolId, setSelectedClass, t, userData]);
    return (
        <>
            <Box sx={{ marginBottom: '36px' }}>
                <Tabs value={isHomeworkMode} onChange={handlePickTestroomModeChange} aria-label='basic tabs example'>
                    <Tab sx={{ fontSize: '24px', width: '120px' }} label={t('resultPage.classroom')} value={'Classroom'} />
                    <Tab sx={{ fontSize: '24px', width: '120px' }} label={t('resultPage.homework')} value={'Homework'} />
                </Tabs>
            </Box>
            <ResultTable columns={classesTableData?.columns} data={classesTableData?.data} isLoading={loading} showSearchTab={true} />
            {(classesTableData?.data?.length === 0 || loading) && (
                <img
                    src={noResultImage}
                    alt='no result'
                    style={{
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        paddingTop: '50px',
                    }}
                />
            )}
        </>
    );
};
