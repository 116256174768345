import { ResultTableProps } from 'pages/results/component/ResultTable/ResultTable';
import { useEffect, useState } from 'react';

// export type StudentProfileWithResults = StudentProfile & TestroomResults;
export type TopPerformanceCardProp = {
    studentData?: ResultTableProps;
};
export const useTopPerformanceCardHook = (props: TopPerformanceCardProp) => {
    const [topThreeStudents, setTopThreeStudents] = useState<any[]>([]);
    useEffect(() => {
        if (props.studentData) {
            if (props.studentData?.data !== undefined) {
                let sortedArray = [...props.studentData?.data].sort((a, b) => (typeof b.totalScore === 'string' ? 0 : b.totalScore) - (typeof a.totalScore === 'string' ? 0 : a.totalScore));
                setTopThreeStudents(sortedArray);
            }
        }
    }, [props.studentData]);
    return {
        topThreeStudents,
    };
};
