import { OverallResultByQuestion, StudentPerformance } from 'models/resultPage/ResultPageModel';
import { useCallback, useState } from 'react';
import { getStringSortDirection } from '../utils';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { ResultTableProps } from 'pages/results/component/ResultTable/ResultTable';
// export type StudentProfileWithResults = StudentProfile & TestroomResults;

export const useSpecificQuestionStudentResultHook = (resultByQuestion: OverallResultByQuestion[]) => {
    const { t } = useTranslation();
    const [questionTableFromEachStudentData, setQuestionTableFromEachStudentData] = useState<ResultTableProps>();
    const studentQuestionResultsTableRow = useCallback(
        (question: StudentPerformance, index: number) => {
            if (question) {
                const studentName = question.studentName;
                const studentScore = question.studentScore;
                const averageTime = question.averageTime;
                const questionAttempt = question.questionAttempt;
                const grading = question.grading;
                return {
                    student: studentName,
                    trueOrFalse: questionAttempt === 0 ? t('resultPage.not-attempted') : grading ? t('resultPage.correct') : t('resultPage.wrong'),
                    //TODO handle HH:mm:ss
                    answerTime: moment.utc(averageTime).isValid() ? moment.utc(averageTime).format('mm:ss') : '00:00',
                    attempt: questionAttempt,
                    score: studentScore,
                };
            } else {
                return {
                    student: '',
                    trueOrFalse: '',
                    //TODO handle HH:mm:ss
                    answerTime: '',
                    attempt: 0,
                    score: false,
                    onClick: async () => {},
                };
            }
        },
        [t],
    );
    const prepareStudentQuestionTable = useCallback(
        (questionId: string) => {
            const questionObj = resultByQuestion.filter((ans) => ans.questionID === questionId);
            if (resultByQuestion.length > 0) {
                const tableDataFromQuery = {
                    data: questionObj[0].studentPerformances?.map((student: StudentPerformance, index) => {
                        return studentQuestionResultsTableRow(student, index);
                    }),
                    columns: [
                        {
                            name: 'student',
                            nameStr: t('menu-students'),
                            sortCallback: () => {
                                setQuestionTableFromEachStudentData((prevState) => {
                                    if (prevState?.data) {
                                        const cloneState = [...prevState?.data];
                                        const sortDirection = getStringSortDirection(cloneState, 'student');
                                        if (sortDirection === 'ascending') {
                                            cloneState.sort((a, b) => b?.student?.localeCompare(a?.student));
                                        } else {
                                            cloneState.sort((a, b) => a?.student?.localeCompare(b?.student));
                                        }
                                        return {
                                            ...prevState,
                                            data: cloneState,
                                        };
                                    } else {
                                        return prevState;
                                    }
                                });
                            },
                        },
                        {
                            name: 'trueOrFalse',
                            nameStr: t('trueOrFalse'),
                            resultIndicator: true,
                            sortCallback: () => {
                                setQuestionTableFromEachStudentData((prevState) => {
                                    if (prevState?.data) {
                                        const cloneState = [...prevState?.data];
                                        const sortDirection = getStringSortDirection(cloneState, 'isCorrect');
                                        if (sortDirection === 'ascending') {
                                            cloneState.sort((a, b) => b?.correctCount?.localeCompare(a?.correctCount));
                                        } else {
                                            cloneState.sort((a, b) => a?.correctCount?.localeCompare(b?.correctCount));
                                        }
                                        return {
                                            ...prevState,
                                            data: cloneState,
                                        };
                                    } else {
                                        return prevState;
                                    }
                                });
                            },
                        },
                        {
                            name: 'answerTime',
                            nameStr: t('answerTime'),
                            sortCallback: () => {
                                setQuestionTableFromEachStudentData((prevState) => {
                                    if (prevState?.data) {
                                        const cloneState = [...prevState?.data];
                                        const sortDirection = getStringSortDirection(cloneState, 'answerTime');
                                        if (sortDirection === 'ascending') {
                                            cloneState.sort((a, b) => b?.totalTestDuration?.localeCompare(a?.totalTestDuration));
                                        } else {
                                            cloneState.sort((a, b) => a?.totalTestDuration?.localeCompare(b?.totalTestDuration));
                                        }
                                        return {
                                            ...prevState,
                                            data: cloneState,
                                        };
                                    } else {
                                        return prevState;
                                    }
                                });
                            },
                        },
                        {
                            name: 'attempt',
                            nameStr: t('resultPage.answer-attempt'),
                            sortCallback: () => {
                                setQuestionTableFromEachStudentData((prevState) => {
                                    if (prevState?.data) {
                                        const cloneState = [...prevState?.data];
                                        const sortDirection = getStringSortDirection(cloneState, 'attempt');
                                        if (sortDirection === 'ascending') {
                                            cloneState.sort((a, b) => b?.totalTestDuration?.localeCompare(a?.totalTestDuration));
                                        } else {
                                            cloneState.sort((a, b) => a?.totalTestDuration?.localeCompare(b?.totalTestDuration));
                                        }
                                        return {
                                            ...prevState,
                                            data: cloneState,
                                        };
                                    } else {
                                        return prevState;
                                    }
                                });
                            },
                        },
                        {
                            name: 'score',
                            nameStr: t('score'),
                            sortCallback: () => {
                                setQuestionTableFromEachStudentData((prevState) => {
                                    if (prevState?.data) {
                                        const cloneState = [...prevState?.data];
                                        const sortDirection = getStringSortDirection(cloneState, 'score');
                                        if (sortDirection === 'ascending') {
                                            cloneState.sort((a, b) => b?.totalTestDuration?.localeCompare(a?.totalTestDuration));
                                        } else {
                                            cloneState.sort((a, b) => a?.totalTestDuration?.localeCompare(b?.totalTestDuration));
                                        }
                                        return {
                                            ...prevState,
                                            data: cloneState,
                                        };
                                    } else {
                                        return prevState;
                                    }
                                });
                            },
                        },
                    ],
                };
                setQuestionTableFromEachStudentData(tableDataFromQuery);
            }
        },
        [resultByQuestion, t, studentQuestionResultsTableRow],
    );

    return {
        prepareStudentQuestionTable,
        questionTableFromEachStudentData,
    };
};
