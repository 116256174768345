import { Box, Typography } from '@mui/material';
import { CardAnswer } from 'pages/results/component/CardAnswer/CardAnswer';
import { t } from 'i18next';
import moment from 'moment';
import { AnswerCardDataProps } from '../AnswerCard';
import styled from '@emotion/styled';
import { colors } from 'styles/Colors';
import { Constants } from 'Constants';
import { useAnswerGroups } from '../hook/UseAnswerGroup';

export const CardAnswersItem = (cards: AnswerCardDataProps, locale: Constants.Locale) => {
    const answersGroups = useAnswerGroups(cards.results);

    return (
        <Cards>
            {answersGroups.map((answers: any[], index: number) => (
                <Box
                    key={index}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '24px',
                        background: '#f8f0e0',
                        borderRadius: '12px',
                        padding: '8px',
                    }}>
                    <Box
                        sx={{
                            background: '#FCC86C',
                            color: colors.yellow900,
                            width: 'fit-content',
                            padding: '4px 32px',
                        }}>
                        {t('answerRound').replace('{{num}}', '' + (index + 1))}
                    </Box>
                    <CardAnswerGroupContainer>
                        {answers.map((answer: any, index) => {
                            const obj = JSON.parse(answer.data);
                            const card: any = Object.values(obj)[0];
                            return (
                                <CardAnswerContainer key={index}>
                                    <Typography
                                        sx={{
                                            fontWeight: 700,
                                            fontSize: '16px',
                                            color: colors.yellow900,
                                            lineHeight: '150%',
                                        }}>
                                        {t('answerTry', {
                                            num: (index + 1).toString(),
                                        })}
                                        :
                                    </Typography>
                                    <CardAnswer card={card} isCorrect={answer?.correct} locale={locale} />
                                    <Typography
                                        sx={{
                                            fontWeight: 400,
                                            fontSize: '16px',
                                            color: colors.yellow900,
                                        }}>
                                        {t('answerTime')}: {moment.utc(moment(answer?.endTime).diff(moment(answer?.startTime))).format('mm:ss')}
                                    </Typography>
                                </CardAnswerContainer>
                            );
                        })}
                    </CardAnswerGroupContainer>
                </Box>
            ))}
        </Cards>
    );
};

const Cards = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const CardAnswerGroupContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
    background: #f8f0e0;
    border-radius: 12px;
`;

const CardAnswerContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 16px 24px;
    background: ${colors.yellow050};
    border-radius: 12px;
`;
