import WarningIcon from '@mui/icons-material/Warning';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, SxProps, TextField, Theme, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
    open: boolean;
    handleClose: () => void;
    handleDelete: () => void;
};
export const DeletePopup = (props: Props) => {
    const { t } = useTranslation();

    const [keyword, setKeyword] = useState('');
    const [isError, setIsError] = useState(false);

    const textStyles: SxProps<Theme> = {
        color: '#543E1A',
    };

    useEffect(() => {
        setIsError(false);
        setKeyword('');
    }, [props.open]);

    return (
        <Dialog open={props.open} onClose={props.handleClose} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
            <DialogTitle id='alert-dialog-title' sx={textStyles}>
                {t('homework.delete.title')}
            </DialogTitle>
            <Box sx={{ backgroundColor: '#533D180A', width: '100%', pl: 3, pr: 3, pt: 2, pb: 2, display: 'flex', gap: 1 }}>
                <WarningIcon sx={{ color: '#ED6C02' }} />
                <Typography>{t('homework.delete.description')}</Typography>
            </Box>
            <DialogContent sx={{ width: 400 }}>
                <DialogContentText id='alert-dialog-description2' sx={textStyles}>
                    {t('homework.delete.description2')}
                </DialogContentText>

                <DialogContentText id='alert-dialog-description3' sx={[textStyles, { mt: 2 }]}>
                    {t('homework.delete.description3')}
                </DialogContentText>

                <TextField
                    label={t('homework.delete.enterKeyword')}
                    variant='outlined'
                    fullWidth
                    sx={{ mt: 2 }}
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                    error={isError}
                    helperText={isError ? t('homework.delete.description3') : ''}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>{t('cancel')}</Button>
                <Button
                    onClick={() => {
                        if (keyword !== 'DELETE') {
                            setIsError(true);
                            return;
                        }

                        props.handleDelete();
                    }}
                    autoFocus>
                    {t('delete')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
