import { gql, useLazyQuery } from '@apollo/client';
import { UserAuthContext } from 'contexts/UserAuthContext';
import { ERROR_TYPE, TTError, handleException } from 'models/ErrorHandling';
import { AuthenticatedItemModel } from 'pages/authentication/models/AuthenticatedItemModel';
import { useCallback, useContext } from 'react';
import { HomeworkModel } from './HomeworkModel';

const QUERY_GQL = gql`
    query GetHomework($skip: Int!, $take: Int!, $where: HomeworkWhereInput!) {
        authenticatedItem {
            ... on User {
                id
                name
                email
                role
                studentProfile {
                    id
                    user {
                        id
                    }
                    studentNumber
                    familyName
                    givenName
                    dob
                }
            }
        }
        homeworks(skip: $skip, take: $take, orderBy: { startTime: asc }, where: $where) {
            id
            name
            lessonVersion {
                id
                lessonName
            }
            homeworkName
            startTime
            endTime
            serverTime
            isArchived
            teacher {
                id
            }
            class {
                id
                className
            }
            classCount
        }
        homeworksCount(where: $where)
    }
`;

type QueryVariableType = {
    skip: number;
    take: number;
    where: {
        teacher: {
            id: {
                equals: string;
            };
        };
        isArchived?: {
            equals: boolean;
        };
        endTime?: {
            gt?: string;
            lte?: string;
        };
    };
};

type ModelQueryType = {
    authenticatedItem: AuthenticatedItemModel;
    homeworks: HomeworkModel[];
    homeworksCount: number;
};

export const useHomeworkModelQuery = () => {
    const auth = useContext(UserAuthContext);
    const [queryData, { data, loading }] = useLazyQuery<ModelQueryType>(QUERY_GQL);

    const getHomeworks = useCallback(
        async (variables: QueryVariableType): Promise<ModelQueryType | null> => {
            try {
                const result = await queryData({
                    variables,
                    fetchPolicy: 'no-cache',
                    notifyOnNetworkStatusChange: true,
                });

                if (!result?.data) {
                    throw new TTError(ERROR_TYPE.NO_DATA);
                }

                return result?.data;
            } catch (err) {
                const errorMsg = handleException(err);
                if (errorMsg === ERROR_TYPE.KS_ACCESS_DENIED) {
                    auth.accessDenied(errorMsg);
                }

                throw new TTError(errorMsg);
            }
        },
        [auth, queryData],
    );

    return { getHomeworks, data, loading };
};
