import { Box } from '@mui/material';
import AverageClassScore from 'pages/results/component/OverallClassResultPage/AverageClassScore';
import OverallStudentRanking from 'pages/results/component/OverallClassResultPage/OverallStudentRanking/OverallStudentRanking';
import StudentResultTable from 'pages/results/component/OverallClassResultPage/OverallStudentResultInfo/StudentResultTable';
import StudentNumber from 'pages/results/component/OverallClassResultPage/StudentNumber';
import AnswerPerformanceGroupChart from 'pages/results/component/OverallResultPage/AnswerPerformanceGroupChart';
import { AvgScorePerStudent, TestroomScore, useOverallTestroomResult } from 'pages/results/hook/useOverallTestroomResult';
import { useEffect, useState } from 'react';

// deleted
const OverallClassResultPage = () => {
    const { getOverallTestRoom, getOverallTestRoomByType } = useOverallTestroomResult();
    const [studentAverageScore, setStudentAverageScore] = useState(0);
    const [studentCount, setStudentCount] = useState(0);
    const [testRoomData, setTestroomData] = useState<TestroomScore>({});
    const [studentData, setStudentData] = useState<AvgScorePerStudent[]>([]);
    const [resultTypeData, setResultTypeData] = useState<any>({});
    useEffect(() => {
        getTestroomResult();
    }, []);
    const getTestroomResult = async () => {
        const res = await getOverallTestRoom();
        setStudentAverageScore(res?.avgScoreAllStudent ?? 0);
        setStudentCount(res?.studentCount ?? 0);
        setTestroomData(res?.testroomScore ?? {});
        setStudentData(res?.avgScorePerStudent ?? []);
        const resType = await getOverallTestRoomByType();
        console.log(resType);
        setResultTypeData(resType);
    };
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                gap: 5,
                flex: '1',
                alignSelf: 'stretch',
            }}>
            <Box
                sx={{
                    display: 'flex',
                    flex: 4,
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '16px',
                    alignSelf: 'stretch',
                }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        gap: '16px',
                        alignSelf: 'stretch',
                    }}>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            gap: '20px',
                            alignSelf: 'stretch',
                        }}>
                        <AverageClassScore score={studentAverageScore} />
                        <StudentNumber student={studentCount} />
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                        }}>
                        <AnswerPerformanceGroupChart testroomData={testRoomData} />
                    </Box>
                    {/* <OverallQuestionTypePerformance props={resultTypeData} /> */}
                    <StudentResultTable studentData={studentData} />
                </Box>
            </Box>
            <OverallStudentRanking studentData={studentData} />
        </Box>
    );
};

export default OverallClassResultPage;
