import { ApolloClient, InMemoryCache } from '@apollo/client';

export function CreateApolloClient() {
    const token = localStorage.getItem('token');
    return Client(token);
}

function Client(token: string | null) {
    const cache = new InMemoryCache();
    const client = new ApolloClient({
        uri: process.env.REACT_APP_BACKEND_API_URL,
        cache,
        headers: token ? { authorization: `Bearer ${token}` } : {},
    });

    return client;
}
