import { useState } from 'react';

import btnNormal from './img/clever-btn-normal.png';
import btnHover from './img/clever-btn-hover.png';
import { t } from 'i18next';

const CLIENT_ID = '9bb51623462f47dd0659';

export const CleverLoginButton = () => {
    const [hover, setHover] = useState(false);

    return (
        <button
            name='clever-login-button'
            onClick={() => {
                setHover(false);
                window.location.href = `https://clever.com/oauth/authorize?response_type=code&redirect_uri=${window.location.origin}/on-boarding/sign-in&client_id=${CLIENT_ID}`;
            }}
            onMouseEnter={() => {
                setHover(true);
            }}
            onMouseLeave={() => {
                setHover(false);
            }}
            style={{
                border: 'none',
                backgroundImage: `url(${hover ? btnHover : btnNormal})`,
                padding: 0,
                height: '40px',
                width: '360px',
                color: '#1464FF',
                fontSize: '14px',
                backgroundSize: 'cover',
            }}>
            {t('sso.clever')}
        </button>
    );
};
