import { gql, useMutation } from '@apollo/client';
import { Results } from '../ResultPageModel';

const UPDATE_RESULTS = gql`
    mutation UpdateResults($data: [ResultUpdateArgs!]!) {
        updateResults(data: $data) {
            id
            grading
            data
        }
    }
`;

export const useUpdateResultsMutation = () => {
    const [updateResults] = useMutation(UPDATE_RESULTS);

    return { updateResults };
};
