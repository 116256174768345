// asset
import CloseIcon from '@mui/icons-material/Close';
import PrintIcon from '@mui/icons-material/Print';
import './PrintQRCodeModal.css';

import { Box, Button, Grid, Dialog, Typography, Toolbar, IconButton, AppBar, DialogContent, CircularProgress, Container, Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PopupCircularProgress } from 'components/General/Popups/PopupCircularProgress';
import { QRCode } from 'react-qrcode-logo';
import { StudentModel } from 'models/student/StudentModel';
import { useEffect, useState } from 'react';
import { CreateApolloClient } from 'client/apollo';
import { CREATE_SESSION, CreateSessionData, CreateSessionVariables } from 'client/gql';
import { Constants } from 'Constants';

type Props = {
    students: StudentModel[];
    isOpened: boolean;
    onClose: () => void;
};

export const PrintQRCodeModal = (props: Props) => {
    // provider
    const { t } = useTranslation();

    // ui state
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>();
    const [tokens, setTokens] = useState<Record<string, string>>({});

    // handle session creation
    useEffect(() => {
        if (props.isOpened) {
            setLoading(true);
            setTokens({});
            setError(null);

            const client = CreateApolloClient();

            Promise.all(
                props.students.map((student) => {
                    return client.mutate<CreateSessionData, CreateSessionVariables>({
                        mutation: CREATE_SESSION,
                        variables: {
                            studentProfileId: student.studentProfile.id,
                            ttl: Constants.User.StudentSessionTTL,
                        },
                    });
                }),
            )
                .then((results) => {
                    const tokens: Record<string, string> = {};
                    for (const result of results) {
                        if (!result.data || !result.data.authenticateWithClassroom) {
                            throw new Error(`No data`);
                        } else if (result.data?.authenticateWithClassroom.__typename === 'AuthenticateWithClassroomFailure') {
                            throw new Error(result.data.authenticateWithClassroom.message);
                        } else {
                            tokens[result.data.authenticateWithClassroom.item.id] = result.data.authenticateWithClassroom.sessionToken;
                        }
                    }
                    setTokens(tokens);
                })
                .catch((error) => {
                    setTokens({});
                    setError(error instanceof Error ? error.message : `${error}`);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [props.students, props.isOpened]);

    return (
        <>
            <Dialog open={props.isOpened} fullScreen onClose={props.onClose}>
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton edge='start' color='inherit' onClick={props.onClose} aria-label='close'>
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
                            {t('studentPage.print-student-qrcode')}
                        </Typography>
                        <Button autoFocus color='inherit' onClick={() => window.print()} startIcon={<PrintIcon />}>
                            {t('studentPage.print')}
                        </Button>
                    </Toolbar>
                </AppBar>
                <DialogContent className='print-area' sx={{ background: 'transparent' }}>
                    {error ? (
                        <Alert severity='error'>{error}</Alert>
                    ) : loading ? (
                        <Container sx={{ display: 'flex', height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                            <CircularProgress />
                        </Container>
                    ) : (
                        <Grid container spacing={3}>
                            {props.students.map((student: StudentModel, index: number) => (
                                <Grid item xs={6} key={'QRCODE' + index}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', py: '24px' }}>
                                        <QRCode ecLevel='M' size={400} fgColor={'#000000'} value={tokens[student.studentProfile.id]} />
                                        <Typography textAlign='center' sx={{ mt: 1 }}>
                                            {student.studentProfile.class.school.schoolName}
                                        </Typography>
                                        <Typography textAlign='center'>
                                            {student.studentProfile.class.className}, {student.name}
                                            {student.studentProfile.studentNumber ? ` (${student.studentProfile.studentNumber})` : ''}
                                        </Typography>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    )}
                </DialogContent>
            </Dialog>
            <PopupCircularProgress isOpened={false} />
        </>
    );
};
