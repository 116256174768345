import { useCallback, useEffect, useState } from 'react';
import { AnswerCardDataProps } from '../AnswerCard';
import { Results, WritingResult } from 'pages/results/models';

export const useAnswerCardHook = (answerCardData: AnswerCardDataProps) => {
    const [isAutoCorrect, SetIsAutoCorrect] = useState(true);
    const [selectedAnswers, setSelectedAnswer] = useState<Results[]>([]);
    const handleWritingAnswerCard = useCallback(() => {
        if (answerCardData.questionType === 'Writing') {
            //check an object is empty
            if (Array.isArray(answerCardData.results)) {
                SetIsAutoCorrect(answerCardData.results.some((result) => !(JSON.parse(result?.data) as WritingResult).writing.normalMode));
            }

            // select all items for grading
            setSelectedAnswer(answerCardData.results);
        }
    }, [answerCardData]);
    const handleAudioAnswer = useCallback(() => {
        if (answerCardData.questionType === 'Audio') {
            // select all items for grading
            // const item: any = Array.isArray(data.data) ? (data.data.length > 0 ? data.data[0] : {}) : {};
            // if (Object.keys(item).length !== 0) {
            //     setSelectedAnswer([item]);
            // }
            setSelectedAnswer(answerCardData.results);
            SetIsAutoCorrect(false);
        }
    }, [answerCardData]);
    const handleSasAudioAnswer = useCallback(() => {
        if (answerCardData.questionType === 'sasAudio') {
            // select all items for grading
            setSelectedAnswer(answerCardData.results);
            SetIsAutoCorrect(false);
        }
    }, [answerCardData]);

    useEffect(() => {
        if (answerCardData.questionType === 'Writing') {
            handleWritingAnswerCard();
        }
        if (answerCardData.questionType === 'Audio') {
            handleAudioAnswer();
        }
        if (answerCardData.questionType === 'sasAudio') {
            handleSasAudioAnswer();
        }
    }, [answerCardData, handleAudioAnswer, handleSasAudioAnswer, handleWritingAnswerCard]);

    return { selectedAnswers, isAutoCorrect, handleWritingAnswerCard, handleAudioAnswer, handleSasAudioAnswer };
};
