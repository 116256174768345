/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Chip, Grid, Stack, Typography, Box } from '@mui/material';
import { DataGrid, GridColDef, GridSelectionModel } from '@mui/x-data-grid';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UserAuthContext } from '../../contexts/UserAuthContext';

import { useAuthChecking } from 'hooks/useAuthChecking';

import { FilterHomeworkSelect } from './components/FilterHomeworkSelect';
import { PublishHomeworkButton } from './components/PublishHomeworkButton';

import { HomeworkStatus, useHomeworksHook } from './hooks/useHomeworksHook';

import { DeletePopup } from 'components/DeletePopup';
import { HomeworkModel } from 'models/homework/HomeworkModel';
import moment from 'moment';

type HomeworkGridUiType = {
    id: string;
    startTime: string;
    endTime: string;
    lessonName: string;
    homeworkName: string;

    status: HomeworkStatus;
    classes: { id: string; className: string }[];
};

export const HomeworkPage = () => {
    const { t } = useTranslation();
    const auth = useContext(UserAuthContext);

    const { data, loading, refresh, pagination, setPagination, homeworkFilter, setHomeworkFilter, handleDelete, handleArchive } = useHomeworksHook();

    useAuthChecking(data);

    const { authCheck } = useAuthChecking();

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [tobeDeletedIds, setTobeDeletedIds] = useState<{ id: string }[]>([]);

    const [selectedRows, setSelectedRows] = useState<GridSelectionModel>([]);

    const handleDeletePopupOpen = (ids: { id: string }[]) => {
        setIsDeleteModalOpen(true);
        setTobeDeletedIds(ids);
    };

    const handleDeletePopupClose = () => {
        setIsDeleteModalOpen(false);
    };

    const handleDeletePopupConfirm = () => {
        setIsDeleteModalOpen(false);
        handleDelete(tobeDeletedIds);

        setSelectedRows([]);
    };

    const flattenData = (data: HomeworkModel[]): HomeworkGridUiType[] => {
        return data.flatMap((item: HomeworkModel) => {
            const status = item.isArchived ? HomeworkStatus.archived : new Date(item.serverTime) > new Date(item.endTime) ? HomeworkStatus.ended : HomeworkStatus.started;

            return [
                {
                    id: item.id,
                    startTime: moment(item.startTime).format('DD/MM/YYYY'),
                    endTime: moment(item.endTime).format('DD/MM/YYYY'),
                    lessonName: item.lessonVersion?.lessonName,
                    homeworkName: item.homeworkName,

                    status: status,
                    classes: item.class || [],
                },
            ];
        });
    };

    const columns: GridColDef<HomeworkGridUiType>[] = [
        {
            field: 'startTime',
            headerName: t('homework.startTime'),
            flex: 1,
            sortable: false,
            filterable: false,
        },
        {
            field: 'endTime',
            headerName: t('homework.deadline'),
            flex: 1,
            sortable: false,
            filterable: false,
        },
        {
            field: 'lessonName',
            headerName: t('homework.lessonName'),
            flex: 1,
            sortable: false,
            filterable: false,
        },
        {
            field: 'homeworkName',
            headerName: t('homework.homeworkName'),
            flex: 1,
            sortable: false,
            filterable: false,
        },
        {
            field: 'classes',
            headerName: t('homework.class'),
            flex: 1,
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <Stack direction='row' spacing={1}>
                        {params.row.classes.map((data, index: number) => {
                            return <Chip sx={{ background: '#F2F0ED' }} key={index} label={data.className} />;
                        })}
                    </Stack>
                );
            },
        },
        {
            field: 'status',
            headerName: t('homework.status'),
            flex: 1,
            sortable: false,
            filterable: true,

            renderCell: (params) => {
                return (
                    <Typography
                        sx={{
                            //
                            fontSize: '0.875em',
                            color: params.row?.status === HomeworkStatus.archived ? '#533D18' : params.row?.status === HomeworkStatus.ended ? '#D32F2F' : '#2E7D32',
                        }}>
                        {params.row?.status === HomeworkStatus.started && t('homework.status-started')}
                        {params.row?.status === HomeworkStatus.ended && t('homework.status-ended')}
                        {params.row?.status === HomeworkStatus.archived && t('homework.status-archived')}
                    </Typography>
                );
            },
        },
        {
            field: 'delete',
            headerName: '',
            flex: 1,
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <Button
                        variant='outlined'
                        color='error'
                        sx={{
                            textTransform: 'none',
                            borderRadius: '8px',
                        }}
                        onClick={(event) => {
                            event.stopPropagation();
                            handleDeletePopupOpen([{ id: params.row?.id }]);
                        }}>
                        {t('delete')}
                    </Button>
                );
            },
        },
        {
            field: 'archive',
            headerName: '',
            flex: 1,
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <Button
                        disabled={params.row?.status === HomeworkStatus.archived}
                        variant='outlined'
                        color='inherit'
                        sx={{
                            textTransform: 'none',
                            borderRadius: '8px',
                        }}
                        onClick={(event) => {
                            event.stopPropagation();
                            handleArchive([{ where: { id: params.row?.id }, data: { isArchived: true } }]);
                        }}>
                        {t('archive')}
                    </Button>
                );
            },
        },
    ];

    useEffect(() => {
        if (pagination.pageSize > 0) {
            refresh();
        }
    }, [pagination, auth.selectedSchool]);

    // check session expire
    useEffect(() => {
        if (data) {
            try {
                authCheck(data?.authenticatedItem);
            } catch (err) {
                auth.logout();
            }
        }
    }, [data?.authenticatedItem]);

    const onFilterValueChange = (value: HomeworkStatus) => {
        setHomeworkFilter(value);
    };

    const onSelectionModelChange = (item: GridSelectionModel) => {
        setSelectedRows(item);
    };

    return (
        <>
            <Stack direction='column' spacing={1} className='pageCustom'>
                <Grid container sx={{ pb: 3 }}>
                    <Grid item container justifyContent='flex-start' xs={6}>
                        <Typography variant='h4'>{t('menu-homework')}</Typography>
                    </Grid>
                    <Grid item container justifyContent='flex-end' xs={6}>
                        <FilterHomeworkSelect onChange={onFilterValueChange} />
                        <PublishHomeworkButton refresh={refresh} />
                    </Grid>
                </Grid>

                <Stack
                    id='DataGridContainer'
                    sx={{
                        height: 1,

                        border: 2,
                        borderColor: '#DCCDB5',
                        borderRadius: '16px',
                        backgroundColor: '#fff',
                    }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            height: '68px',
                            p: '16px',
                            gap: '8px',
                            borderBottom: '1px solid rgba(224, 224, 224, 1)',
                        }}>
                        <Button
                            disabled={selectedRows.length === 0}
                            variant='outlined'
                            color='error'
                            sx={{
                                textTransform: 'none',
                                borderRadius: '8px',
                            }}
                            onClick={(event) => {
                                event.stopPropagation();
                                handleDeletePopupOpen(
                                    selectedRows.map((id) => {
                                        return { id: id.toString() };
                                    }),
                                );
                            }}>
                            {t('delete')}
                        </Button>

                        <Button
                            disabled={selectedRows.length === 0 || homeworkFilter === HomeworkStatus.archived}
                            variant='outlined'
                            color='inherit'
                            sx={{
                                textTransform: 'none',
                                borderRadius: '8px',
                            }}
                            onClick={(event) => {
                                event.stopPropagation();
                                handleArchive(
                                    selectedRows.map((id) => {
                                        return {
                                            where: { id: id.toString() },
                                            data: { isArchived: true },
                                        };
                                    }),
                                );
                            }}>
                            {t('archive')}
                        </Button>
                    </Box>
                    <Box
                        sx={{
                            height: 1,
                        }}>
                        <DataGrid
                            sx={{
                                border: 0,
                                height: 1,
                            }}
                            checkboxSelection
                            onSelectionModelChange={onSelectionModelChange}
                            disableColumnMenu={true}
                            columns={columns}
                            paginationMode='server'
                            rows={flattenData(data?.homeworks || [])}
                            loading={loading}
                            page={pagination.page}
                            rowCount={data?.homeworksCount || 0}
                            // onSortModelChange={onSortModelChange}
                            onPageChange={(page) => {
                                setPagination((prev) => ({ ...prev, page: page }));
                            }}
                            autoPageSize
                            onPageSizeChange={(pz) => {
                                setPagination((prev) => ({ ...prev, pageSize: pz }));
                            }}
                        />
                    </Box>
                </Stack>
            </Stack>

            <DeletePopup open={isDeleteModalOpen} handleClose={handleDeletePopupClose} handleDelete={handleDeletePopupConfirm} />
        </>
    );
};
