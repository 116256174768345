import { Box, Typography } from '@mui/material';
import moment from 'moment';
import SasRadicalMatchingAnswer from 'pages/results/component/SasRadicalMatching/SasRadicalMatchingAnswer';
import { AnswerItemProp, Results, SasRadicalMatchingResult } from 'pages/results/models';
import { TestroomWithQuestions } from 'pages/testroom/models/TestroomModel';
import { useTranslation } from 'react-i18next';
import { colors } from 'styles/Colors';
import { AnswerCardDataProps } from '../AnswerCard';

export interface HkcsQ2AnswerItemProp {
    index: number;
    results: Results;
    answerCardData: AnswerCardDataProps;
    selectedTestroom: TestroomWithQuestions;
}
export const SasRadicalMatchingAnswerItem = (props: AnswerItemProp) => {
    const { t } = useTranslation();
    return props?.answerCardData?.results?.map((item: any, index: number) => {
        const sasObj = props?.selectedTestroom?.lessonVersion?.lessonQuestions.filter((lessonObj) => lessonObj.id === props?.answerCardData.questionID);
        if (sasObj === undefined) {
            return <>no question found</>;
        }
        let sasRadicalMatchingResObj: SasRadicalMatchingResult = JSON.parse(item.data) as SasRadicalMatchingResult;
        let cardAns = [];
        let questionImageURL1 = '';
        let questionImageURL2 = '';
        let sasRadicalCardContent: any = {};
        let answersAttempted: number[] = JSON.parse(sasRadicalMatchingResObj?.sasRadicalMatching?.answers) as number[];
        let isColumn: boolean = false;
        if (sasObj.length > 0) {
            if (sasObj[0].content) {
                questionImageURL1 = sasObj[0].content.questionImageURL1 ?? '';
                questionImageURL2 = sasObj[0].content.questionImageURL2 ?? '';
                sasRadicalCardContent = sasObj[0].content;
                cardAns = sasRadicalCardContent?.answers;
                isColumn = sasRadicalCardContent?.layout === 1 ? false : true;
            }
        }
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                }}>
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: '16px',
                        color: colors.yellow900,
                        lineHeight: '150%',
                    }}>
                    {t('answerTry', {
                        num: (index + 1).toString(),
                    })}
                </Typography>
                <Box
                    onClick={() => {
                        // setSelectedAnswerIndex('' + index);
                        // setSelectedAnswer([item]);
                    }}
                    sx={
                        {
                            // ...(selectedAnswerIndex === '' + index && {
                            //   border: '6px solid #29C0E7',
                            // }),
                        }
                    }>
                    <SasRadicalMatchingAnswer isColumn={isColumn} answers={answersAttempted} characterImg1={questionImageURL1} characterImg2={questionImageURL2} sasCardChoices={cardAns} />
                </Box>
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: '16px',
                        color: colors.yellow900,
                    }}>
                    {t('answerTime')}: {moment.utc(moment(item?.endTime).diff(moment(item?.startTime))).format('mm:ss')}
                </Typography>
            </Box>
        );
    });
};
