import { Box, Divider, Stack, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { SlideComponent, SlideComponentHkcsQ1 } from 'models/lesson/LessonModel';
import { SetStateAction, useEffect, useState } from 'react';
import useLessonSlideProps from '../../../hooks/useLessonSlideProps';
import { DoSound } from './components/DoSound';
import { OptionsType } from './components/PropsImagePicker';
import { SelectImageBox } from './components/SelectImageBox';
import { SelectWordsBox } from './components/SelectWordsBox';

import { createDebouncedSaveHandler } from './functions/debouncedSaveHandler';
const debouncedSaveHandler = createDebouncedSaveHandler();

export type SelectChangeEvent<T = string> = (Event & { target: { value: T; name: string } }) | React.ChangeEvent<HTMLInputElement>;

export const LessonsSlidePropsHkcsQ1 = ({
    item,
    index,
    setCanSave,
    handleCreate,
}: {
    item: SlideComponent;
    index: number;
    setCanSave?: React.Dispatch<SetStateAction<boolean>>;
    handleCreate?: (item: SlideComponent) => void;
}) => {
    const { t } = useTranslation();
    const { slideComponentHandler } = useLessonSlideProps();

    let itemContent: SlideComponentHkcsQ1 = item.content as SlideComponentHkcsQ1;

    const updateUI = (save: boolean) => {
        if (handleCreate) {
            handleCreate(item);
        } else {
            slideComponentHandler({ ...item }, save);
        }
    };

    const [gridHeight, setGridHeight] = useState('40vh');

    useEffect(() => {
        function handleResize() {
            // Get 50% of the height of the parent element
            // Assuming the parent's height is set to 80vh

            const parentOffset = 550;
            const newHeight = `${window.innerHeight - parentOffset}px`;
            setGridHeight(newHeight);
        }

        // Set the height initially
        handleResize();

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        setCanSave?.(
            (itemContent.image1 !== '' || itemContent.image1URL !== '') &&
                (itemContent.image2 !== '' || itemContent.image2URL !== '') &&
                itemContent.characterList.length > 0 &&
                itemContent.questions.filter((item) => item.image == '' && item.imageURL == '').length <= 0,
        );
    }, [item]);

    const saveUploadedImage = (imageURL: string, index: number) => {
        itemContent.questions[index].imageURL = imageURL;
    };
    const imageOnClick = (option: OptionsType, index: number) => {
        itemContent.questions[index].image = option?.value ?? '';
    };
    const deleteUploadedImage = (index: number) => {
        itemContent.questions[index].imageURL = '';
        itemContent.questions[index].image = '';
    };

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 1,
                    m: 1,
                }}>
                <Box sx={{ flex: '1 1 300px' }}>
                    <Stack spacing={2} justifyContent={'center'}>
                        <Box
                            sx={{
                                width: 150,
                                alignSelf: 'center',
                            }}>
                            <SelectWordsBox
                                singleWord={false}
                                canSelectQuestion={false}
                                questionLabel=''
                                characterList={itemContent?.characterList}
                                phrase={itemContent.phrase}
                                phraseSound={itemContent.phraseSound}
                                phraseSoundGroup={itemContent.phraseSoundGroup}
                                onPhraseSoundGroupChanged={(phraseSoundGroup) => {
                                    itemContent.phraseSoundGroup = phraseSoundGroup;
                                    updateUI(true);
                                }}
                                selectedCharacterIndex={itemContent.character}
                                onCharacterPicked={(characterPhrase) => {
                                    itemContent.characterList = characterPhrase?.character;
                                    itemContent.character = 0;
                                    itemContent.characterSound = {
                                        jyutping: characterPhrase?.character?.[itemContent.character]?.jyutping,
                                        pinyin: characterPhrase?.character?.[itemContent.character]?.pinyin,
                                    };
                                    itemContent.phrase = characterPhrase?.phrase;
                                    itemContent.phraseSound = {
                                        jyutping: characterPhrase?.jyutping,
                                        pinyin: characterPhrase?.pinyin,
                                    };
                                    updateUI(true);
                                }}
                                onQuestionCharacterSelected={(index, character) => {}}
                            />
                        </Box>
                        <Stack direction={'row'} spacing={1} justifyContent={'center'}>
                            <DoSound
                                soundString={itemContent?.phraseSound?.jyutping?.[0]?.sound}
                                phrase={itemContent?.phrase}
                                groupIndex={itemContent?.phraseSoundGroup?.jyutping?.[0]?.startIndex || 0}
                                folder='jyutping'
                                sx={{
                                    bgcolor: '#23B1D3',
                                    color: '#FAFAFA',
                                    '&:hover': {
                                        bgcolor: '#23B1D3',
                                        color: '#FAFAFA',
                                        opacity: '0.7',
                                    },
                                    pointerEvents: 'auto',
                                }}>
                                {t('cantonese')}
                            </DoSound>

                            <DoSound
                                soundString={itemContent?.phraseSound?.pinyin?.[0]?.sound}
                                phrase={itemContent?.phrase}
                                groupIndex={itemContent?.phraseSoundGroup?.pinyin?.[0]?.startIndex || 0}
                                folder='pinyin'
                                sx={{
                                    bgcolor: '#FFFFFF',
                                    color: '#29C0E7',
                                    '&:hover': {
                                        bgcolor: '#FFFFFF',
                                        color: '#29C0E7',
                                        opacity: '0.7',
                                    },
                                    pointerEvents: 'auto',
                                    borderWidth: 1,
                                    borderStyle: 'solid',
                                    borderColor: '#29C0E7',
                                }}>
                                {t('putonghua')}
                            </DoSound>
                        </Stack>
                        <Divider />
                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 1,
                            }}>
                            <Box sx={{ flex: '1 1 150px' }}>
                                <SelectImageBox
                                    keepMargin={false}
                                    imageURL={itemContent.image1URL ?? ''}
                                    image={itemContent.image1 ?? ''}
                                    flip={1}
                                    onSelected={(imageURL: string) => {
                                        itemContent.image1URL = imageURL;
                                        updateUI(true);
                                    }}
                                    handleDelete={() => {
                                        itemContent.image1URL = '';
                                        itemContent.image1 = '';
                                        updateUI(true);
                                    }}
                                    onClickHandler={(option: OptionsType) => {
                                        itemContent.image1 = option?.value ?? '';
                                        updateUI(true);
                                    }}
                                />
                                <TextField
                                    label={t('hkcs.q1.keyword')}
                                    type='text'
                                    sx={{ width: '100%', mt: 1 }}
                                    value={itemContent.keyword1}
                                    onChange={(e) => {
                                        itemContent.keyword1 = e.target.value;
                                        updateUI(false);
                                        debouncedSaveHandler(() => {
                                            updateUI(true);
                                        });
                                    }}
                                />
                            </Box>
                            <Box sx={{ flex: '1 1 150px' }}>
                                <SelectImageBox
                                    keepMargin={false}
                                    imageURL={itemContent.image2URL ?? ''}
                                    image={itemContent.image2 ?? ''}
                                    flip={1}
                                    onSelected={(imageURL: string) => {
                                        itemContent.image2URL = imageURL;
                                        updateUI(true);
                                    }}
                                    handleDelete={() => {
                                        itemContent.image2URL = '';
                                        itemContent.image2 = '';
                                        updateUI(true);
                                    }}
                                    onClickHandler={(option: OptionsType) => {
                                        itemContent.image2 = option?.value ?? '';
                                        updateUI(true);
                                    }}
                                />
                                <TextField
                                    label={t('hkcs.q1.keyword')}
                                    type='text'
                                    sx={{ width: '100%', mt: 1 }}
                                    value={itemContent.keyword2}
                                    onChange={(e) => {
                                        itemContent.keyword2 = e.target.value;
                                        updateUI(false);
                                        debouncedSaveHandler(() => {
                                            updateUI(true);
                                        });
                                    }}
                                />
                            </Box>
                        </Box>
                    </Stack>
                </Box>
                <Box sx={{ flex: '1 1 200px' }}>
                    <Stack spacing={1} justifyContent={'center'}>
                        <Typography>{t('hkcs.q1.questionDescription')}</Typography>
                        {itemContent?.questions?.map((question, index) => (
                            <SelectImageBox
                                key={index}
                                keepMargin={false}
                                imageURL={question.imageURL ?? ''}
                                image={question.image ?? ''}
                                flip={question.flip ?? 1}
                                onSelected={(imageURL: string) => {
                                    saveUploadedImage(imageURL, index);
                                    updateUI(true);
                                }}
                                handleDelete={() => {
                                    deleteUploadedImage(index);
                                    updateUI(true);
                                }}
                                onClickHandler={(option: OptionsType) => {
                                    imageOnClick(option, index);
                                    updateUI(true);
                                }}
                            />
                        ))}
                    </Stack>
                </Box>
            </Box>
        </>
    );
};
