import React, { useState } from 'react';
import { Box, Link, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const CsvDownloadComponent = () => {
    const { t } = useTranslation();
    const [showCSVHints, setShowCSVHints] = useState(false);
    const handleHelpClick = () => {
        setShowCSVHints(!showCSVHints);
    };

    return (
        <Box>
            {showCSVHints && (
                <Typography textAlign='center' fontSize={10}>
                    {t('studentPage.uploadInstruction')}
                </Typography>
            )}
            <Box
                style={{
                    fontSize: 10,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                <IconButton onClick={handleHelpClick}>
                    <HelpOutlineIcon
                        style={{
                            fontSize: 20,
                            marginRight: 5,
                        }}
                    />
                </IconButton>
                <Link href='/csv/template.csv'>{t('studentPage.csv-template')}</Link>
            </Box>
        </Box>
    );
};

export default CsvDownloadComponent;
