import { useContext, useMemo, useEffect } from 'react';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { LessonsSlideContextProvider } from 'pages/lessons/contexts/LessonsSlideContext';
import { WorksheetContextProvider } from 'pages/lessons/contexts/WorksheetContext';
import {
    CalendarPage,
    ClassesPage,
    HomePage,
    LessonsAddPage,
    WorksheetsAddPage,
    LessonsPage,
    WorksheetsPage,
    NotFoundPage,
    PreviewPage,
    ResultsPage,
    RootPage,
    StudentsPage,
    HomeworkPage,
    SupportPage,
    TestroomPage,
    TermsConditionsContent,
    PrivacyPolicyContent,
    // LandingPage,
    LandingPageWebFlow,
    LandingPageWebFlowEmbed,
    ProfilePage,
} from 'pages';
import { GradingPage, SelectClassPage, SelectQuestionPage, SelectStudentPage, SelectTestroomPage } from 'pages/results/subPage';
import {
    // AuthPath,
    CalendarPath,
    ClassesPath,
    EmailVerificationPath,
    GradingPath,
    LandingPath,
    LandingPathEmbed,
    LessonsPath,
    WorksheetsPath,
    OnBoardingPath,
    LessonsShareWithPublicPath,
    PreviewPath,
    ResultPath,
    SelectClassPath,
    SelectQuestionPath,
    SelectStudentPath,
    SelectTestroomPath,
    SignInPath,
    SignUpPath,
    RegisterSuccessPath,
    StudentsPath,
    HomeworkPath,
    SupportPath,
    TestroomPath,
    ForgotPasswordPath,
    ResetVerificationPath,
    SetNewPasswordPath,
    ResetSuccessPath,
    TermsConditionsPath,
    PrivacyPolicyPath,
    RegistrationProcessPath,
    ProfilePath,
    ConfirmLessonSharePath,
    OverallResultPath,
    SelectQuestionId,
    ClassOverallResultPath,
    RedirectPlaylistPath,
} from './utils';
import { OnBoardingPage } from 'pages/onBoarding/OnBoardingPage';
import { SignInPage } from 'pages/onBoarding/subPage/SignIn';
import { SignUpPage } from 'pages/onBoarding/subPage/SignUp';
import { EmailVerificationPage } from 'pages/onBoarding/subPage/EmailVerification';
import { RegisterSuccessPage } from 'pages/onBoarding/subPage/RegisterSuccess';
import { ForgotPasswordPage } from 'pages/onBoarding/subPage/ForgotPassword';
import { ResetVerificationPage } from 'pages/onBoarding/subPage/ResetVerification';
import { SetNewPasswordPage } from 'pages/onBoarding/subPage/SetNewPassword';
import { ResetSuccessPage } from 'pages/onBoarding/subPage/ResetSuccess';
import { UserAuthContext } from 'contexts/UserAuthContext';
import { RegistrationProcessPage } from 'pages/onBoarding/subPage/RegistrationProcess';
import { LessonsShareWithPublicPage } from 'pages/lessons/LessonsShareWithPublicPage';
import { ProtectedRoute } from './ProtectedRoute';
import { UserPermissionContext } from '../contexts/UserPermissionContext';
import { PlanConfigModel } from 'models/planConfig/PlanConfigModel';
import { ConfirmLessonShare } from 'pages/lessons/ConfirmLessonShare';
import OverallResultPage from 'pages/results/subPage/OverallResultPage/OverallResultPage';
import { QuestionResultPage } from 'pages/results/subPage/QuestionResultPage/QuestionResultPage';
import QuestionResultByEachStudent from 'pages/results/subPage/QuestionResultByEachStudent/QuestionResultByEachStudent';
import OverallClassResultPage from 'pages/results/subPage/OverallClassResultPage/OverallClassResultPage';
import { RedirectPlaylist } from 'RedirectPlaylist';
import PrintResultPage from 'pages/results/subPage/PrintResultPage/PrintResultPage';

type Props = {
    isUserLoggedIn: boolean;
};

// Access the landing page URL from the configuration or environment variable
const landingPageURL = process.env.REACT_APP_LANDING_PAGE_URL ? process.env.REACT_APP_LANDING_PAGE_URL : window.location.origin + '/on-boarding/sign-in';

// Define props for ExternalRedirect
interface ExternalRedirectProps {
    to: string;
}

// Custom Redirect Component for external URLs
const ExternalRedirect: React.FC<ExternalRedirectProps> = ({ to }) => {
    useEffect(() => {
        window.location.href = to;
    }, [to]);

    return null; // Render nothing
};

export const createMainRouter = (isUserLoggedIn: boolean, userPermissions: PlanConfigModel) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { selectedSchool } = useContext(UserAuthContext);

    const url = new URL(window.location.href);
    const rootPath = url.pathname + url.search;
    const params = url.searchParams;
    const path = encodeURIComponent(rootPath);
    const fallbackPath = params.get('fallback');
    const signupParam = params.get('signup');

    const loginGuard = (element: JSX.Element) => {
        return isUserLoggedIn ? element : <Navigate to={`/${OnBoardingPath}/${signupParam && signupParam === '1' ? SignUpPath : SignInPath}?fallback=${path}`} />;
    };

    return createBrowserRouter([
        {
            element: <RootPage />,
            path: '/',
            children: [
                {
                    element: isUserLoggedIn ? (
                        <HomePage />
                    ) : (
                        <ExternalRedirect to={landingPageURL} />

                        // <Navigate  to={`${LandingPath}`} />
                    ),
                    path: '/',
                },
                {
                    element: <SupportPage />,
                    path: `${SupportPath}`,
                },
                {
                    element: loginGuard(<CalendarPage />),
                    path: `${CalendarPath}`,
                },
                {
                    element: loginGuard(<LessonsPage />),
                    path: `${LessonsPath}`,
                },
                {
                    element: loginGuard(
                        <LessonsSlideContextProvider>
                            <LessonsAddPage />
                        </LessonsSlideContextProvider>,
                    ),
                    path: `${LessonsPath}/:id`,
                },
                {
                    element: loginGuard(
                        <ProtectedRoute
                            hasAccess={() => {
                                // console.log(
                                //   'userPermissions?.enableWorksheetFunction',
                                //   userPermissions,
                                // );
                                return userPermissions?.enableWorksheetFunction;
                            }}
                            render={() => <WorksheetsPage />}
                            path={`${WorksheetsPath}`}
                        />,
                    ),
                    path: `${WorksheetsPath}`,
                },
                {
                    element: loginGuard(
                        <WorksheetContextProvider>
                            <WorksheetsAddPage />,
                        </WorksheetContextProvider>,
                    ),
                    path: `${WorksheetsPath}/:id`,
                },
                {
                    element: loginGuard(<LessonsShareWithPublicPage />),
                    path: `${LessonsShareWithPublicPath}`,
                },
                {
                    element: loginGuard(<ClassesPage />),
                    path: `${ClassesPath}`,
                },
                {
                    element: loginGuard(<StudentsPage />),
                    path: `${StudentsPath}`,
                },
                {
                    element: loginGuard(<StudentsPage />),
                    path: `${StudentsPath}/:classId`,
                },
                {
                    element: loginGuard(<HomeworkPage />),
                    path: `${HomeworkPath}`,
                },
                {
                    element: loginGuard(<ResultsPage />),
                    path: `${ResultPath}`,
                    children: [
                        {
                            index: true,
                            element: <Navigate to={`${SelectClassPath}/${selectedSchool?.id}`} />,
                        },
                        {
                            element: <SelectClassPage />,
                            path: `${SelectClassPath}/:schoolId`,
                        },
                        {
                            element: <SelectTestroomPage />,
                            path: `${SelectClassPath}/:schoolId/${SelectTestroomPath}/:classId/:mode`,
                        },
                        {
                            element: <OverallResultPage />,
                            path: `${SelectClassPath}/:schoolId/${SelectTestroomPath}/:classId/:mode/${OverallResultPath}/:testroomId/`,
                        },
                        {
                            element: <OverallClassResultPage />,
                            path: `${SelectClassPath}/:schoolId/${SelectTestroomPath}/:classId/${ClassOverallResultPath}/`,
                        },
                        {
                            element: <QuestionResultPage />,
                            path: `${SelectClassPath}/:schoolId/${SelectTestroomPath}/:classId/:mode/${SelectQuestionPath}/:testroomId/`,
                        },
                        {
                            element: <QuestionResultByEachStudent />,
                            path: `${SelectClassPath}/:schoolId/${SelectTestroomPath}/:classId/:mode/${SelectQuestionPath}/:testroomId/${SelectQuestionId}/:questionId/`,
                        },
                        {
                            element: <SelectStudentPage />,
                            path: `${SelectClassPath}/:schoolId/${SelectTestroomPath}/:classId/:mode/${SelectStudentPath}/:testroomId/`,
                        },
                        {
                            element: <SelectQuestionPage />,
                            path: `${SelectClassPath}/:schoolId/${SelectTestroomPath}/:classId/:mode/${SelectStudentPath}/:testroomId/${SelectQuestionPath}/:studentId`,
                        },
                        {
                            element: <GradingPage />,
                            path: `${SelectClassPath}/:schoolId/${SelectTestroomPath}/:classId/:mode/${SelectStudentPath}/:testroomId/${SelectQuestionPath}/:studentId/${GradingPath}/:questionIndex`,
                        },
                        {
                            element: <PrintResultPage />,
                            path: `${SelectClassPath}/:schoolId/${SelectTestroomPath}/:classId/:mode/${SelectStudentPath}/:testroomId/${SelectQuestionPath}/:studentId/${GradingPath}/PrintResult`,
                        },
                    ],
                },
                {
                    element: loginGuard(<ProfilePage onSubmit={() => {}} isOpened={false} initValues={null} resetEmailExists={() => {}} />),
                    path: `${ProfilePath}`,
                },
            ],
        },
        // {
        //   element: isUserLoggedIn ? <Navigate to='/' /> : <OnBoardingPage />,
        //   path: `${AuthPath}`,
        // },
        {
            // element: <LandingPage />,
            element: <LandingPageWebFlow />,

            path: `${LandingPath}`,
        },
        {
            element: <LandingPageWebFlowEmbed />,
            path: `${LandingPathEmbed}/:locale`,
        },
        {
            element: isUserLoggedIn ? <Navigate to={`${fallbackPath ? fallbackPath : '/'}`} /> : <OnBoardingPage />,
            path: `${OnBoardingPath}`,
            children: [
                {
                    index: true,
                    element: <Navigate to={SignInPath} />,
                },
                {
                    element: <SignInPage />,
                    path: `${SignInPath}`,
                },
                ...(process.env.REACT_APP_SIGN_UP_ENABLED == 'false'
                    ? []
                    : [
                          {
                              element: <SignUpPage />,
                              path: `${SignUpPath}`,
                          },
                      ]),
                {
                    element: <EmailVerificationPage />,
                    path: `${EmailVerificationPath}`,
                },
                {
                    element: <RegistrationProcessPage />,
                    path: `${RegistrationProcessPath}`,
                },
                {
                    element: <RegisterSuccessPage />,
                    path: `${RegisterSuccessPath}`,
                },
                {
                    element: <ForgotPasswordPage />,
                    path: `${ForgotPasswordPath}`,
                },
                {
                    element: <ResetVerificationPage />,
                    path: `${ResetVerificationPath}`,
                },
                {
                    element: <SetNewPasswordPage />,
                    path: `${SetNewPasswordPath}`,
                },
                {
                    element: <ResetVerificationPage />,
                    path: `${ResetVerificationPath}`,
                },
                {
                    element: <ResetSuccessPage />,
                    path: `${ResetSuccessPath}`,
                },
            ],
        },
        {
            element: <TermsConditionsContent />,
            path: `${TermsConditionsPath}`,
        },
        {
            element: <PrivacyPolicyContent />,
            path: `${PrivacyPolicyPath}`,
        },
        {
            element: <NotFoundPage />,
            path: '*',
        },
        {
            element: loginGuard(
                <LessonsSlideContextProvider>
                    <PreviewPage />
                </LessonsSlideContextProvider>,
            ),
            path: `${PreviewPath}/:id`,
        },
        {
            element: loginGuard(<ConfirmLessonShare />),
            path: `${ConfirmLessonSharePath}`,
        },
        {
            element: loginGuard(
                <LessonsSlideContextProvider>
                    <TestroomPage />
                </LessonsSlideContextProvider>,
            ),
            path: `${TestroomPath}/:otp`,
        },
        {
            element: <RedirectPlaylist />,
            path: `${RedirectPlaylistPath}`,
        },
    ]);
};

export const MainRoutes = (props: Props) => {
    const { isUserLoggedIn } = props;
    const { userPermissions } = useContext(UserPermissionContext);
    // console.log('MainRoutes userPermissions', userPermissions);
    const router = useMemo(() => createMainRouter(isUserLoggedIn, userPermissions), [isUserLoggedIn, userPermissions]);

    return <RouterProvider router={router} />;
};
