import { Box, Typography } from '@mui/material';
import moment from 'moment';
import FeedbackIcon from 'pages/results/component/CorrectTopRightIcon/FeedbackIcon';
import { HkcsQ1QuestionImages } from 'pages/results/hook/useGetHkcsImagesHook';
import { AnswerItemProp, Results } from 'pages/results/models';
import { useTranslation } from 'react-i18next';
import { colors } from 'styles/Colors';

export const HkcsQ1AnswerItem = (props: AnswerItemProp) => {
    const { t } = useTranslation();
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '72px',
            }}>
            {props?.answerCardData?.results?.map((item: Results, index: number) => {
                const selectedcs1 = props?.selectedTestroom?.lessonVersion.lessonQuestions.filter((lessonObj) => lessonObj.id === props?.answerCardData.questionID);
                if (selectedcs1 === undefined) {
                    return <>no question found</>;
                }
                const imagesArray: HkcsQ1QuestionImages[] = (selectedcs1[0]?.content?.questions as HkcsQ1QuestionImages[]) ?? [];
                if (imagesArray.length === 0) {
                    return <></>;
                }
                const selectIdx = JSON.parse(item.data).hkcsQ1.selectedIndex;
                return (
                    <Box
                        sx={{
                            padding: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '16px',
                        }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '16px',
                                paddingLeft: '32px',
                                position: 'relative',
                            }}>
                            <FeedbackIcon isCorrect={true} top={-15} right={-45} />

                            <Typography
                                sx={{
                                    fontWeight: 400,
                                    fontSize: '16px',
                                    color: colors.yellow900,
                                    lineHeight: '150%',
                                }}>
                                {t('answerTry', {
                                    num: (index + 1).toString(),
                                })}
                            </Typography>
                            {imagesArray.length - 1 > selectIdx && selectIdx >= 0 && (
                                <Box
                                    component='img'
                                    src={
                                        imagesArray[selectIdx]?.imageURL && imagesArray[selectIdx]?.imageURL !== ''
                                            ? imagesArray[selectIdx]?.imageURL
                                            : `/characters/${imagesArray[selectIdx]?.image !== '' ? imagesArray[selectIdx]?.image + '/image.png' : 'default.png'}`
                                    }
                                    alt='bg'
                                    sx={{
                                        width: '240px', // width in pixels
                                        height: '160px', // height in pixels
                                        flexShrink: 0,
                                        backgrosund: '#FFF',
                                        objectFit: 'contain', // maintain original ratio
                                    }}
                                />
                            )}
                            <Typography
                                sx={{
                                    fontWeight: 400,
                                    fontSize: '16px',
                                    color: colors.yellow900,
                                }}>
                                {t('answerTime')}: {moment.utc(moment(item?.endTime).diff(moment(item?.startTime))).format('mm:ss')}
                            </Typography>
                        </Box>
                    </Box>
                );
            })}
        </Box>
    );
};
