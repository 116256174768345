import { Stack } from '@mui/material';
import { ResultTable, ResultTableProps } from 'pages/results/component/ResultTable/ResultTable';
import moment from 'moment';
import { ResultsPageContext, StudentProfileWithResults, WritingResult } from 'pages/results/models';
import { GameType, gameTypeStr, getStringSortDirection, getStringDotSplitSortDirection, grade, gradeVariants, gradingTypeStr } from 'pages/results/utils';
import { LessonQuestion } from 'pages/testroom/models/TestroomModel';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { GradingPath, SelectClassPath, SelectQuestionPath, SelectStudentPath, SelectTestroomPath } from 'routes/utils';
import { Circle } from '@mui/icons-material';
import styled from '@emotion/styled';

export const SelectQuestionPage = () => {
    const navigator = useNavigate();
    const { schoolId, classId, testroomId, studentId, mode } = useParams();
    const { selectedTestRoom, selectedStudent, setSelectedQuestion } = useOutletContext<ResultsPageContext>();
    const { t } = useTranslation();
    const [questionTableData, setQuestionTableData] = useState<ResultTableProps>();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const prepareQuestionTable = (lessonQuestions: LessonQuestion[], student: StudentProfileWithResults) => {
            setIsLoading(false);
            setQuestionTableData({
                data: lessonQuestions?.map((question: LessonQuestion, index) => {
                    const answers = student?.results?.filter((result) => result.componentId === question.id);
                    let questionName;
                    if (isNullOrEmpty(question?.content?.phrase)) {
                        questionName =
                            typeof question?.content?.character === 'number'
                                ? question?.content?.characterList[question?.content?.character].character
                                : question?.content?.character?.split('/')[1].replace(/[0-9]/g, '');
                    } else {
                        questionName = question?.content?.phrase;
                    }
                    if (question.type === 'multipleChoice') {
                        questionName = t('hksas.mctitle');
                    } else if (question.type === 'sasAudio') {
                        questionName = t('hksas.audio.title');
                    } else if (question.type === 'sasFillInTheBlanks') {
                        questionName = t('hksas.fillInTheBlanks.title');
                    } else if (question.type === 'sasFillInTheBlanksWriting') {
                        questionName = t('hksas.fillInTheBlanksWriting.title');
                    }
                    if (answers) {
                        const answersTimeSec = answers?.reduce((prevValue, answer) => prevValue + moment(answer?.endTime).diff(moment(answer?.startTime)), 0);
                        const score = grade(answers) === 'correct' ? 1 : 0;
                        const isAudioAndNotGraded = (question?.content?.gameType as GameType) === 'Audio' && !answers.some((item) => item.grading !== 'ungraded');
                        const isSasAudioAndNotGraded = (question?.content?.gameType as GameType) === 'sasAudio' && !answers.some((item) => item.grading !== 'ungraded');
                        let isNotWritingNormalVersionAndNotGraded = false;
                        if ((question?.content?.gameType as GameType) === 'Writing') {
                            const isNotGraded = !answers.some((item) => item.grading !== 'ungraded');
                            let isNotNormalMode = false;
                            if (answers.length > 0) {
                                if (!(JSON.parse(answers[0].data) as WritingResult)?.writing.normalMode) {
                                    isNotNormalMode = true;
                                }
                            }

                            isNotWritingNormalVersionAndNotGraded = isNotGraded && isNotNormalMode;
                        }
                        let questionType = gameTypeStr(question?.content?.gameType as GameType) ?? gameTypeStr(question.type as GameType);
                        const isMCAndNotGraded = question.type === 'multipleChoice' && !answers.some((item) => item.grading !== 'ungraded');
                        return {
                            question: `${index + 1}. ${questionName}`,
                            phrase: question?.content?.phrase,
                            questionType,
                            answerTime: moment.utc(answersTimeSec).format('mm:ss'),
                            score,
                            trueOrFalse:
                                isSasAudioAndNotGraded || isNotWritingNormalVersionAndNotGraded || isAudioAndNotGraded || isMCAndNotGraded
                                    ? gradingTypeStr('ungraded')
                                    : gradingTypeStr(grade(answers)),
                            id: question.id,
                            answers: answers,
                            customCellVariants:
                                isSasAudioAndNotGraded || isNotWritingNormalVersionAndNotGraded || isAudioAndNotGraded || isMCAndNotGraded ? gradeVariants('ungraded') : gradeVariants(grade(answers)),
                            onClick: async () => {
                                setSelectedQuestion(index);
                                navigator(
                                    `../${SelectClassPath}/${schoolId}/${SelectTestroomPath}/${classId}/${
                                        mode === 'Homework' ? 'Homework' : 'Classroom'
                                    }/${SelectStudentPath}/${testroomId}/${SelectQuestionPath}/${studentId}/${GradingPath}/${index}`,
                                );
                            },
                        };
                    } else {
                        return {
                            question: `${index + 1}. ${questionName}`,
                            phrase: question?.content?.phrase,
                            questionType: gameTypeStr(question?.content?.gameType as GameType),
                            answerTime: 'N/A',
                            score: 'N/A',
                            trueOrFalse: 'N/A',
                            id: question.id,
                            answers: answers,
                        };
                    }
                }),
                columns: [
                    {
                        name: 'question',
                        nameStr: t('question'),
                        sortCallback: () => {
                            setQuestionTableData((prevState) => {
                                if (prevState?.data) {
                                    const cloneState = [...prevState?.data];
                                    const sortDirection = getStringDotSplitSortDirection(cloneState, 'question');
                                    if (sortDirection === 'unsorted') {
                                        console.error('Error: Invalid question format');
                                        return prevState;
                                    }
                                    const sortFunction = (a: any, b: any) => {
                                        const regex = /^(\d+)\./;
                                        const matchA = a.question.match(regex);
                                        const matchB = b.question.match(regex);
                                        if (!matchA || !matchB) {
                                            console.error('Error: Invalid question format');
                                            return 0;
                                        }
                                        const A = Number(matchA[1]);
                                        const B = Number(matchB[1]);
                                        if (isNaN(A) || isNaN(B)) {
                                            console.error('Error: Invalid number format');
                                            return 0;
                                        }
                                        return sortDirection === 'ascending' ? B - A : A - B;
                                    };
                                    cloneState.sort(sortFunction);
                                    return {
                                        ...prevState,
                                        data: cloneState,
                                    };
                                } else {
                                    return prevState;
                                }
                            });
                        },
                    },
                    {
                        name: 'questionType',
                        nameStr: t('type'),
                        sortCallback: () => {
                            setQuestionTableData((prevState) => {
                                if (prevState?.data) {
                                    const cloneState = [...prevState?.data];
                                    const sortDirection = getStringSortDirection(cloneState, 'questionType');
                                    if (sortDirection === 'ascending') {
                                        cloneState.sort((a, b) => b?.questionType?.localeCompare(a?.questionType));
                                    } else {
                                        cloneState.sort((a, b) => a?.questionType?.localeCompare(b?.questionType));
                                    }
                                    return {
                                        ...prevState,
                                        data: cloneState,
                                    };
                                } else {
                                    return prevState;
                                }
                            });
                        },
                    },
                    {
                        name: 'phrase',
                        nameStr: t('resultPage.phrase'),
                        sortCallback: () => {
                            setQuestionTableData((prevState) => {
                                if (prevState?.data) {
                                    const cloneState = [...prevState?.data];
                                    const sortDirection = getStringSortDirection(cloneState, 'phrase');
                                    console.log(cloneState);

                                    if (sortDirection === 'ascending') {
                                        cloneState.sort((a, b) => b?.questionType?.localeCompare(a?.questionType));
                                    } else {
                                        cloneState.sort((a, b) => a?.questionType?.localeCompare(b?.questionType));
                                    }
                                    return {
                                        ...prevState,
                                        data: cloneState,
                                    };
                                } else {
                                    return prevState;
                                }
                            });
                        },
                    },
                    {
                        name: 'answerTime',
                        nameStr: t('answerTime'),
                        sortCallback: () => {
                            setQuestionTableData((prevState) => {
                                if (prevState?.data) {
                                    const cloneState = [...prevState?.data];
                                    const sortDirection = getStringSortDirection(cloneState, 'answerTime');
                                    if (sortDirection === 'ascending') {
                                        cloneState.sort((a, b) => b?.answerTime?.localeCompare(a?.answerTime));
                                    } else {
                                        cloneState.sort((a, b) => a?.answerTime?.localeCompare(b?.answerTime));
                                    }
                                    return {
                                        ...prevState,
                                        data: cloneState,
                                    };
                                } else {
                                    return prevState;
                                }
                            });
                        },
                    },
                    {
                        name: 'score',
                        nameStr: t('score'),
                        sortCallback: () => {
                            setQuestionTableData((prevState) => {
                                if (prevState?.data) {
                                    const cloneState = [...prevState?.data];
                                    const sortDirection = getStringSortDirection(cloneState, 'score');
                                    if (sortDirection === 'ascending') {
                                        cloneState.sort((a, b) => b?.score?.localeCompare(a?.score));
                                    } else {
                                        cloneState.sort((a, b) => a?.score?.localeCompare(b?.score));
                                    }
                                    return {
                                        ...prevState,
                                        data: cloneState,
                                    };
                                } else {
                                    return prevState;
                                }
                            });
                        },
                    },
                    {
                        name: 'trueOrFalse',
                        nameStr: t('trueOrFalse'),
                        customCell: ({ variants, children }: { variants?: string; children?: ReactNode }) => (
                            <DotContainer>
                                <Circle
                                    sx={{
                                        color: variants,
                                        width: '12px',
                                        height: '12px',
                                    }}
                                    stroke={'#533D18'}
                                    stroke-width={4}
                                />
                                {children}
                            </DotContainer>
                        ),
                        sortCallback: () => {
                            setQuestionTableData((prevState) => {
                                if (prevState?.data) {
                                    const cloneState = [...prevState?.data];
                                    const sortDirection = getStringSortDirection(cloneState, 'trueOrFalse');
                                    if (sortDirection === 'ascending') {
                                        cloneState.sort((a, b) => b?.trueOrFalse?.localeCompare(a?.trueOrFalse));
                                    } else {
                                        cloneState.sort((a, b) => a?.trueOrFalse?.localeCompare(b?.trueOrFalse));
                                    }
                                    return {
                                        ...prevState,
                                        data: cloneState,
                                    };
                                } else {
                                    return prevState;
                                }
                            });
                        },
                    },
                ],
            });
        };

        if (selectedTestRoom?.lessonVersion?.lessonQuestions?.length > 0 && selectedStudent?.results && selectedStudent?.results?.length > 0) {
            prepareQuestionTable(selectedTestRoom.lessonVersion.lessonQuestions, selectedStudent);
        }
    }, [selectedTestRoom, selectedStudent, t, setSelectedQuestion, navigator, schoolId, classId, mode, testroomId, studentId]);

    return (
        <Stack direction='column' spacing={1} className='pageCustom'>
            <ResultTable columns={questionTableData?.columns} data={questionTableData?.data} isLoading={isLoading} />
        </Stack>
    );
};

export const DotContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
`;
function isNullOrEmpty(str: string | undefined): boolean {
    if (str === undefined) {
        return true;
    }
    if (str.trim() === '') {
        return true;
    }
    return false;
}
