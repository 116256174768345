import { Box, Typography } from '@mui/material';
import moment from 'moment';
import FeedbackIcon from 'pages/results/component/CorrectTopRightIcon/FeedbackIcon';
import HKcsQ2Textbubbles from 'pages/results/component/HkcsQ2/HKcsQ2Textbubbles';
import { AnswerItemProp, HkcsQ2Result, Results } from 'pages/results/models';
import { useTranslation } from 'react-i18next';
import { colors } from 'styles/Colors';

export const HkcsQ2AnswerItem = (props: AnswerItemProp) => {
    const { t } = useTranslation();
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '72px',
            }}>
            {props?.answerCardData?.results?.map((item: Results, index: number) => {
                const hkcsQ2ResultObj: HkcsQ2Result = JSON.parse(item.data) as HkcsQ2Result;
                console.log(hkcsQ2ResultObj);
                return (
                    <Box
                        sx={{
                            padding: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '16px',
                        }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '16px',
                                paddingLeft: '32px',
                                position: 'relative',
                            }}>
                            <FeedbackIcon isCorrect={true} top={-15} right={-45} />
                            <Typography
                                sx={{
                                    fontWeight: 400,
                                    fontSize: '16px',
                                    color: colors.yellow900,
                                    lineHeight: '150%',
                                }}>
                                {t('answerTry', {
                                    num: (index + 1).toString(),
                                })}
                            </Typography>
                            <Box
                                component='img'
                                src={item.files.length > 0 ? item.files[0].file.url : ''}
                                alt='Example Image'
                                sx={{
                                    width: 288, // width in pixels
                                    height: 177, // height in pixels
                                    flexShrink: 0,
                                    borderRadius: 12.5 / 2, // border radius in pixels
                                    border: '1.5px solid #000', // border style
                                    background: '#FFF',
                                }}
                            />
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '16px',
                                }}>
                                <HKcsQ2Textbubbles sentence={hkcsQ2ResultObj.hkcsQ2.textContent} chosenWord={hkcsQ2ResultObj.hkcsQ2.answer} />
                            </Box>
                            <Typography
                                sx={{
                                    fontWeight: 400,
                                    fontSize: '16px',
                                    color: colors.yellow900,
                                }}>
                                {t('answerTime')}: {moment.utc(moment(item?.endTime).diff(moment(item?.startTime))).format('mm:ss')}
                            </Typography>
                        </Box>
                    </Box>
                );
            })}
        </Box>
    );
};
