import { useFormik } from 'formik';
import { t } from 'i18next';
import { ClassForOptionModel } from 'models/class/ClassModel';
import { useClassesForOptionQuery } from 'models/class/useClassesForOptionQuery';
import { ERROR_TYPE, TTError } from 'models/ErrorHandling';
import { HomeworkModel } from 'models/homework/HomeworkModel';
import { useCreateHomeworkMutation } from 'models/homework/useCreateHomeworkMutation';
import { useLessonPublishMutation } from 'models/lesson/useLessonPublishMutation';
import moment from 'moment';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

export default function usePublishHomeworkHook(cb: (data: HomeworkModel, action: 'published') => void) {
    const { runMutation: createHomeworkMutation } = useCreateHomeworkMutation();

    const { runMutation: publishLessonMutation } = useLessonPublishMutation();

    const { runQuery } = useClassesForOptionQuery();
    const [classesData, setClassesData] = useState<ClassForOptionModel[]>([]);

    const validationSchema = Yup.object().shape({
        name: Yup.string().min(0, t('text-limit')).max(50, t('text-limit')),
        lessonId: Yup.string().required(t('required')),
        class: Yup.array().min(1, t('required')),
        startTime: Yup.date().nullable().required(t('required')),
        endTime: Yup.date().nullable().required(t('required')),
    });
    const formik = useFormik({
        initialValues: {
            name: '',
            lessonId: '',
            lessonName: '',
            class: [] as ClassForOptionModel[],
            writingHints: false,
            freeStyleMode: false,
            startTime: null as Date | null,
            endTime: null as Date | null,
        },
        validationSchema,
        onSubmit: async (values) => {
            try {
                if (!values.lessonId || values.lessonId === '') {
                    throw new TTError(ERROR_TYPE.KS_VALIDATION_FAILURE);
                }
                const publishResult = await publishLessonMutation({
                    lessonID: values.lessonId,
                });

                if (!publishResult) {
                    throw new TTError(ERROR_TYPE.KS_VALIDATION_FAILURE);
                }

                // This can set the time to 00:00:00
                const startTime = moment(values.startTime);
                startTime.hours(0).minutes(0).seconds(0).milliseconds(0);

                // This can set the time to 00:00:00
                const endTime = moment(values.endTime);
                endTime.hours(0).minutes(0).seconds(0).milliseconds(0);

                const res = await createHomeworkMutation({
                    name: values.name,
                    lessonVersion: publishResult?.id,
                    class: { connect: values.class.map((c) => ({ id: c.id })) },
                    writingHints: values.writingHints,
                    freeStyleMode: values.freeStyleMode,
                    startTime: startTime.toDate(),
                    endTime: endTime.toDate(),
                });

                cb(res, 'published');
            } catch (err: any) {
                console.log(err);

                enqueueSnackbar(t(`error.${err.message}`), {
                    variant: 'error',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                });
            }
        },
    });

    useEffect(() => {
        if (!classesData || classesData.length !== 0) {
            return;
        }

        (async () => {
            try {
                const result = await runQuery({});
                setClassesData(result?.classes ?? []);
            } catch (err: any) {
                enqueueSnackbar(t(`error.${err.message}`), {
                    variant: 'error',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                });
            }
        })();
    }, [classesData, runQuery]);

    return {
        formik,
        classesQueryData: classesData,
    };
}
