import { gql } from '@apollo/client';

export const GET_RESULTS_BY_TESTROOM = gql`
    query GetResultsByTestroom($testroom: IDFilter) {
        results(where: { testroom: { id: $testroom } }) {
            id
            message
            data
            student {
                id
                givenName
                familyName
            }
            startTime
            endTime
            componentId
            correct
            grading
        }
    }
`;

export const UPDATE_RESULTS = gql`
    mutation UpdateResults($data: [ResultUpdateArgs!]!) {
        updateResults(data: $data) {
            id
            grading
            data
        }
    }
`;
