import { useParams } from 'react-router-dom';

import { StudentId, useGetClassStudentIds } from 'models/resultPage/getClassStudentIds';
import { useTestroomAnswerStatusByType } from 'models/resultPage/useTestroomAnswerStatusByType';
import { useGetQuestionTypeCountHook } from './useGetQuestionTypeCountHook';

// export type StudentProfileWithResults = StudentProfile & TestroomResults;

export const useGetAnswerCountByType = () => {
    const { classId } = useParams();
    const { getQuestionTypeCount } = useGetQuestionTypeCountHook();
    const { getStudentIds } = useGetClassStudentIds();
    const { getCorrectAttemptsByType } = useTestroomAnswerStatusByType();
    const getAnswerTypeCount = async () => {
        try {
            const studentData = await getStudentIds({
                classId: classId ?? '',
            });
            const studentIds = studentData?.class?.students ?? [];
            const resultByType = getOverallAnswerStatus(studentIds);
            return resultByType;
        } catch (error: any) {
            console.log(error);
            console.log(error?.message);
        }
    };
    const getOverallAnswerStatus = async (studentIds: StudentId[]) => {
        try {
            const results = [];
            //get all question in testroom
            const questionTypeTotalCount = await getQuestionTypeCount();
            let totalQuestionCount: any = {};

            for (const studentData of studentIds) {
                const testRoomStudentParticpated: string[] = [];
                const studentCorrectAttemptsByType = await getCorrectAttemptsByType({
                    classId: classId ?? '',
                    studentId: studentData.id,
                });

                if (studentCorrectAttemptsByType?.getGroupedQuestionTypeResult?.correctResultTypeCount) {
                    //get total question student attempted
                    studentCorrectAttemptsByType?.getGroupedQuestionTypeResult?.correctResultTypeCount.map((res: any) => {
                        //check testRoom counted
                        if (!testRoomStudentParticpated.includes(res.testroomId)) {
                            testRoomStudentParticpated.push(res.testroomId);
                            if (questionTypeTotalCount) {
                                const testRoomQuestionCount = questionTypeTotalCount.filter((result) => result?.testroomId === res.testroomId.toString()).slice(0, 1);
                                //check insert testroom data
                                for (let key in testRoomQuestionCount[0]) {
                                    if (key.toString() !== 'testroomId') {
                                        if (!totalQuestionCount.hasOwnProperty(key)) {
                                            totalQuestionCount[key] = testRoomQuestionCount[0][key];
                                        } else {
                                            totalQuestionCount[key] += testRoomQuestionCount[0][key];
                                        }
                                    }
                                }
                            }
                        }
                    });
                    results.push(...studentCorrectAttemptsByType?.getGroupedQuestionTypeResult?.correctResultTypeCount);
                }
            }
            //   loop all the testroom to get correct count
            const correctAnswerCount: any = {};
            if (questionTypeTotalCount) {
                for (const testRoom of questionTypeTotalCount) {
                    for (let key in testRoom) {
                        if (key.toString() !== 'testroomId') {
                            const filterArray = results.filter((result) => result?.gameType === key.toString());
                            const correctAnswer = filterArray.reduce((accumulator, currentValue) => accumulator + currentValue.count, 0);
                            if (!correctAnswer.hasOwnProperty(key)) {
                                correctAnswerCount[key] = correctAnswer;
                            } else {
                                correctAnswerCount[key] += correctAnswer;
                            }
                        }
                    }
                }
            }
            return { correctAnswerCount, totalQuestionCount };
        } catch (error: any) {
            console.log(error);
            console.log(error?.message);
        }
    };
    return { getAnswerTypeCount };
};
