import { gql, useLazyQuery } from '@apollo/client';
import { UserAuthContext } from 'contexts/UserAuthContext';
import { ERROR_TYPE, handleException, TTError } from 'models/ErrorHandling';
import { Results } from 'models/resultPage/ResultPageModel';
import { useCallback, useContext } from 'react';
const GET_RESULTS_BY_HOMEWORK_AND_STUDENT = gql`
    query GetResultsByTestroomAndStudent($homeworkId: IDFilter, $studentId: IDFilter) {
        homeworkResults(where: { homework: { id: $homeworkId }, student: { id: $studentId } }, orderBy: [{ startTime: asc }]) {
            id
            message
            data
            student {
                id
                givenName
                familyName
            }
            files {
                file {
                    url
                }
            }
            startTime
            endTime
            componentId
            correct
            grading
        }
    }
`;

export type ModelQueryType = {
    homeworkResults: Results[];
};

type QueryVariableType = {
    homeworkId: { equals: string };
    studentId: { equals: string };
};

export const useHomeworkStudentResultQuery = () => {
    const auth = useContext(UserAuthContext);
    const [queryData, { data, loading }] = useLazyQuery<ModelQueryType>(GET_RESULTS_BY_HOMEWORK_AND_STUDENT);

    const getHomeworkStudentResultQuery = useCallback(
        async (variables: QueryVariableType): Promise<ModelQueryType | null> => {
            try {
                const result = await queryData({
                    variables,
                    fetchPolicy: 'no-cache',
                    notifyOnNetworkStatusChange: true,
                });

                if (!result?.data) {
                    throw new TTError(ERROR_TYPE.NO_DATA);
                }

                return result?.data;
            } catch (err) {
                const errorMsg = handleException(err);
                if (errorMsg === ERROR_TYPE.KS_ACCESS_DENIED) {
                    auth.accessDenied(errorMsg);
                }

                throw new TTError(errorMsg);
            }
        },
        [auth, queryData],
    );

    return { getHomeworkStudentResultQuery };
};
