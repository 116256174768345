import { Box, Typography } from '@mui/material';
import { EnglishWritingAnswer } from 'pages/results/EnglishWritingAnswer/EnglishWritingAnswer';
import moment from 'moment';
import { AnswerItemProp, Results, Strokes, WritingAnswer as WritingAnswerModel } from 'pages/results/models';
import { useTranslation } from 'react-i18next';
import { colors } from 'styles/Colors';

export const HkcsEnQ2AnswerItem = (props: AnswerItemProp) => {
    const { t } = useTranslation();

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '72px',
            }}>
            {props.answerCardData?.results.map((item: Results, index: number) => {
                let hkcsEnQ2ResultObj;
                try {
                    hkcsEnQ2ResultObj = JSON.parse(item.data);
                } catch (error) {
                    hkcsEnQ2ResultObj = {};
                }
                return (
                    <Box
                        sx={{
                            padding: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '16px',
                        }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '16px',
                                paddingLeft: '32px',
                            }}>
                            <Typography
                                sx={{
                                    fontWeight: 400,
                                    fontSize: '16px',
                                    color: colors.yellow900,
                                    lineHeight: '150%',
                                }}>
                                {t('answerTry', {
                                    num: (index + 1).toString(),
                                })}
                            </Typography>
                            <Box
                                component='img'
                                src={item.files.length > 0 ? item.files[0].file.url : ''}
                                alt='Example Image'
                                sx={{
                                    width: 288, // width in pixels
                                    height: 177, // height in pixels
                                    flexShrink: 0,
                                    borderRadius: 12.5 / 2, // border radius in pixels
                                    border: '1.5px solid #000', // border style
                                    background: '#FFF',
                                }}
                            />
                            <Typography
                                sx={{
                                    fontWeight: 400,
                                    fontSize: '16px',
                                    color: colors.yellow900,
                                    lineHeight: '150%',
                                }}>
                                {t('hkcs.en.q2.sampleSentence')}
                            </Typography>
                            <Typography
                                sx={{
                                    fontWeight: 400,
                                    fontSize: '16px',
                                    color: colors.yellow900,
                                    lineHeight: '150%',
                                }}>
                                {hkcsEnQ2ResultObj?.hkcsEnQ2?.sampleSentence ?? ''}
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '16px',
                                }}>
                                {Array.isArray(hkcsEnQ2ResultObj?.hkcsEnQ2?.sampleSentenceAnswer) &&
                                    hkcsEnQ2ResultObj?.hkcsEnQ2?.sampleSentenceAnswer.map((item: any, index: number) => {
                                        const writing: WritingAnswerModel = item;
                                        return (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    gap: '16px',
                                                }}>
                                                <Box
                                                    sx={{
                                                        border: '1px solid #856666',
                                                        borderRadius: '8px',
                                                    }}>
                                                    <EnglishWritingAnswer strokes={writing.strokes as Strokes[][]} writingVersion={writing.version} width={writing.width ?? 0} />
                                                </Box>
                                            </Box>
                                        );
                                    })}
                            </Box>
                            <Typography
                                sx={{
                                    fontWeight: 400,
                                    fontSize: '16px',
                                    color: colors.yellow900,
                                    lineHeight: '150%',
                                }}>
                                {hkcsEnQ2ResultObj?.hkcsEnQ2?.textContent ?? ''}
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '16px',
                                }}>
                                {Array.isArray(hkcsEnQ2ResultObj?.hkcsEnQ2?.answer) &&
                                    hkcsEnQ2ResultObj?.hkcsEnQ2?.answer.map((item: any, index: number) => {
                                        const writing: any = item;
                                        return (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    gap: '16px',
                                                }}>
                                                <Box
                                                    sx={{
                                                        border: '1px solid #856666',
                                                        borderRadius: '8px',
                                                    }}>
                                                    <EnglishWritingAnswer strokes={writing.strokes} writingVersion={writing.version} width={writing.width} />
                                                </Box>
                                            </Box>
                                        );
                                    })}
                            </Box>
                            <Typography
                                sx={{
                                    fontWeight: 400,
                                    fontSize: '16px',
                                    color: colors.yellow900,
                                }}>
                                {t('answerTime')}: {moment.utc(moment(item?.endTime).diff(moment(item?.startTime))).format('mm:ss')}
                            </Typography>
                        </Box>
                    </Box>
                );
            })}
        </Box>
    );
};
