import styled from '@emotion/styled';
import { CheckRounded, ClearRounded } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { EasyWritingAnswer } from 'pages/results/EasyWritingAnswer/EasyWritingAnswer';
import { WritingAnswer } from 'pages/results/component/WritingAnswer/WritingAnswer';
import moment from 'moment';
import { AnswerItemProp, HkcsQ3Result, Results, Strokes, WritingAnswer as WritingAnswerModel } from 'pages/results/models';
import { useTranslation } from 'react-i18next';
import { colors } from 'styles/Colors';
interface TopRightIconProps {
    isCorrect: boolean;
}

export const HkcsQ3AnswerItem = (props: AnswerItemProp) => {
    const { t } = useTranslation();

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '72px',
            }}>
            {props?.answerCardData?.results?.map((item: Results, index: number) => {
                const hkcsQ3ResultObj: HkcsQ3Result = JSON.parse(item.data);
                return (
                    <Box
                        sx={{
                            padding: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '16px',
                        }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '16px',
                                paddingLeft: '32px',
                            }}>
                            <Typography
                                sx={{
                                    fontWeight: 400,
                                    fontSize: '16px',
                                    color: colors.yellow900,
                                    lineHeight: '150%',
                                }}>
                                {t('answerTry', {
                                    num: (index + 1).toString(),
                                })}
                            </Typography>
                            <Box
                                component='img'
                                src={item.files.length > 0 ? item.files[0].file.url : ''}
                                alt='Example Image'
                                sx={{
                                    width: 288, // width in pixels
                                    height: 177, // height in pixels
                                    flexShrink: 0,
                                    borderRadius: 12.5 / 2, // border radius in pixels
                                    border: '1.5px solid #000', // border style
                                    background: '#FFF',
                                }}
                            />
                            <Typography
                                sx={{
                                    fontWeight: 400,
                                    fontSize: '16px',
                                    color: colors.yellow900,
                                    lineHeight: '150%',
                                }}>
                                {hkcsQ3ResultObj.hkcsQ3.textContent ?? ''}
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '16px',
                                }}>
                                {hkcsQ3ResultObj.hkcsQ3.answer.map((item: any, index: number) => {
                                    const writing: WritingAnswerModel = item;
                                    return (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: '16px',
                                            }}>
                                            <Box
                                                sx={{
                                                    border: '1px solid #856666',
                                                    borderRadius: '8px',
                                                    // ...(selectedAnswerIndex === '' + index && {
                                                    //   border: '6px solid #29C0E7',
                                                    // }),
                                                }}>
                                                {writing.normalMode ? (
                                                    <TopRightIconContainer>
                                                        <EasyWritingAnswer strokes={writing.strokes as Strokes[][][]} writingVersion={writing.version} />
                                                        <TopRightIcon isCorrect={props.answerCardData.correct}>
                                                            {props.answerCardData.correct ? (
                                                                <CheckRounded
                                                                    sx={{
                                                                        color: colors.reallyWhite,
                                                                        display: 'block',
                                                                        margin: 'auto',
                                                                    }}
                                                                />
                                                            ) : (
                                                                <ClearRounded
                                                                    sx={{
                                                                        color: colors.reallyWhite,
                                                                        display: 'block',
                                                                        margin: 'auto',
                                                                    }}
                                                                />
                                                            )}
                                                        </TopRightIcon>
                                                    </TopRightIconContainer>
                                                ) : (
                                                    <WritingAnswer strokes={writing.strokes as Strokes[][]} writingVersion={writing.version} />
                                                )}
                                            </Box>
                                        </Box>
                                    );
                                })}
                            </Box>

                            {/* <Dialog fullScreen open={true}>
      <Box
        sx={{
          '@media print': {
            width: '210mm',
            height: '297mm',
          },
          border: 1,
          width: '100%', // Add this
          height: '100%', // Add this
        }}></Box>
      <Button
        sx={{ position: 'absolute', top: 0 }}
        variant='contained'
        onClick={handlePrint}
        className='no-print'>
        Print
      </Button>
    </Dialog> */}
                            <Typography
                                sx={{
                                    fontWeight: 400,
                                    fontSize: '16px',
                                    color: colors.yellow900,
                                }}>
                                {t('answerTime')}: {moment.utc(moment(item?.endTime).diff(moment(item?.startTime))).format('mm:ss')}
                            </Typography>
                        </Box>
                    </Box>
                );
            })}
        </Box>
    );
};

const TopRightIconContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

const TopRightIcon = styled.div<TopRightIconProps>`
    display: flex;
    position: absolute;
    width: 32px;
    height: 32px;
    right: -15px;
    top: -15px;
    border-radius: 50%;
    background: ${(props) => (props.isCorrect ? colors.success400 : colors.error500)};
`;
