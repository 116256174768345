import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import { Button } from '@mui/material';
import { UserAuthContext } from 'contexts/UserAuthContext';
import { LessonModel, LessonVersionModel } from 'models/lesson/LessonModel';
import { useLessonsQuery } from 'models/lesson/useLessonsQuery';
import { useSnackbar } from 'notistack';
import { CalendarData, TimeSlotDialogState } from 'pages/calendar/types/timeSlotTypes';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import usePublishHomeworkHook from '../hooks/usePublishHomeworkHook';
import { LessonPicker } from './LessonPicker';
import { PublishHomeworkModal } from './PublishHomeworkModal';

type PublishHomeworkProps = {
    refresh: () => void;
};

export const PublishHomeworkButton = ({ refresh }: PublishHomeworkProps) => {
    const { t } = useTranslation();

    const { userData } = useContext(UserAuthContext);

    const { enqueueSnackbar } = useSnackbar();

    const navigate = useNavigate();

    const [isLessonPickerOpen, setIsLessonPickerOpen] = useState(false);
    const [lessons, setLessons] = useState<LessonModel[]>([]);

    const [currentCalendarState, setCurrentCalendarState] = useState<TimeSlotDialogState>({
        isDialogOpen: false,
        calendarData: {} as CalendarData,
    });

    const LessonsQuery = useLessonsQuery();

    const { formik, classesQueryData } = usePublishHomeworkHook(async (data) => {
        if (!data.id || data.id === '') {
            enqueueSnackbar(t('createFailed'), {
                variant: 'error',
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
            });
            return;
        }

        enqueueSnackbar(t('publishedMessage'), {
            variant: 'success',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });

        handleClose();

        refresh();
    });

    const handleClose = () => {
        setCurrentCalendarState({
            isDialogOpen: false,
            calendarData: {} as CalendarData,
        });
    };

    const handleOpen = () => {
        setCurrentCalendarState({
            isDialogOpen: true,
            calendarData: {} as CalendarData,
        });
        resetForm();
    };

    const resetForm = () => {
        formik.resetForm();
        formik.setFieldValue('offlineMode', false);
        formik.setFieldValue('writingHints', false);
        formik.setFieldValue('freeStyleMode', false);
    };

    const handleLessonPickerOpen = () => {
        setIsLessonPickerOpen(true);
        fetchLessons();
    };

    const fetchLessons = async () => {
        try {
            const data = await LessonsQuery.getMyLessons({ teacherID: userData?.id ?? '' });
            if (data) {
                for (let lessonItem of data.lessons) {
                    if (lessonItem.latestLessonDraft) {
                        lessonItem.latestLessonDraft.slides = lessonItem.latestLessonDraft?.slides.map((slideItem) => {
                            if (typeof slideItem.data === 'string') {
                                return {
                                    ...slideItem,
                                    data: JSON.parse(slideItem.data),
                                };
                            } else {
                                return { ...slideItem };
                            }
                        });
                    } else {
                        lessonItem.latestLessonDraft = {} as LessonVersionModel;
                    }
                }
                setLessons(data.lessons);
            }
        } catch (err: any) {
            enqueueSnackbar(t(`error.${err.message}`), {
                variant: 'error',
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            });
        }
    };

    const handleLessonPickerClick = (lesson: LessonModel) => {
        formik.setFieldValue('lessonId', lesson.id);
        formik.setFieldValue('lessonName', lesson.latestLessonDraft.lessonName);
    };

    return (
        <>
            <Button
                onClick={handleOpen}
                variant='contained'
                color='primary'
                sx={{
                    textTransform: 'none',
                    borderRadius: '50px',
                    mr: 1,
                    color: 'white',
                }}
                startIcon={<PublishedWithChangesIcon />}>
                {t('homework.publish.title')}
            </Button>

            <PublishHomeworkModal
                currentCalendarState={currentCalendarState}
                formik={formik}
                classesData={classesQueryData}
                handleClose={handleClose}
                handleLessonPickerOpen={handleLessonPickerOpen}
            />

            <LessonPicker isOpen={isLessonPickerOpen} onCloseHandler={() => setIsLessonPickerOpen(false)} onClickHandler={handleLessonPickerClick} lessonData={lessons} />
        </>
    );
};
