import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { CancelButton } from 'components/General/Buttons/CancelButton';
import { SubmitButton } from 'components/General/Buttons/SubmitButton';
import { PopupBody } from 'components/General/Popups/PopupBody';
import { PopupCircularProgress } from 'components/General/Popups/PopupCircularProgress';
import { PopupTitle } from 'components/General/Popups/PopupTitle';
import { FormikProps } from 'formik';
import { ClassForOptionModel } from 'models/class/ClassModel';
import { TimeSlotDialogState } from 'pages/calendar/types/timeSlotTypes';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type uiProps = {
    currentCalendarState: TimeSlotDialogState;
    formik: FormikProps<{
        name: string;
        otp: string;
        class: string;
        passlock: string;
        offlineMode: boolean;
        writingHints: boolean;
        freeStyleMode: boolean;
    }>;
    handleDelete?: (id: string) => Promise<void>;
    classesData?: ClassForOptionModel[];
    handleClose: () => void;
};

export const PublishModal = ({ currentCalendarState, formik, handleDelete, classesData: classesQueryData, handleClose }: uiProps) => {
    const { t } = useTranslation();
    const [deleteOpen, setDeleteOpen] = useState<boolean>(false);

    return (
        <>
            <Dialog open={currentCalendarState.isDialogOpen && !formik.isSubmitting}>
                <DialogTitle>
                    <PopupTitle>{t('new.publishLesson.title')}</PopupTitle>
                </DialogTitle>
                <form onSubmit={formik.handleSubmit}>
                    <DialogContent>
                        <Stack spacing={2} minWidth={480}>
                            {formik.values.otp !== '' && (
                                <Stack direction='row' spacing={1} alignItems='center' justifyContent='flex-start'>
                                    <PopupBody>{t('otp')}:</PopupBody>
                                    <PopupBody>
                                        {formik.values.otp}{' '}
                                        <Button variant='outlined' href={`/testroom/${formik.values.otp}`}>
                                            Enter Testroom
                                        </Button>
                                    </PopupBody>
                                </Stack>
                            )}
                            <TextField
                                id='name'
                                name='name'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.name}
                                label={t('name')}
                                fullWidth
                                variant='outlined'
                                error={formik.touched.name && !!formik.errors.name}
                                helperText={formik.touched.name ? formik.errors.name : ''}
                            />

                            <FormControl fullWidth error={formik.touched.class && !!formik.errors.class}>
                                <InputLabel id='select-class-label'>{t('class')}</InputLabel>
                                <Select
                                    labelId='select-class-label'
                                    id='select-class'
                                    value={formik.values.class}
                                    label={t('class')}
                                    onChange={(e) => {
                                        formik.setFieldValue('class', e.target.value);
                                    }}
                                    onBlur={formik.handleBlur}>
                                    {classesQueryData?.map((option) => (
                                        <MenuItem value={option.id} key={option.id}>
                                            {option.school.schoolName} {option.className}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>{formik.touched.class ? formik.errors.class : ''}</FormHelperText>
                            </FormControl>

                            <TextField
                                id='passlock'
                                type='number'
                                name='passlock'
                                onChange={(event) => {
                                    formik.setFieldValue('passlock', `${event.target.value}`);
                                }}
                                onBlur={(event) => {
                                    formik.setFieldValue('passlock', `${event.target.value}`);
                                }}
                                value={formik.values.passlock}
                                label={t('passlock')}
                                fullWidth
                                variant='outlined'
                                error={!!formik.errors.passlock}
                                helperText={formik.errors.passlock}
                            />
                        </Stack>
                    </DialogContent>
                    <DialogActions sx={{ display: 'flex' }}>
                        {currentCalendarState.calendarData?.data?.id && (
                            <CancelButton onClick={() => setDeleteOpen(true)} disabled={formik.isSubmitting} color='error' variant='outlined' sx={{ mr: 'auto' }}>
                                {t('delete')}
                            </CancelButton>
                        )}

                        <CancelButton onClick={handleClose} disabled={formik.isSubmitting}>
                            {t('cancel')}
                        </CancelButton>

                        <SubmitButton type='submit' loading={formik.isSubmitting}>
                            {t('new.publishLesson.enterClassroom')}
                        </SubmitButton>
                    </DialogActions>
                </form>
            </Dialog>

            <Dialog open={deleteOpen} onClose={() => setDeleteOpen(false)} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
                <DialogTitle id='alert-dialog-title'>{t('confirm-delete')}</DialogTitle>
                <DialogContent sx={{ width: 400 }}>
                    <DialogContentText id='alert-dialog-description'>{t('confirm-delete-description')}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteOpen(false)}>{t('cancel')}</Button>
                    {handleDelete && (
                        <Button
                            onClick={() => {
                                setDeleteOpen(false);
                                handleDelete?.(currentCalendarState.calendarData?.data?.id);
                            }}
                            autoFocus>
                            {t('delete')}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
            <PopupCircularProgress isOpened={formik.isSubmitting} />
        </>
    );
};
