import { Dialog, DialogActions, DialogTitle, Box, Button, Select, SelectChangeEvent, MenuItem, Typography } from '@mui/material';
import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import { UserAuthContext } from '../../contexts/UserAuthContext';
import { DialogProps } from '@mui/material/Dialog';
import { useStudentAddFromCsv } from 'hooks/useStudentAddFromCsv';
import { CancelButton } from 'components/General/Buttons/CancelButton';
import CsvDownloadComponent from './StudentSampleCSVDownload';
import { UpgradePlanPopup } from '../../routes/UpgradePlanPopup';

export const StudentsAddFromCSV = ({ refresh }: { refresh: () => void }) => {
    const auth = useContext(UserAuthContext);
    const { t } = useTranslation();
    const [showUpgradePopup, setShowUpgradePopup] = useState(false);
    const handleStudentLimitExceeded = () => {
        setShowUpgradePopup(true); // This will trigger the popup display
    };

    const { errorMsg, allClassIDs, classID, handleChosenIDChange, open, handleClose, handleClickOpen, handleFileUpload, uploadingFile, SetSubmittedFile, finishUploading, setFinishLoading } =
        useStudentAddFromCsv({
            refresh,
            onStudentLimitExceeded: handleStudentLimitExceeded,
        });
    const [maxWidth, setMaxWidth] = useState<DialogProps['maxWidth']>('xs');
    const [fullWidth, setFullWidth] = useState(true);
    return (
        <>
            <Box>
                <Button
                    variant='contained'
                    color='secondary'
                    sx={{
                        textTransform: 'none',
                        borderRadius: '8px',
                    }}
                    onClick={handleClickOpen}
                    component='label'
                    startIcon={<AddIcon />}>
                    {t('import-csv-student-button-title')}
                </Button>

                {showUpgradePopup && <UpgradePlanPopup open={showUpgradePopup} onClose={() => setShowUpgradePopup(false)} reason='studentLimitExceeded' />}
            </Box>
            <Dialog open={open} onClose={handleClose} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description' fullWidth={fullWidth} maxWidth={maxWidth}>
                <DialogTitle
                    sx={{
                        justifyContent: 'center',
                        display: 'flex',
                    }}
                    id='alert-dialog-title'>
                    <Typography> {t('class')}</Typography>
                </DialogTitle>
                <DialogTitle
                    sx={{
                        justifyContent: 'center',
                        display: 'flex',
                    }}
                    id='alert-dialog-title'>
                    <Select
                        labelId='demo-simple-selec-class-id'
                        id='demo-simple-select-class-id'
                        value={classID}
                        sx={{ width: '50%' }}
                        onChange={(event: SelectChangeEvent) => {
                            const newValue = event.target.value;
                            handleChosenIDChange(newValue);
                        }}>
                        {allClassIDs?.classes.map((item) => {
                            return (
                                <MenuItem value={item.id} key={item.id}>
                                    <Typography>{item.className}</Typography>
                                </MenuItem>
                            );
                        })}
                    </Select>
                </DialogTitle>
                <DialogTitle
                    sx={{
                        justifyContent: 'center',
                        display: 'flex',
                    }}
                    id='alert-dialog-title'>
                    <Typography color='red' textAlign='center'>
                        {errorMsg}
                    </Typography>
                </DialogTitle>

                <DialogActions sx={{ justifyContent: 'center', paddingBottom: '60px' }}>
                    <Box>
                        <Button
                            variant='contained'
                            color='secondary'
                            sx={{
                                textTransform: 'none',
                                borderRadius: '8px',
                                marginRight: '2rem',
                            }}
                            disabled={classID == ''}
                            onClick={handleClickOpen}
                            component='label'
                            startIcon={<AddIcon />}>
                            {t('import-csv-student-button-title')}
                            <input hidden accept='.csv' type='file' onChange={SetSubmittedFile} />
                        </Button>
                        <CancelButton onClick={handleClose}>{t('cancel')}</CancelButton>
                    </Box>
                </DialogActions>
                <DialogTitle
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                    }}
                    id='alert-dialog-upload'>
                    <Button
                        variant='contained'
                        color='secondary'
                        sx={{
                            textTransform: 'none',
                            borderRadius: '8px',
                            alignSelf: 'center',
                            width: '50%',
                            marginBottom: 1,
                        }}
                        disabled={classID == '' || uploadingFile === null}
                        onClick={handleFileUpload}
                        component='label'>
                        {t('importCsvPage.upload')}
                    </Button>

                    <Typography color='black' textAlign='center'>
                        {t('importCsvPage.fileName')}
                        {uploadingFile === null ? '' : uploadingFile?.name}
                    </Typography>
                </DialogTitle>

                <CsvDownloadComponent />
            </Dialog>
            <Dialog
                open={finishUploading}
                onClose={handleClose}
                aria-labelledby='finish-upload-dialog-title'
                aria-describedby='finish-upload-dialog-description'
                fullWidth={fullWidth}
                maxWidth={maxWidth}>
                <DialogTitle
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                    }}
                    id='finish-upload-dialog'>
                    <Typography color='black' textAlign='center'>
                        {t('importCsvPage.uploadSuccess')}
                    </Typography>
                    <Button
                        variant='contained'
                        color='secondary'
                        sx={{
                            textTransform: 'none',
                            borderRadius: '8px',
                            alignSelf: 'center',
                            width: '50%',
                            marginBottom: 1,
                        }}
                        onClick={() => {
                            setFinishLoading(false);
                            handleClose();
                        }}
                        component='label'>
                        {t('importCsvPage.back')}
                    </Button>
                </DialogTitle>
            </Dialog>
        </>
    );
};
