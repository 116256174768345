import React, { useEffect, useState } from 'react';
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Box, Card } from '@mui/material';
import { TestroomScore } from 'pages/results/hook/useOverallTestroomResult';

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface Dataset {
    label: string;
    data: number[];
    backgroundColor: string;
    stack: string;
}

interface TestRoomResutData {
    testroomId: string;
    correct: number;
    wrong: number;
    notAnswer: number;
}

interface ChartData {
    labels: string[];
    datasets: Dataset[];
}

interface ChartOptions {
    plugins: {
        title: {
            display: boolean;
            text: string;
        };
    };
    aspectRatio: number; // This will make the chart square
    responsive: boolean;
    interaction: {
        mode: 'index' | 'nearest' | 'point' | 'nearest' | 'x' | 'y';
        intersect: boolean;
    };
    scales: {
        x: {
            stacked: boolean;
        };
        y: {
            stacked: boolean;
        };
    };
}

interface AnswerPerformanceGroupChartProps {
    testroomData: TestRoomResutData[];
}
const initOptions: ChartOptions = {
    plugins: {
        title: {
            display: true,
            text: '1A 班答題表現',
        },
    },
    aspectRatio: 2, // This will make the chart square
    responsive: true,
    interaction: {
        mode: 'index' as const,
        intersect: false,
    },
    scales: {
        x: {
            stacked: true,
        },
        y: {
            stacked: true,
        },
    },
};

const initData = {
    labels: [],
    datasets: [
        {
            label: '正確',
            // data: testroomData.map((item) => item.correct),
            data: [],
            backgroundColor: 'rgb(76, 175, 80)',
            stack: 'Stack 0',
        },
        {
            label: '錯誤',
            // data: testroomData.map((item) => item.wrong),
            data: [],
            backgroundColor: 'rgb(239,83,80)',
            stack: 'Stack 1',
        },
        {
            label: '沒作答',
            // data: testroomData.map((item) => item.notAnswer),
            data: [],
            backgroundColor: 'rgb(255,202,40)',
            stack: 'Stack 2',
        },
    ],
};
type ChartProp = {
    testroomData: TestroomScore;
};

// archived
const AnswerPerformanceGroupChart = ({ testroomData }: ChartProp) => {
    const [dataForChart, setDataForChart] = useState<ChartData>(initData);
    const options: ChartOptions = {
        plugins: {
            title: {
                display: true,
                text: '1A 班答題表現',
            },
        },
        aspectRatio: 2, // This will make the chart square
        responsive: true,
        interaction: {
            mode: 'index' as const,
            intersect: false,
        },
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
        },
    };
    useEffect(() => {
        const testroomIds: string[] = [];
        const correctAnswer: number[] = [];
        const wrongAnswer: number[] = [];
        const unattamptedAnswer: number[] = [];
        console.log(testroomData);
        if (testroomData) {
            let index = 0;
            for (let key in testroomData) {
                console.log(key);
                testroomIds.push(index.toString());
                if (testroomData.hasOwnProperty(key)) {
                    correctAnswer.push(testroomData[key].totalCorrect);
                    wrongAnswer.push(testroomData[key].totalIncorrectResult);
                    unattamptedAnswer.push(testroomData[key].notAttemptedQuestion);
                }
                index++;
            }
            const data = {
                labels: testroomIds,
                datasets: [
                    {
                        label: '正確',
                        data: correctAnswer,
                        backgroundColor: 'rgb(76, 175, 80)',
                        stack: 'Stack 0',
                    },
                    {
                        label: '錯誤',
                        data: wrongAnswer,
                        backgroundColor: 'rgb(239,83,80)',
                        stack: 'Stack 1',
                    },
                    {
                        label: '沒作答',
                        data: unattamptedAnswer,
                        backgroundColor: 'rgb(255,202,40)',
                        stack: 'Stack 2',
                    },
                ],
            };
            setDataForChart(data);
        }
    }, [testroomData]);

    return (
        <Bar
            style={{
                backgroundColor: 'white',
                borderRadius: '10px',
                padding: '10px',
                width: '100%',
            }}
            options={options}
            data={dataForChart}
        />
    );
};

export default AnswerPerformanceGroupChart;
