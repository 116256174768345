import { Box, Typography } from '@mui/material';
import MultipleChoiceAnswer, { MCQuestionProp } from 'pages/results/component/MultipleChoice/MultipleChoiceAnswer';
import { AnswerItemProp, Results } from 'pages/results/models';
import { useTranslation } from 'react-i18next';
import { colors } from 'styles/Colors';

export const MultipleChoiceAnswerItem = (props: AnswerItemProp) => {
    const { t } = useTranslation();
    return (
        <Box
            sx={{
                padding: 0,
                display: 'flex',
                flexDirection: 'column',
                gap: '58px',
            }}>
            {props.answerCardData?.results?.map((item: Results, index: number) => {
                const selectedMc = props?.selectedTestroom?.lessonVersion?.lessonQuestions.filter((lessonObj) => lessonObj.id === props?.answerCardData.questionID);
                if (selectedMc === undefined) {
                    return <>no question found</>;
                }
                const mCQuestionsObj: MCQuestionProp[] = (selectedMc[0].content?.questions as MCQuestionProp[]) ?? [];
                return (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '18px',
                        }}>
                        <Typography
                            sx={{
                                fontWeight: 400,
                                fontSize: '16px',
                                color: colors.yellow900,
                                lineHeight: '150%',
                            }}>
                            {t('answerTry', {
                                num: (index + 1).toString(),
                            })}
                        </Typography>
                        <MultipleChoiceAnswer mcQuestions={mCQuestionsObj} data={item.data} />
                    </Box>
                );
            })}
        </Box>
    );
};
