import { useFormik } from 'formik';
import { t } from 'i18next';
import { ClassForOptionModel } from 'models/class/ClassModel';
import { useClassesForOptionQuery } from 'models/class/useClassesForOptionQuery';
import { ERROR_TYPE, TTError } from 'models/ErrorHandling';
import { useLessonPublishMutation } from 'models/lesson/useLessonPublishMutation';
import { useCreateTestroomMutation } from 'models/testroom/useCreateTestroomMutation';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { TestroomModel } from '../../testroom/models/TestroomModel';

export default function usePublishLessonNewHook(lessonID: string, cb: (data: TestroomModel, action: 'published') => void) {
    const { runMutation: createTestroomMutation } = useCreateTestroomMutation();

    const { runMutation: publishLessonMutation } = useLessonPublishMutation();

    const { runQuery } = useClassesForOptionQuery();
    const [classesData, setClassesData] = useState<ClassForOptionModel[]>([]);

    const validationSchema = Yup.object().shape({
        name: Yup.string().min(2, t('text-limit')).max(50, t('text-limit')).required(t('required')),
        class: Yup.string().required(t('required')),
    });
    const formik = useFormik({
        initialValues: {
            name: '',
            otp: '',
            class: '',
            passlock: '',
            offlineMode: false,
            writingHints: false,
            freeStyleMode: false,
        },
        validationSchema,
        onSubmit: async (values) => {
            try {
                if (!lessonID || lessonID === '') {
                    throw new TTError(ERROR_TYPE.KS_VALIDATION_FAILURE);
                }

                const publishResult = await publishLessonMutation({
                    lessonID: lessonID,
                });

                if (!publishResult) {
                    throw new TTError(ERROR_TYPE.KS_VALIDATION_FAILURE);
                }

                const res = await createTestroomMutation({
                    ...values,
                    lessonVersion: publishResult?.id,
                });

                cb(res, 'published');
            } catch (err: any) {
                enqueueSnackbar(t(`error.${err.message}`), {
                    variant: 'error',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                });
            }
        },
    });

    useEffect(() => {
        if (!classesData || classesData.length !== 0) {
            return;
        }

        (async () => {
            try {
                const result = await runQuery({});
                setClassesData(result?.classes ?? []);
            } catch (err: any) {
                enqueueSnackbar(t(`error.${err.message}`), {
                    variant: 'error',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                });
            }
        })();
    }, [classesData, runQuery]);

    return {
        formik,
        classesData,
    };
}
