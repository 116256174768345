import { Results } from 'models/resultPage/ResultPageModel';
import { useUpdateResultsMutation } from 'models/resultPage/results/useUpdateResultsMutation';

export const useUpdateResultHook = () => {
    const { updateResults } = useUpdateResultsMutation();
    const onSaveGrading = async (item: { results: Results[]; grade: string }[]) => {
        const data = item
            ?.map((item: { results: Results[]; grade: string }) => {
                return item.results.map((result: any) => {
                    return {
                        where: { id: result.id },
                        data: { grading: item.grade },
                    };
                });
            })
            .flat();
        try {
            const result = await updateResults({
                variables: {
                    data: data,
                },
            });

            if (result.data) {
                // handleClickOpen();
            }
        } catch (error) {
            console.log('error:', error);
        }
    };
    return { onSaveGrading };
};
